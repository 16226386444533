import React, { useCallback, useEffect, useState } from "react";
import "../../style/css/gallery.css";
import {
  usePaginationAllGalleryListMutation,
} from "../../app/services/galleryService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

//CustomLeftArrow
const CustomLeftArrow = ({ onClick, currentSlide }) =>
  <div className={currentSlide === 0 ? 'disable-prev' : ''}
    onClick={() => {
      onClick();
    }}> <KeyboardArrowLeftIcon className="arrowConfiggallery" /></div>

//Custom Right Arrow
const responsive = {
  largedesktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1300, min: 992 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  samllDesktop: {
    breakpoint: { max: 992, min: 576 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const page = 1;
  const [galleryPaginationList, setGalleryPaginationList] = useState([]);
  const [description, setDescription] = useState("");
  const itemPerPage = 12;
  const [GalleryPagination] = usePaginationAllGalleryListMutation();
  const [showArrows, setShowArrows] = useState(true);
  const [setselected, setSetSelected] = useState(true)
  const [totalCount, settotalCount] = useState()
  const [items, setItems] = useState(1)

  //Get All Pagination
  const getAllPagination = useCallback(() => {
    const data = { page: page, itemsPerPage: itemPerPage };
    GalleryPagination(data).then((res) => {
      if (res?.data?.status) {
        settotalCount(res?.data?.data?.totalCount)
        setGalleryPaginationList(res?.data?.data?.list);
        if (res?.data?.data?.list?.length > 0) {
          setSelectedImage(res?.data?.data?.list[0]);
          setDescription(res?.data?.data?.list[0]?.description);
        }
      }
    });
  }, [page, itemPerPage, GalleryPagination]);

  const handleResize = () => {
    if (window.innerWidth < 769) { // Adjust the width according to your breakpoints
      setShowArrows(true);
    } else {
      setShowArrows(true);
    }
    if (window.innerWidth < 425) { // Adjust the width according to your breakpoints
      setSetSelected(false);
    } else {
      setSetSelected(true);
    }
    if (window.innerWidth > 1300) {
      setItems(4); // Show 3 items above 1200px
    }
    else if (window.innerWidth > 992 && window.innerWidth <= 1300) {
      setItems(3); // Show 3 items above 1200px
    } else if (window.innerWidth > 576 && window.innerWidth <= 992) {
      setItems(2); // Show 2 items between 992px and 1200px
    } else {
      setItems(1); // Show 1 item below 992px
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Check on mount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomRightArrow = ({ onClick, currentSlide }) =>
    <div className={currentSlide >= (Math.ceil(totalCount / items) - 1) ? 'disable-prev ' : ''} onClick={() => {

      onClick();
    }}> <KeyboardArrowRightIcon className="arrowConfiggallery" /></div>

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    if (!setselected) {
      setSelectedImage(galleryPaginationList[currentSlide])
    }
    return (
      showArrows ?
        <div className="carousel-button-group">
          <CustomLeftArrow currentSlide={currentSlide} onClick={() => previous()} />
          <CustomRightArrow currentSlide={currentSlide} onClick={() => next()} />
        </div> : <></>
    );
  };

  //getAllpaginations
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  // image Handle Click
  const handleImageClick = (item, description, e) => {
    setTimeout(() => {
      e.stopPropagation()
      // Your click logic here
    }, 100);
    if (item || description) {
      setSelectedImage(item);
      setDescription(description);
    }
  };

  return (
    <div>
      <div className="galery-wrap-container p-md-5 p-3 overallPadding ">
        {galleryPaginationList &&
          galleryPaginationList.length <= 0 ? null :
          (<>
            {galleryPaginationList && galleryPaginationList.length >= 1 && (
              <>
                <div className="d-flex justify-content-between align-items-center align_left pe-1">
                  <h2 className="custom-header">Our Galleries</h2>
                  <div className="d-flex align-items-center">
                    <Link className="viewAllgallery d-flex align-items-center view" to="/view-Gallery">
                      View All
                      <ArrowForwardIcon className="ms-1" />
                    </Link>
                    <div></div>
                  </div>
                </div>
                <div className="gallery-container mt-1 mb-3 d-none d-sm-block">
                  <div className="row ">
                    {selectedImage && (
                      <div
                        className="d-flex justify-content-center gallerHover"
                      >
                        <img
                          src={selectedImage?.imageUrl}
                          alt="Selected"
                          className="selected-image w-100"
                        />
                        <p className="img-description fs-4 fw-bold text-justify w-50">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <Carousel
                  draggable={false}
                  showDots={false}
                  swipeable={true}
                  responsive={responsive}
                  autoPlay={false}
                  keyBoardControl={true}
                  arrows={false}
                  partialVisible={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  customButtonGroup={<ButtonGroup />}
                  renderButtonGroupOutside={true}
                  centerMode={false}
                >
                  {galleryPaginationList.map((item, index) => (
                    <div className="slider d-flex justify-content-center mt-4 mt-md-0" key={index}>
                      <img src={item?.imageUrl} alt="Gallery"
                        className={`galleryImg ${selectedImage._id === item._id ? "active" : ""
                          }`} onClick={(e) => handleImageClick(item, item?.description, e)} />
                    </div>
                  ))}
                </Carousel>
              </>
            )}
          </>)
        }
      </div>
    </div>
  );
};

export default Gallery;
