import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import Header from "../Component/Header/header";
import Footer from "../Component/Footer/Footer";
import Login from "../Pages/Login";
import RequireAuth from "../app/auth/RequireAuth";
import Error from "../Component/404/Error";
import AboutPage from "../Pages/AboutPage";
import PresentOfficeBearers from "../Pages/PresentOfficeBearers";
import ChapterForm from "../Pages/Admin/ChapterForm";
import RegisterForm from "../Pages/RegisterForm";
import Marquee from "../Component/Marquee/marquee";
import AddEditJournal from "../Pages/JournalForms/AddEditJournal";
import JoinEltai from "../Pages/Membership/JoinEltai";
import ViewChapter from "../Pages/ViewChapter";
import EventForm from "../Pages/Admin/EventForm";
import MemberTable from "../Pages/Admin/MemberTable";
//import ContactUs from "../Pages/ContactUS/ContactUs";
import ContactUs from "../Pages/ContactUS/ContactUs.jsx";
import StartingChapter from "../Pages/Chapter/StartingChapter";
import ViewEvent from "../Pages/ViewEvent";
import EventType from "../Pages/Admin/EventType";
import SIGGroup from "../Pages/Admin/SIGGroup";
import Category from "../Pages/Admin/Category/Category";
import ViewCategory from "../Pages/Admin/Category/ViewCategory";
import SigMemberTable from "../Pages/Admin/SIG/SigMemberTable";
import Announcement from "../Pages/Admin/Announcement";
import Admin from "../Pages/Admin/Admin";
import MemberShipPlan from "../Pages/Admin/MemberShipPlan";
import ViewMembershipPlan from "../Pages/Admin/ViewMembershipPlan";
import AdminRequired from "../app/auth/AdminRequired";
import ChapterAdminRequired from "../app/auth/ChapterAdminRequired";
import SIGAdminRequired from "../app/auth/SIGAdminRequired";
import AllMemberList from "../Pages/Membership/AllMemberList";
import EventTable from "../Pages/Admin/EventTable";
import EventFeed from "../Pages/EventFeed";
import MakeAdmin from "../Pages/Chapter/MakeAdmin";
// import MemberForm from "../Pages/Membership/MemberForm";
import ForumTable from "../Pages/Admin/ForumTable";
import ForumForm from "../Pages/Admin/ForumForm";
import ViewForum from "../Pages/Admin/ViewForum";
import ReadMoreForum from "../Pages/Admin/ReadMoreForum";
import SIGGroupForm from "../Pages/Admin/SIGGroupForm";
import ViewSIG from "../Pages/Admin/ViewSIG";
import JournalTable from "../Pages/JournalForms/JournalTable";
import GalleryTable from "../Pages/Admin/Gallery/GalleryTable";
import AddEditTestimonals from "../Pages/Testimonials/AddEditTestimonals";
import ViewTestimonial from "../Pages/Testimonials/ViewTestimonial";
import MakeAdminSig from "../Pages/Admin/SIG/MakeAdminSig";
import JobFrom from "../Pages/Admin/Job/JobFrom";
import ViewJob from "../Pages/Admin/Job/ViewJob";
import ViewRegistered from "../Pages/Admin/ViewRegistered";
import Viewmember from "../Pages/Member/Viewmember";
import Resume from "../Pages/Admin/Resume/Resume";
import ResumeTable from "../Pages/Admin/Resume/ResumeTable";
import SigAdmin from "../Pages/SigAdmin/SigAdmin";
import ViewNewsLetter from "../Pages/Admin/NewsLetter/ViewNewsLetter";
import NewsLetterForm from "../Pages/Admin/NewsLetter/NewsLetterForm";
import ChapterAdminPortal from "../Pages/Chapter/ChapterAdminPortal";
import ChapterAdmin from "../Pages/Chapter/ChapterAdmin";
import MemberRequried from "../app/auth/MemberRequried";
import MemberRoute from "../Pages/Member/MemberRoute";
import AdminApproval from "../Pages/Admin/AdminApproval";
import IndividualEvent from "../Pages/IndividualEvent";
import ViewIndividualChapter from "../Pages/ViewIndividualChapter";
import ViewSigDetail from "../Pages/Admin/ViewSigDetail";
import ViewGallery from "../Pages/Admin/Gallery/ViewGallery";
import AddEditGallery from "../Pages/Admin/Gallery/AddEditGallery";
import ViewJournal from "../Pages/JournalForms/ViewJournal";
import Showgallery from "../Pages/Admin/Showgallery";
import SIGJoinedMember from "../Pages/Admin/SIGJoinedMember";
import ChapterJoinMember from "../Pages/ChapterJoinMember";
import Forgotpassword from "../Pages/ForgotPassword/Forgotpassword";
import ResetPassword from "../Pages/resetPassword/ResetPassword";
import Profile from "../Component/Profile/Profile";
import SigAdminProfile from "../Component/Profile/SigAdminProfile";
import ChapterProfile from "../Component/Profile/ChapterProfile";
import OurArchitects from "../Component/HomePage/OurArchitects/OurArchitects";
import OurExecutiveCommittee from "../Component/HomePage/OurExecutiveCommittee/OurExecutiveCommittee";
import TeacherMember from "../Pages/Member/TeacherMember/TeacherMember.jsx";
import StudentMember from "../Pages/Member/StudentMember/StudentMember.jsx";
import OurHistory from "../Component/HomePage/OurHistory/OurHistory";
import EltaiSig from "../Component/HomePage/EltaiSig/EltaiSig.jsx";
import StartingSig from "../Component/HomePage/startingSig/StartingSig.jsx";
import Partner from "../Component/HomePage/Partner/Partner.jsx";
import AnnualConference from "../Component/Participate/annulconference/AnnualConference.jsx";
import StartingChapterEltai from "../Pages/Chapter/StartingChapterEltai";
import IndiaReads from "../Pages/IndiaReads.jsx";
import ImportantAnnouncementTable from "../Pages/Admin/ImportAnnocement/ImportantAnnouncementTable.jsx";
import AddEditImportAnnounment from "../Pages/Admin/ImportAnnocement/AddEditImportAnnounment.jsx";
import MembershipPage from "../Component/MemberShip/MembershipPage.jsx";
import AddEditStudentMember from "../Pages/Member/StudentMember/AddEditStudentMember.jsx";
import AddEditInstitution from "../Pages/Member/Institution/Institution.jsx";
import AddEditCorporate from "../Pages/Member/Coporate/Coporate.jsx";
import ViewAllNews from "../Component/HomePage/ViewAllNews.jsx";
import Teacher from "../Component/MemberShip/Teacher/Teacher.jsx";
import InstitutionalMembers from "../Component/HomePage/OurInstitutionalMembers/InstitutionalMembers.jsx";
import ViewCorporate from "../../src/Pages/Member/Coporate/ViewCorporate.jsx";
import ViewInstitution from "../Pages/Member/Institution/ViewInstitution.jsx";
import ViewAnnouncement from "../Pages/ViewAnnouncement.jsx";
import TeachersProfile from "../Component/Profile/TeachersProfile.jsx";
import EducationalProfile from "../Component/Profile/EducationalProfile.jsx";
import CorporateProfile from "../Component/Profile/CorporateProfile.jsx";
import MembershipInstitution from "../Component/MemberShip/MembershipInstitution/MembershipInstitution.jsx";
import AnnualConferenceList from "../Pages/Admin/AnnualConference/AnnualConferenceList.jsx";
import AddEditImportAnnualConference from "../Pages/Admin/AnnualConference/AddEditImportAnnualConference.jsx";
import ClaenderOfEvents from "../Pages/ClaenderOfEvents.jsx";
import NonReceipt from "../Pages/Admin/ReceiptTable/NonReceipt.jsx";
import OurLogo from "../Pages/OurLogo.jsx";
import NewsHomePage from "../Pages/News/NewsHomePage.jsx";
import AssistantAdmin from "../Pages/Admin/AssitantAdmin/AssistantAdmin.jsx";
import AddEditAssistantAdmin from "../Pages/Admin/AssitantAdmin/AddEditAssitantAdmin.jsx";
import MembershipManagementHomePage from "../Pages/Membership/HomePage.jsx";
import CardPdfConvert from "../Pages/Member/CardPdfConvert/CardPdfConvert.jsx";
import CategoryWiseMembership from "../Pages/Member/Report/CategoryWiseMembership.jsx";
import StateMembership from "../Pages/Member/Report/StateReport/StateMembership.jsx";
import AddEditBannerForm from "../Pages/Admin/BannerContent/AddEditBannerForm.jsx";
import BannerContentTable from "../Pages/Admin/BannerContent/BannerContentTable.jsx";
import DynamicManagementHomePage from "../Pages/Membership/HomePage.jsx";
import AddEditDualTeacher from "../Pages/Member/DualTeacher/DualTeacher.jsx";
import AddEditPartner from "../Pages/Admin/Partner/AddEditPartner.jsx";
import PartnerTable from "../Pages/Admin/Partner/PartnerTable.jsx";
import AssistentAdmin from "../Pages/AssistentAdmin/AssistentAdmin.jsx";
import AssistentAdminRequired from "../app/auth/AssistentAdminRequried.js";
import MembershipBenifits from "../Pages/Admin/MembershipBenifits.jsx";
import SpotlightForm from "../Pages/Admin/SpotlightForm.jsx";
import SpotlightTable from "../Pages/Admin/SpotlightTable.jsx";
import Membership from "../Component/MemberShip/Membership/Membership.jsx";
import CommingSoonPage from "../Component/HomePage/CommingSoon.jsx";
const Routing = () => {
  const location = useLocation();
  // Function to determine whether to hide header and footer based on the route
  const shouldHideHeaderFooter = () => {
    return (
      location.pathname === "/login" ||
      location.pathname.includes("/resetPassword") ||
      location.pathname === "/register" ||
      location.pathname === "/forgotPassword"
    );
  };

  return (
    <>
      {!shouldHideHeaderFooter() && <Marquee></Marquee>}
      {!shouldHideHeaderFooter() && <Header></Header>}
      <Routes>
        <Route path="/add-edit-teacher" element={<Teacher />} />
        <Route path="/comming-soon" element={<CommingSoonPage />} />
        <Route path="/calendar-of-events" element={<ClaenderOfEvents />} />
        <Route
          path="/membership-institution"
          element={<MembershipInstitution />}
        />
        <Route path="/member-benefits" element={<MembershipBenifits />} />
        <Route index element={<HomePage />} />
        <Route path="/membership-details" element={<Membership/>} />
        <Route path="/add-Edit-corporate" element={<AddEditCorporate />} />
        <Route path="/news" element={<ViewAllNews />} />
        <Route path="/add-edit-institution" element={<AddEditInstitution />} />
        <Route
          path="/Add-Edit-Student-Member"
          element={<AddEditStudentMember />}
        />
        <Route path="/add-dual-teacher" element={<AddEditDualTeacher />} />
        <Route path="/India-Reads" element={<IndiaReads />} />
        <Route path="/chapter-starting" element={<StartingChapterEltai />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterForm />} />
        {/* <Route path="/register/:planIdFromRoute/:planTypeIdFromRoute" element={<RegisterForm />} /> */}
        <Route path="/forgotPassword" element={<Forgotpassword />} />
        <Route
          path="/our-institutional-members"
          element={<InstitutionalMembers />}
        />
        <Route path="/resetPassword/:resetToken" element={<ResetPassword />} />
        <Route path="/aboutUs" element={<AboutPage />} />
        <Route path="/mission" element={<AboutPage />} />
        <Route path="/vision" element={<AboutPage />} />
        <Route path="/officeBearers" element={<PresentOfficeBearers />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/join-eltai" element={<JoinEltai />} />
        <Route path="/view-Chapter" element={<ViewChapter />} />
        <Route path="/view-Event" element={<ViewEvent />} />
        <Route path="/view-Gallery" element={<ViewGallery />} />
        {/* <Route path="/contact-us" Component={ContactUs} /> */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/starting-chapter" element={<StartingChapter />} />
        <Route path="/eventType" element={<EventType />} />
        <Route path="/eventFeed" element={<EventFeed />} />
        <Route path="/VieweventFeed/:eventId" element={<IndividualEvent />} />
        <Route path="/sig-member-table/:id" element={<SigMemberTable />} />
        {/* <Route path='/sigGroup' element={<SIGGroup />} /> */}
        <Route path="/eventFeed" element={<EventFeed />} />
        {/* <Route path="/sig-member-table"Component={SigMemberTable}/> */}
        {/* <Route path='/memberForm' element={<MemberForm />} />   */}
        <Route
          path="/view-Chapter/:chapterId"
          element={<ViewIndividualChapter />}
        />
        <Route path="/sig-member-table/:id" element={<SigMemberTable />} />
        <Route path="addEditSigGroups" element={<SIGGroup />} />
        <Route path="/showGallery/:id" element={<Showgallery />} />
        <Route path="/viewSigDetails/:sigGroupId" element={<ViewSigDetail />} />
        <Route path="/ourArchitects" element={<OurArchitects />} />
        <Route
          path="/ourExecutiveCommittee"
          element={<OurExecutiveCommittee />}
        />
        <Route path="/ourHistory" element={<OurHistory />} />
        <Route path="/membershipPage" element={<MembershipPage />} />
        <Route path="/eltai-sig" element={<EltaiSig />} />
        <Route path="/starting-sig" element={<StartingSig />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/annual-conference" element={<AnnualConference />} />
        <Route path="/viewAnnouncement" element={<ViewAnnouncement />} />
        <Route path="viewJournal/:journal" element={<ViewJournal />} />
        <Route path="/ourLogo" element={<OurLogo />} />
        <Route path="/news-home" element={<NewsHomePage />} />

        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="/eventForm" element={<EventForm />} />
          <Route path="/eventForm/:eventId" element={<EventForm />} />
          <Route path="/memberList" element={<MemberTable />} />
          <Route path="/allMemberList" element={<AllMemberList />} />
          <Route path="/memberList/:chapterId" element={<MemberTable />} />
          <Route path="/chapterForm" element={<ChapterForm />} />
          <Route path="/chapterForm/:chapterId" element={<ChapterForm />} />
          <Route path="/addEditJournal" element={<AddEditJournal />} />
          <Route
            path="/addEditJournal/:journalId"
            element={<AddEditJournal />}
          />
          <Route path="/membership-details" element={<Membership/>} />
          <Route path="/journalTable" element={<JournalTable />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:categoryId" element={<Category />} />
          <Route path="/viewCategory" element={<ViewCategory />} />
          <Route path="/forumTable" element={<ForumTable />} />
          <Route path="/forumForm" element={<ForumForm />} />
          <Route path="forumForm/:forumId" element={<ForumForm />} />
          <Route path="/viewForum" element={<ViewForum />} />
          <Route path="/readMoreForum/:forum" element={<ReadMoreForum />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/eventList" element={<EventTable />} />
          <Route
            path="/viewRegistered/:eventId"
            element={<ViewRegistered />}
          ></Route>
          <Route path="addEditSigGroups" element={<SIGGroup />} />
          <Route path="addJob" element={<JobFrom />}></Route>
          <Route path="viewJob" element={<ViewJob />}></Route>
          <Route path="viewNewsLetter" element={<ViewNewsLetter />}></Route>
          <Route path="addNewsLetter" element={<NewsLetterForm />}></Route>
          <Route
            path="addNewsLetter/:newsId"
            element={<NewsLetterForm />}
          ></Route>
          <Route path="/addResume" element={<Resume />} />
          <Route path="/resume-table" element={<ResumeTable />} />
          <Route path="/addResume/:id" element={<Resume />} />
          <Route path="/gallery-table" element={<GalleryTable />} />
          <Route path="/gallery-table/:id" element={<GalleryTable />} />
          <Route path="viewMember" element={<Viewmember />}></Route>
          <Route path="viewJournal/:journal" element={<ViewJournal />} />
          <Route path="/VieweventFeed/:eventId" element={<IndividualEvent />} />
          <Route
            path="/view-Chapter/:chapterId"
            element={<ViewIndividualChapter />}
          />
          <Route
            path="/viewSigDetails/:sigGroupId"
            element={<ViewSigDetail />}
          />
          <Route path="/showGallery/:id" element={<Showgallery />} />
          <Route path="/chapter-starting" element={<StartingChapterEltai />} />
          <Route path="/membershipPage" element={<MembershipPage />} />
        </Route>
        <Route element={<AdminRequired />}>
          <Route path="/admin/" element={<Admin />}>
            <Route path="studentsProfile/:key/:id" element={<Profile />} />
            <Route
              path="teachersprofile/:key/:id"
              element={<TeachersProfile />}
            />
           <Route path="membership-details" element={<Membership/>} />

            <Route
              path="educationalprofile/:key/:id"
              element={<EducationalProfile />}
            />
            <Route
              path="corporateprofile/:key/:id"
              element={<CorporateProfile />}
            />
            <Route path="membershipPage" element={<MembershipPage />} />
            <Route path="add-edit-spotlight" element={<SpotlightForm />} />
            <Route path="add-edit-spotlight/:id" element={<SpotlightForm />} />
            <Route path="spotlight-table" element={<SpotlightTable />} />
            <Route path="add-edit-partner" element={<AddEditPartner />} />
            <Route path="add-edit-partner/:id" element={<AddEditPartner />} />
            <Route path="partner-table" element={<PartnerTable />} />
            <Route path="banner-content-form" element={<AddEditBannerForm />} />
            <Route
              path="banner-content-form/:bannerContentId"
              element={<AddEditBannerForm />}
            />
            <Route
              path="banner-content-table"
              element={<BannerContentTable />}
            />
            <Route
              path="teacher-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="category-wise-member-ship"
              element={<CategoryWiseMembership />}
            />
            <Route path="state-member-ship" element={<StateMembership />} />
            <Route
              path="student-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="View-institution/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="view-corporate/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route path="teacher-member/:id" element={<TeacherMember />} />
            <Route path="student-member/:id" element={<StudentMember />} />
            <Route path="View-institution/:id" element={<ViewInstitution />} />
            <Route path="view-corporate/:id" element={<ViewCorporate />} />
            <Route path="ourLogo" element={<OurLogo />} />
            <Route path="assistantAdmin" element={<AssistantAdmin />} />
            <Route
              path="AddEditAssistantAdmin"
              element={<AddEditAssistantAdmin />}
            />
            <Route
              path="AddEditAssistantAdmin/:id"
              element={<AddEditAssistantAdmin />}
            />
            <Route path="management" element={<DynamicManagementHomePage />} />
            <Route
              path="communicationManagement"
              element={<DynamicManagementHomePage />}
            />
            <Route path="non-receipt" element={<NonReceipt />} />
            <Route
              path="annualConferenceList"
              element={<AnnualConferenceList />}
            />
            <Route
              path="addEditImport-AnnualConference"
              element={<AddEditImportAnnualConference />}
            />
            <Route
              path="addEditImport-AnnualConference/:id"
              element={<AddEditImportAnnualConference />}
            />
            <Route path="" element={<HomePage />} />
            <Route path="approval" element={<AdminApproval />} />
            <Route path="eventFeed" element={<EventFeed />} />
            <Route
              path="viewMembershipPlans"
              element={<ViewMembershipPlan />}
            />
            <Route
              path="VieweventFeed/:eventId"
              element={<IndividualEvent />}
            />
            <Route path="aboutUs" element={<AboutPage />} />
            <Route path="mission" element={<AboutPage />} />
            <Route path="vision" element={<AboutPage />} />
            {/* <Route path="contact-us" Component={ContactUs} /> */}
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="allMemberList" element={<AllMemberList />} />
            <Route path="join-eltai" element={<JoinEltai />} />
            <Route path="chapterForm" element={<ChapterForm />} />
            <Route path="view-Chapter" element={<ViewChapter />} />
            <Route path="chapterForm/:chapterId" element={<ChapterForm />} />
            <Route path="memberList/:chapterId" element={<MemberTable />} />
            <Route path="viewNewsLetter" element={<ViewNewsLetter />} />
            <Route path="addEditJournal" element={<AddEditJournal />} />
            <Route
              path="addEditJournal/:journalId"
              element={<AddEditJournal />}
            />
            <Route path="journalTable" element={<JournalTable />} />
            <Route path="add-edit-Gallery" element={<AddEditGallery />} />
            <Route path="add-edit-Gallery/:id" element={<AddEditGallery />} />
            <Route path="gallery-table" element={<GalleryTable />} />
            <Route path="addNewsLetter" element={<NewsLetterForm />} />
            <Route path="eventForm" element={<EventForm />} />
            <Route path="eventForm/:eventId" element={<EventForm />} />
            <Route path="addJob" element={<JobFrom />} />
            <Route path="addNewsLetter/:newsId" element={<NewsLetterForm />} />
            <Route path="starting-chapter" element={<StartingChapter />} />
            <Route path="eventType" element={<EventType />} />
            <Route path="eventList" element={<EventTable />} />
            <Route path="viewCategory" element={<ViewCategory />} />
            <Route path="resume-table" element={<ResumeTable />} />
            <Route path="addResume/:id" element={<Resume />} />
            <Route path="addResume" element={<Resume />} />
            <Route path="category" element={<Category />} />
            <Route path="category/:categoryId" element={<Category />} />
            <Route path="announcement" element={<Announcement />} />
            <Route path="viewJob" element={<ViewJob />}></Route>
            <Route path="forumTable" element={<ForumTable />} />
            <Route path="forumForm" element={<ForumForm />} />
            <Route path="forumForm/:forumId" element={<ForumForm />} />
            <Route path="viewForum" element={<ViewForum />} />
            <Route path="readMoreForum/:forum" element={<ReadMoreForum />} />
            <Route
              path="chapter/make-admin/:chapterId"
              element={<MakeAdmin />}
            />
            <Route
              path="addEditTestimonials"
              element={<AddEditTestimonals />}
            />
            <Route
              path="addEditTestimonials/:testimonialId"
              element={<AddEditTestimonals />}
            />
            <Route path="viewTestimonials" element={<ViewTestimonial />} />
            <Route path="officeBearers" element={<PresentOfficeBearers />} />
            <Route path="addEditMembershipPlan" element={<MemberShipPlan />} />
            <Route
              path="addEditMembershipPlan/:membershipId"
              element={<MemberShipPlan />}
            />
            <Route path="addEditSigGroups" element={<SIGGroup />} />
            <Route path="addEditSigGroups/:id" element={<SIGGroup />} />
            <Route path="sig-joined-Member/:id" element={<SIGJoinedMember />} />
            <Route path="sigGroupForm" element={<SIGGroupForm />} />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route
              path="MakeAdminSigGroup/:sigGroupId"
              element={<MakeAdminSig />}
            />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route path="viewJournal/:journal" element={<ViewJournal />} />
            <Route
              path="view-Chapter/:chapterId"
              element={<ViewIndividualChapter />}
            />
            <Route
              path="viewSigDetails/:sigGroupId"
              element={<ViewSigDetail />}
            />
            <Route path="showGallery/:id" element={<Showgallery />} />
            <Route path="approveRejectGallery/:id" element={<Showgallery />} />
            <Route
              path="chapterJoinMember/:id"
              element={<ChapterJoinMember />}
            />
            <Route
              path="importannoucenment"
              element={<ImportantAnnouncementTable />}
            />
            <Route
              path="addEditImport-Announment"
              element={<AddEditImportAnnounment />}
            />
            <Route
              path="addEditImport-Announment/:id"
              element={<AddEditImportAnnounment />}
            />
            <Route path="chapter-starting" element={<StartingChapterEltai />} />
          </Route>
          <Route path="/viewCategory" element={<ViewCategory />} />
        </Route>
        <Route element={<ChapterAdminRequired />}>
          <Route path="/chapterAdmin/" element={<ChapterAdmin />}>
            <Route path="membershipPage" element={<MembershipPage />} />
            <Route path="membership-details" element={<Membership/>} />
            <Route path="management" element={<DynamicManagementHomePage />} />
            <Route path="" element={<HomePage />} />
            <Route path="chapterDetail" element={<ChapterAdminPortal />} />
            <Route path="ourLogo" element={<OurLogo />} />
            <Route
              path="view-Chapter/:chapterId"
              element={<ViewIndividualChapter />}
            />
            {/* <Route path="contact-us" Component={ContactUs} /> */}
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="viewJob" element={<ViewJob />}></Route>
            <Route
              path="VieweventFeed/:eventId"
              element={<IndividualEvent />}
            />
            <Route
              path="viewMembershipPlans"
              element={<ViewMembershipPlan />}
            />
            <Route path="officeBearers" element={<PresentOfficeBearers />} />
            <Route path="join-eltai" element={<JoinEltai />} />
            <Route path="chapterForm" element={<ChapterForm />} />
            <Route path="view-Chapter" element={<ViewChapter />} />
            <Route path="aboutUs" element={<AboutPage />} />
            <Route path="mission" element={<AboutPage />} />
            <Route path="vision" element={<AboutPage />} />
            <Route path="eventFeed" element={<EventFeed />} />
            <Route path="eventForm" element={<EventForm />} />
            <Route path="eventList" element={<EventTable />} />
            <Route path="eventForm/:eventId" element={<EventForm />} />
            <Route path="add-edit-Gallery" element={<AddEditGallery />} />
            <Route path="add-edit-Gallery/:id" element={<AddEditGallery />} />
            <Route path="gallery-table" element={<GalleryTable />} />
            <Route path="gallery-table/:id" element={<GalleryTable />} />
            <Route path="forumTable" element={<ForumTable />} />
            <Route path="forumForm" element={<ForumForm />} />
            <Route path="forumForm/:forumId" element={<ForumForm />} />
            <Route path="viewForum" element={<ViewForum />} />
            <Route path="starting-chapter" element={<StartingChapter />} />
            <Route path="addEditSigGroups" element={<SIGGroup />} />
            <Route path="sigGroupForm" element={<SIGGroupForm />} />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="eventType" element={<EventType />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route
              path="MakeAdminSigGroup/:sigGroupId"
              element={<MakeAdminSig />}
            />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route path="eventList" element={<EventTable />} />
            <Route path="addEditJournal" element={<AddEditJournal />} />
            <Route
              path="addEditJournal/:journalId"
              element={<AddEditJournal />}
            />
            <Route path="journalTable" element={<JournalTable />} />
            <Route path="viewJournal/:journal" element={<ViewJournal />} />
            <Route
              path="viewSigDetails/:sigGroupId"
              element={<ViewSigDetail />}
            />
            <Route path="showGallery/:id" element={<Showgallery />} />
            <Route
              path="chapterJoinMember/:id"
              element={<ChapterJoinMember />}
            />
            <Route path="chapterProfile/:id" element={<ChapterProfile />} />
            <Route
              path="importannoucenment"
              element={<ImportantAnnouncementTable />}
            />
            <Route
              path="addEditImport-Announment"
              element={<AddEditImportAnnounment />}
            />
            <Route
              path="addEditImport-Announment/:id"
              element={<AddEditImportAnnounment />}
            />
            <Route path="chapterNewsFeed" element={<Viewmember />}></Route>
            <Route path="chapter-starting" element={<StartingChapterEltai />} />
          </Route>
        </Route>
        <Route element={<SIGAdminRequired />}>
          <Route
            path="membershipManagement"
            element={<DynamicManagementHomePage />}
          />
          <Route path="/sigAdmin/" element={<SigAdmin />}>
            <Route path="membershipPage" element={<MembershipPage />} />
            <Route path="management" element={<DynamicManagementHomePage />} />
            <Route path="" element={<HomePage />} />
            <Route path="signewfeed" element={<Viewmember />}></Route>
            <Route path="ourLogo" element={<OurLogo />} />
            <Route path="membership-details" element={<Membership/>} />
            <Route
              path="view-Chapter/:chapterId"
              element={<ViewIndividualChapter />}
            />
            <Route path="sigDetails" element={<Viewmember />} />
            <Route path="viewJournal/:journal" element={<ViewJournal />} />
            <Route
              path="viewMembershipPlans"
              element={<ViewMembershipPlan />}
            />
            <Route
              path="VieweventFeed/:eventId"
              element={<IndividualEvent />}
            />
            <Route path="officeBearers" element={<PresentOfficeBearers />} />
            <Route path="join-eltai" element={<JoinEltai />} />
            {/* <Route path="contact-us" Component={ContactUs} /> */}
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="chapterForm" element={<ChapterForm />} />
            <Route path="view-Chapter" element={<ViewChapter />} />
            <Route path="aboutUs" element={<AboutPage />} />
            <Route path="mission" element={<AboutPage />} />
            <Route path="vision" element={<AboutPage />} />
            <Route path="eventFeed" element={<EventFeed />} />
            <Route path="eventForm" element={<EventForm />} />
            <Route path="sig-joined-Member/:id" element={<SIGJoinedMember />} />
            <Route path="eventForm/:eventId" element={<EventForm />} />
            <Route path="add-edit-Gallery" element={<AddEditGallery />} />
            <Route path="add-edit-Gallery/:id" element={<AddEditGallery />} />
            <Route path="gallery-table" element={<GalleryTable />} />
            <Route path="gallery-table/:id" element={<GalleryTable />} />
            <Route path="forumTable" element={<ForumTable />} />
            <Route path="forumForm" element={<ForumForm />} />
            <Route path="viewForum" element={<ViewForum />} />
            <Route path="forumForm/:forumId" element={<ForumForm />} />
            <Route path="readMoreForum/:forum" element={<ReadMoreForum />} />
            <Route path="starting-chapter" element={<StartingChapter />} />
            <Route path="addEditSigGroups" element={<SIGGroup />} />
            <Route path="sigGroupForm" element={<SIGGroupForm />} />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route
              path="MakeAdminSigGroup/:sigGroupId"
              element={<MakeAdminSig />}
            />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route path="eventType" element={<EventType />} />
            <Route path="eventList" element={<EventTable />} />
            <Route path="addEditJournal" element={<AddEditJournal />} />
            <Route
              path="addEditJournal/:journalId"
              element={<AddEditJournal />}
            />
            <Route path="journalTable" element={<JournalTable />} />
            <Route
              path="viewSigDetails/:sigGroupId"
              element={<ViewSigDetail />}
            />
            <Route path="showGallery/:id" element={<Showgallery />} />
            <Route path="sigadminProfile/:id" element={<SigAdminProfile />} />
            <Route
              path="importannoucenment"
              element={<ImportantAnnouncementTable />}
            />
            <Route
              path="addEditImport-Announment"
              element={<AddEditImportAnnounment />}
            />
            <Route
              path="addEditImport-Announment/:id"
              element={<AddEditImportAnnounment />}
            />
            <Route path="chapter-starting" element={<StartingChapterEltai />} />
          </Route>
        </Route>
        <Route element={<MemberRequried />}>
          <Route path="/member/" element={<MemberRoute />}>
            <Route path="ourLogo" element={<OurLogo />} />
            <Route path="membershipPage" element={<MembershipPage />} />
            <Route
              path="teacher-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route path="membership-details" element={<Membership/>} />
            <Route
              path="student-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="View-institution/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="view-corporate/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route path="teacher-member/:id" element={<TeacherMember />} />
            <Route path="student-member/:id" element={<StudentMember />} />
            <Route path="View-institution/:id" element={<ViewInstitution />} />
            <Route path="view-corporate/:id" element={<ViewCorporate />} />
            <Route path="" element={<HomePage />} />
            <Route path="viewMember" element={<Viewmember />}></Route>
            <Route
              path="view-Chapter/:chapterId"
              element={<ViewIndividualChapter />}
            />
            <Route path="sigDetails" element={<Viewmember />} />
            {/* <Route path="contact-us" element={ContactUs} /> */}
            <Route path="contact-us" element={<ContactUs />} />
            <Route
              path="viewMembershipPlans"
              element={<ViewMembershipPlan />}
            />
            <Route
              path="VieweventFeed/:eventId"
              element={<IndividualEvent />}
            />
            <Route path="officeBearers" element={<PresentOfficeBearers />} />
            <Route path="join-eltai" element={<JoinEltai />} />
            <Route path="chapterForm" element={<ChapterForm />} />
            <Route path="view-Chapter" element={<ViewChapter />} />
            <Route path="aboutUs" element={<AboutPage />} />
            <Route path="mission" element={<AboutPage />} />
            <Route path="vision" element={<AboutPage />} />
            <Route path="eventFeed" element={<EventFeed />} />
            <Route path="forumTable" element={<ForumTable />} />
            <Route path="forumForm" element={<ForumForm />} />
            <Route path="viewForum" element={<ViewForum />} />
            <Route path="forumForm/:forumId" element={<ForumForm />} />
            <Route path="readMoreForum/:forum" element={<ReadMoreForum />} />
            <Route path="starting-chapter" element={<StartingChapter />} />
            <Route path="addEditSigGroups" element={<SIGGroup />} />
            <Route path="sigGroupForm" element={<SIGGroupForm />} />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route
              path="MakeAdminSigGroup/:sigGroupId"
              element={<MakeAdminSig />}
            />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route path="eventType" element={<EventType />} />
            <Route path="eventList" element={<EventTable />} />
            <Route
              path="viewSigDetails/:sigGroupId"
              element={<ViewSigDetail />}
            />
            <Route path="showGallery/:id" element={<Showgallery />} />
            <Route path="sig-joined-Member/:id" element={<SIGJoinedMember />} />
            <Route path="studentsProfile/:key/:id" element={<Profile />} />
            <Route
              path="teachersprofile/:key/:id"
              element={<TeachersProfile />}
            />
            <Route
              path="educationalprofile/:key/:id"
              element={<EducationalProfile />}
            />
            <Route
              path="corporateprofile/:key/:id"
              element={<CorporateProfile />}
            />
            <Route path="profile/:key/:id" element={<Profile />} />
            <Route path="chapter-starting" element={<StartingChapterEltai />} />
          </Route>
        </Route>
        <Route element={<AssistentAdminRequired />}>
          <Route path="/assistantAdmin/" element={<AssistentAdmin />}>
            <Route path="add-edit-spotlight" element={<SpotlightForm />} />
            <Route path="add-edit-spotlight/:id" element={<SpotlightForm />} />
            <Route path="spotlight-table" element={<SpotlightTable />} />
            <Route path="add-edit-partner" element={<AddEditPartner />} />
            <Route path="add-edit-partner/:id" element={<AddEditPartner />} />
            <Route path="partner-table" element={<PartnerTable />} />
            <Route path="membershipPage" element={<MembershipPage />} />
            <Route path="membership-details" element={<Membership/>} />
            <Route
              path="teacher-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="category-wise-member-ship"
              element={<CategoryWiseMembership />}
            />
            <Route path="state-member-ship" element={<StateMembership />} />
            <Route
              path="student-member/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="View-institution/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route
              path="view-corporate/:id/cardpdf-converter"
              element={<CardPdfConvert />}
            />
            <Route path="teacher-member/:id" element={<TeacherMember />} />
            <Route path="student-member/:id" element={<StudentMember />} />
            <Route path="View-institution/:id" element={<ViewInstitution />} />
            <Route path="view-corporate/:id" element={<ViewCorporate />} />
            <Route path="ourLogo" element={<OurLogo />} />
            <Route path="assistantAdmin" element={<AssistantAdmin />} />
            <Route
              path="AddEditAssistantAdmin"
              element={<AddEditAssistantAdmin />}
            />
            <Route
              path="AddEditAssistantAdmin/:id"
              element={<AddEditAssistantAdmin />}
            />
            <Route
              path="membershipManagement"
              element={<MembershipManagementHomePage />}
            />
            <Route path="non-receipt" element={<NonReceipt />} />
            <Route
              path="annualConferenceList"
              element={<AnnualConferenceList />}
            />
            <Route
              path="addEditImport-AnnualConference"
              element={<AddEditImportAnnualConference />}
            />
            <Route
              path="addEditImport-AnnualConference/:id"
              element={<AddEditImportAnnualConference />}
            />
            <Route path="" element={<HomePage />} />
            <Route path="management" element={<DynamicManagementHomePage />} />
            <Route path="approval" element={<AdminApproval />} />
            <Route path="eventFeed" element={<EventFeed />} />
            <Route
              path="viewMembershipPlans"
              element={<ViewMembershipPlan />}
            />
            <Route
              path="VieweventFeed/:eventId"
              element={<IndividualEvent />}
            />
            <Route path="aboutUs" element={<AboutPage />} />
            <Route path="mission" element={<AboutPage />} />
            <Route path="vision" element={<AboutPage />} />
            {/* <Route path="contact-us" Component={ContactUs} /> */}
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="allMemberList" element={<AllMemberList />} />
            <Route path="join-eltai" element={<JoinEltai />} />
            <Route path="chapterForm" element={<ChapterForm />} />
            <Route path="view-Chapter" element={<ViewChapter />} />
            <Route path="chapterForm/:chapterId" element={<ChapterForm />} />
            <Route path="memberList/:chapterId" element={<MemberTable />} />
            <Route path="viewNewsLetter" element={<ViewNewsLetter />} />
            <Route path="addEditJournal" element={<AddEditJournal />} />
            <Route
              path="addEditJournal/:journalId"
              element={<AddEditJournal />}
            />
            <Route path="journalTable" element={<JournalTable />} />
            <Route path="add-edit-Gallery" element={<AddEditGallery />} />
            <Route path="add-edit-Gallery/:id" element={<AddEditGallery />} />
            <Route path="gallery-table" element={<GalleryTable />} />
            <Route path="addNewsLetter" element={<NewsLetterForm />} />
            <Route path="eventForm" element={<EventForm />} />
            <Route path="eventForm/:eventId" element={<EventForm />} />
            <Route path="addJob" element={<JobFrom />} />
            <Route path="addNewsLetter/:newsId" element={<NewsLetterForm />} />
            <Route path="starting-chapter" element={<StartingChapter />} />
            <Route path="eventType" element={<EventType />} />
            <Route path="eventList" element={<EventTable />} />
            <Route path="viewCategory" element={<ViewCategory />} />
            <Route path="resume-table" element={<ResumeTable />} />
            <Route path="addResume/:id" element={<Resume />} />
            <Route path="addResume" element={<Resume />} />
            <Route path="category" element={<Category />} />
            <Route path="category/:categoryId" element={<Category />} />
            <Route path="announcement" element={<Announcement />} />
            <Route path="viewJob" element={<ViewJob />}></Route>
            <Route path="forumTable" element={<ForumTable />} />
            <Route path="forumForm" element={<ForumForm />} />
            <Route path="forumForm/:forumId" element={<ForumForm />} />
            <Route path="viewForum" element={<ViewForum />} />
            <Route path="readMoreForum/:forum" element={<ReadMoreForum />} />
            <Route
              path="chapter/make-admin/:chapterId"
              element={<MakeAdmin />}
            />
            <Route
              path="addEditTestimonials"
              element={<AddEditTestimonals />}
            />
            <Route
              path="addEditTestimonials/:testimonialId"
              element={<AddEditTestimonals />}
            />
            <Route path="viewTestimonials" element={<ViewTestimonial />} />
            <Route path="officeBearers" element={<PresentOfficeBearers />} />
            <Route path="addEditMembershipPlan" element={<MemberShipPlan />} />
            <Route
              path="addEditMembershipPlan/:membershipId"
              element={<MemberShipPlan />}
            />
            <Route path="addEditSigGroups" element={<SIGGroup />} />
            <Route path="addEditSigGroups/:id" element={<SIGGroup />} />
            <Route path="sig-joined-Member/:id" element={<SIGJoinedMember />} />
            <Route path="sigGroupForm" element={<SIGGroupForm />} />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route
              path="MakeAdminSigGroup/:sigGroupId"
              element={<MakeAdminSig />}
            />
            <Route path="sigGroupForm/:sigGroupId" element={<SIGGroupForm />} />
            <Route path="sig-member-table" element={<SigMemberTable />} />
            <Route path="viewSig" element={<ViewSIG />} />
            <Route path="viewJournal/:journal" element={<ViewJournal />} />
            <Route
              path="view-Chapter/:chapterId"
              element={<ViewIndividualChapter />}
            />
            <Route
              path="viewSigDetails/:sigGroupId"
              element={<ViewSigDetail />}
            />
            <Route path="showGallery/:id" element={<Showgallery />} />
            <Route path="approveRejectGallery/:id" element={<Showgallery />} />
            <Route
              path="chapterJoinMember/:id"
              element={<ChapterJoinMember />}
            />
            <Route
              path="importannoucenment"
              element={<ImportantAnnouncementTable />}
            />
            <Route
              path="addEditImport-Announment"
              element={<AddEditImportAnnounment />}
            />
            <Route
              path="addEditImport-Announment/:id"
              element={<AddEditImportAnnounment />}
            />
            <Route path="chapter-starting" element={<StartingChapterEltai />} />
          </Route>
          <Route path="/viewCategory" element={<ViewCategory />} />
        </Route>
        {/* producted routes end */}
        <Route path="*" element={<Error></Error>}></Route>
      </Routes>
      {!shouldHideHeaderFooter() && <Footer></Footer>}
    </>
  );
};

export default Routing;
