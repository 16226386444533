import React, { useState, useEffect, useCallback } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import { Box, Button, Typography } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import {
    usePaginationNewsLetterMutation,
    useDeleteNewsLetterMutation,
    usePublishNewsLetterMutation,
} from "../../../app/services/newsLetterService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { toast } from "react-toastify";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "@mui/material/Tooltip";
import ReactPaginate from "react-paginate";
import Spinner from "../../../Component/spinner/spinner";
import { selectCurrentScope, } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux"

//table heading
function EnhancedTableHead() {
    const headCells = [
        {
            id: "News Heading",
            numeric: false,
            disablePadding: true,
            label: "Newsletter Heading",
        },
        { id: "Email", numeric: true, disablePadding: false, label: "Email" },
        {
            id: "NewsContent Heading",
            numeric: true,
            disablePadding: false,
            label: "News Content Heading",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Status" },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: '250px !important' },
    ];
    return (
        <>
            <TableHead>
                <TableRow className="menus-color">
                    {headCells.map((headCell) => (
                        <TableCell
                            sx={{ textAlign: "center", color: "#ffff", width: headCell?.width ? headCell?.width : "auto" }}
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </>
    );
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};

const ViewNewsLetter = () => {
    const navigate = useNavigate(); //route
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0); //state for set page
    const rowsPerPage = 5;
    const [getPaginationNewsLetterData] = usePaginationNewsLetterMutation(); //API call of Get all
    const [deleteNewsLetter] = useDeleteNewsLetterMutation(); //API call of delete
    const [publishNewsletter] = usePublishNewsLetterMutation(); //Api call of publish
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [allNewsLetter, setAllNewsLetter] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const memberScope = useSelector(selectCurrentScope);

    //Modal usage
    const handleOpen = (id) => {
        setOpen(true);
        setSelectedId(id);
    };

    const handleClose = () => setOpen(false);

    //getAllPendingEventList     
    const getAllPagination = useCallback(() => {
        var data = {
            page: page + 1,
            itemsPerPage: rowsPerPage,
        };
        getPaginationNewsLetterData(data).then((res) => {
            if (res.data?.status) {
                setLoading(false);
                setTotalPage(res?.data?.data?.totalCount || 0);
                setAllNewsLetter(res.data?.data?.newsletterDetails || []);
            } else {
                setLoading(false);
            }
        });
    }, [getPaginationNewsLetterData, page, rowsPerPage]);

    //useEffect
    useEffect(() => {
        getAllPagination();
    }, [getAllPagination]);

    const handlePageClick = ({ selected }) => {
        setPage(selected);
    };

    //calculate pagecount
    const pageCount = Math.ceil(totalPage / rowsPerPage);

    //Edit NewsLetter
    const handleEdit = (id) => {
        if (memberScope === 'PORTAL_SUPER_ADMIN') {
            navigate("/admin/addNewsLetter/" + id);
        }
        else {
            navigate("/assistantAdmin/addNewsLetter/" + id);
        }

        // let routePrefix = "";
        // switch (memberScope) {
        //     case "PORTAL_SUPER_ADMIN":
        //         routePrefix = "/admin/addNewsLetter";
        //         break;
        //     case "PORTAL_ADMIN":
        //         routePrefix = "/assistantAdmin/addNewsLetter";
        //         break;

        // }
        // console.log(routePrefix,'routePrefix')
        // navigate(`${routePrefix}/${id}`);

        // //     if (memberScope === 'PORTAL_SUPER_ADMIN') {
        // //         console.log('inside the if')
        // //         navigate("/admin/addNewsLetter/" + id);
        // //     }
        // //     else if (memberScope === 'PORTAL_ADMIN') {
        // //         navigate("/assistantAdmin/addNewsLetter/" + id);
        // //     }
        // navigate("/addNewsLetter/" + id);
    };

    //Delete NewsLetter
    const handleDelete = () => {
        const data = { id: selectedId };
        setLoading(true);
        deleteNewsLetter(data).then((res) => {
            if (res.data.status) {
                setLoading(false);
                toast.error("NewsLetter Deleted Successfully");
                handleClose();
                if (allNewsLetter.length <= 1) {
                    setPage(page - 1);
                    getAllPagination();
                } else {
                    getAllPagination();
                }
            } else {
                setLoading(false);
            }
        });
    }

    //publish newsletter
    const publishNewsLetter = (id) => {
        const data = { id: id };
        setLoading(true);
        publishNewsletter(data).then((res) => {
            if (res?.data.data) {
                setLoading(false);
                toast.success("Newsletter Published successfully");
                getAllPagination();
            } else {
                setLoading(false);
            }
        });
    };

    //add newsletter
    const addNewsLetter = () => {
        if (memberScope === "PORTAL_SUPER_ADMIN") {
            navigate("/admin/addNewsLetter/");
        }
        else if (memberScope === "PORTAL_ADMIN") {
            navigate("/assistantAdmin/addNewsLetter/");
        }
    };

    return (
        <div>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Newsletter List</h2>
                </div>
            </div>
            <div className='p-md-5 p-3 overallPadding' >
                <Button variant="contained" className="menus-color" style={{ float: "right", marginBottom: "15px" }} onClick={addNewsLetter}>
                    Add Newsletter
                </Button>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Box>
                            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                    <EnhancedTableHead />
                                    <TableBody>
                                        {allNewsLetter.length > 0 ? (
                                            allNewsLetter.map((newsLetter, index) => (
                                                <TableRow
                                                    hover
                                                    key={newsLetter._id}
                                                    sx={{
                                                        cursor: "pointer",
                                                        backgroundColor:
                                                            index % 2 === 0 ? "#f0f0f0" : "white",
                                                    }}
                                                >
                                                    <TableCell align="center">
                                                        {newsLetter.heading}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {newsLetter.email}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: "30%" }}>
                                                        {newsLetter.newsContent.map(
                                                            (newsContent, index) => (
                                                                <span key={index}>
                                                                    {newsContent.heading}

                                                                    {index !==
                                                                        newsLetter.newsContent.length - 1 && ","}
                                                                </span>
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {newsLetter.isPublish ? (<span style={{ color: "Green", fontWeight: "600" }}>
                                                            Published
                                                        </span>) : (<span style={{
                                                            color: "#766bcb",
                                                            fontWeight: "600",
                                                        }}>
                                                            Pending
                                                        </span>)}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {!newsLetter.isPublish ?
                                                            (<>
                                                                <Tooltip title="Edit">
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => handleEdit(newsLetter._id)}
                                                                        disabled={newsLetter.isPublish === true}
                                                                    >
                                                                        <EditIcon
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>) : <></>
                                                        }
                                                        {newsLetter.isPublish === false && (
                                                            <Tooltip title="Publish">
                                                                <IconButton
                                                                    aria-label="EditIcon"
                                                                    onClick={() =>
                                                                        publishNewsLetter(newsLetter._id)
                                                                    }
                                                                >
                                                                    <LanguageIcon style={{ color: "Purple" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        <Tooltip title="Delete">
                                                            <span>
                                                                <IconButton
                                                                    aria-label="approve"
                                                                    onClick={() => handleOpen(newsLetter._id)}
                                                                >
                                                                    <DeleteIcon style={{ color: "red" }} />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>

                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={5} align="center">
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={style}
                                    className="d-flex align-items-center flex-column gap-3"
                                >
                                    <Typography
                                        id="modal-modal-title"
                                        style={{ color: "#611f1e" }}
                                        variant="h6"
                                        component="h2"
                                    >
                                        Are You Surely Want to Delete this Newsletter?
                                    </Typography>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="success"
                                            onClick={handleClose}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            className="mx-2"
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDelete()}
                                        >
                                            Yes
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                            <br/>
                            {allNewsLetter.length > 0 && (
                                <ReactPaginate
                                    pageCount={pageCount} // Calculate total pages
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={page}
                                    disabledClassName={"disablebtn"}
                                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                                    nextLabel={
                                        pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                                    }
                                />
                            )}
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewNewsLetter;