import React from 'react'
// import NavBar from './NavBar'
import Banner from './banner'

function HeroSection() {
  return (
    <>
      <div className="container-fluid">
      <Banner></Banner>
      {/* <NavBar></NavBar> */}
      </div>
    </>
  )
}

export default HeroSection