import React, { useEffect, useState } from "react";
import "../../style/css/about.css";
import { useGetAllChaptersPaginationMutation } from "../../app/services/chapterServices";
import { useGetAllMemberListMutation } from "../../app/services/memberListServices";
import { useGetAllSIGPaginationMutation } from "../../app/services/SIGServices";
import videoFile from "../../videos/Eltai.mp4";
import { Button } from 'react-bootstrap';
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AboutSection() {
  const [totalChapterPage, setTotalChapterPage] = useState([]);
  const [totalMemberPage, setTotalMemberPage] = useState([]);
  const [totalSIGPage, setTotalSIGPage] = useState([]);
  const [runningNumber, setRunningNumber] = useState(0); // Start with 0
  const [runningSigNumber, setRunningSigNumber] = useState(0); // Start with 0
  const [runningChapterNumber, setRunningChapterNumber] = useState(0); // Start with 0
  const [getAllChaptercount] = useGetAllChaptersPaginationMutation()
  const [getAllMembercount] = useGetAllMemberListMutation();
  const [getAllSIGcount] = useGetAllSIGPaginationMutation();
  const currentScope = useSelector(selectCurrentScope);
  const navigate = useNavigate(); //route

  const stats = [
    { number: "5000+", label: "Individual Members" },
    { number: "500+", label: "Institutional Members" },
    { number: "70+", label: "Chapters Across India" },
    { number: "100+", label: "Partners Across the Globe" },
    { number: "900+", label: "Events Organised So Far" },
  ];


  useEffect(() => {
    //Chapter Count
    getAllChaptercount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalChapterPage(data || 0);
      }
    });

    //Member Count
    getAllMembercount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalMemberPage(data || 0);
      }
    });

    //Get All Sig Count
    getAllSIGcount({ page: 1, itemsPerPage: 5 }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.totalCount;
        setTotalSIGPage(data || 0);
      }
    });
  }, [getAllChaptercount, getAllMembercount, getAllSIGcount]);

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningNumber < totalMemberPage) {
        setRunningNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [runningNumber, totalMemberPage]);

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningSigNumber < totalSIGPage) {
        setRunningSigNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [runningSigNumber, totalSIGPage]);

  //Router Portal
  const joinMember = () => {
    if (currentScope === "PORTAL_SUPER_ADMIN") {
      navigate("/admin/membershipPage");
    } else if (currentScope === "PORTAL_ADMIN") {
      navigate("/assistantAdmin/membershipPage");
    }
    else if (currentScope === "PORTAL_CHAPTER_ADMIN") {
      navigate("/chapterAdmin/membershipPage");
    } else if (currentScope === "PORTAL_SIG_ADMIN") {
      navigate("/sigAdmin/membershipPage");
    } else if (currentScope === "PORTAL_MEMBER") {
      navigate("/member/membershipPage");
    } else {
      navigate("/membershipPage");
    }
  };

  // Decrease the interval for smoother transition
  useEffect(() => {
    const interval = setInterval(() => {
      if (runningChapterNumber < totalChapterPage) {
        setRunningChapterNumber((prevNumber) => prevNumber + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [runningChapterNumber, totalChapterPage]);

  return (
    // <div  className="abt-bg" style={{ position: "relative", top: "53px" }}>
    //   <div className="row aboutsection-container">
    //     <div className="d-flex justify-content-center p-0">
    //       <div>
    //         <div className="d-flex ms-5">
    //           <h2 className="custom-header ms-0">About ELTAI</h2>
    //           <hr className="about-hr ms-4 me-4 d-none d-md-block" />
    //         </div>
    //         <div className="ms-auto align-items-center mt-2 mt-md-0">
    //           <p className="w-60 text-justify signika fw-bold abt-text">
    //             Founded on August 7, 1969, by the visionary Padmashri S. Natarajan and five pioneering educators, the English Language Teachers' Association of India (ELTAI) has flourished into the nation's largest network of English teachers. From an initial 250 members, it has grown to over 5,500 strong, spanning 60 chapters across India. ELTAI stands as a global force, collaborating with esteemed bodies like IATEFL (UK) and TESOL (USA), and offering journals, e-publications, and transformative events. Through our conferences, workshops, and consultancy services, we empower educators with cutting-edge insights, shaping the future of English teaching in India.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <div className="row mb-5">
    //       <div className="event-counter-section section-padding">
    //         <>
    //           <Container maxWidth="xl" className="ms-md-3 ms-0">
    //             <div className="row align-items-center ">
    //               <div className="col-xl-3 col-md-12 col-sm-12 text-md-center">
    //                 <div className="section-title">
    //                   <h5 className="sub-title signika">Become a member</h5>
    //                   <h2 className="title signika">Grow with us.</h2>
    //                   <h2 className="title signika">
    //                   Lead the future.
    //                   </h2>
    //                 </div>
    //               </div>
    //               <div className="col-xl-9 col-md-12 col-sm-12">
    //                 <div className="row d-flex justify-content-center">
    //                   <div className="counter-item">
    //                     <div className="counter-item-inner">
    //                       <h1 className="counter-numb counter color1">5000+</h1>
    //                       <span className="signika member-config">
    //                         Individual Members
    //                       </span>
    //                     </div>
    //                   </div>
    //                   <div className="counter-item">
    //                     <div className="counter-item-inner">
    //                       <h1 className="counter-numb counter color2">200+</h1>
    //                       <span className="signika chapter-config">
    //                         Institutional Members
    //                       </span>
    //                     </div>
    //                   </div>
    //                   <div className="counter-item">
    //                     <div className="counter-item-inner">
    //                       <h1 className="counter-numb counter color3">70+</h1>
    //                       <span className="signika sig-config">
    //                       Chapters Across India
    //                       </span>
    //                     </div>
    //                   </div>
    //                   <div className="counter-item">
    //                     <div className="counter-item-inner">
    //                       <h1 className="counter-numb counter color2">100+</h1>
    //                       <span className="signika sig-config">
    //                       Partners Across the Globe
    //                       </span>
    //                     </div>
    //                   </div>
    //                   <div className="counter-item">
    //                     <div className="counter-item-inner">
    //                       <h1 className="counter-numb counter color1">900+</h1>
    //                       <span className="signika sig-config">Events </span>
    //                       <br />
    //                       <span className="signika sig-config">So Far</span>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </Container>
    //         </>
    //       </div>
    //     </div>
    //   </div>



    // </div>
    <>
      <section className="eltai-section p-md-5 p-3  overallPadding">
        <div className="stats-grid d-md-grid justify-content-evenly">
          {stats.map((stat, index) => (
            <div key={index} className="stat-box">
              <h2>{stat.number}</h2>
              <p>{stat.label}</p>
            </div>
          ))}
        </div>
        <h1 className="about_heading alata-light ">ELTAI: Empowering Educators, Enriching Classrooms</h1>
        <div class="about_content">
          <video class="about_video" controls>
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p className="about_txt">
            The English Language Teachers' Association of India (ELTAI) stands
            as the largest and oldest network of English language and literature
            educators in the country. Established on 7 August 1969 by the
            visionary Padmashree S. Natarajan, ELTAI has grown into a thriving
            community that continues to inspire and support teachers on their
            journey of professional growth.
          </p>
          <p className="about_txt">
            Like the resilient Indian banyan tree, our organisation has spread
            its roots far and wide, evolving from a regional initiative to a
            nationwide movement. Today, we proudly encompass 5,500 members,
            70+ chapters, and collaborations with over 100 institutions, as well
            as 12 partnering Teacher Associations abroad. Our prop roots
            symbolise our outreach, strengthening and expanding a canopy that nurtures the
            English language teaching community across India.
          </p>
          <p className="about_txt">
            At ELTAI, we are driven by a commitment to identifying and advancing
            homegrown approaches to English Language and Literature Teaching,
            which we refer to as <b>Indian ELLT</b>. We host a range of enriching
            programmes, including international annual conferences, seminars,
            workshops, webinars, and faculty development programmes (FDPs).
            Moreover, our intellectual contributions extend to publishing four
            peer-reviewed journals, providing a platform for educators to engage
            with cutting-edge research and innovative teaching strategies.
          </p>
          <p className="about_txt">Join us as we celebrate over five decades of excellence in language education, fostering a collaborative spirit that empowers
            educators to create classrooms where languages come alive and learners unlock their full potential. Together, let us continue
            to shape the future of education in India and beyond.</p>
          <div className="mb-5">
            <Button className='float-end dynamicButton' onClick={joinMember}>Join Us</Button>
          </div>

        </div>

      </section>
    </>


  );
}

export default AboutSection;
