import React, { useCallback, useEffect, useState } from "react";
import TopHeader from "../../Component/Header/TopHeader";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Modal
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGalleryByIdMutation,
  useGetApproveRejectGalleryMutation,
  useDeleteGalleryMutation,
} from "../../app/services/galleryService";
import { Button } from "@mui/material";
import "../../style/viewGallery.css";
import { TextField, Typography, InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { useSelector } from "react-redux";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

function Showgallery() {
  const { id } = useParams();
  const [getById] = useGalleryByIdMutation();
  const [itemsChecked, setItemsChecked] = useState(false); //to patch the checkbox while click the select all
  const [getGalleryData, setGetGalleryData] = useState([]);
  const [mainGallery, setMainGallery] = useState();
  const [approveOrRejectGalleryImage] = useGetApproveRejectGalleryMutation();
  const [deleteGallery] = useDeleteGalleryMutation();
  const [disableReject, setDisableReject] = useState(true);
  const [loading, setLoading] = useState(false);
  const memberScope = useSelector(selectCurrentScope);

  const userId = useSelector(selectUserId);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };

  const handleClose = () => {
    setOpen(false)
  };
  const handleOpen1 = (id) => {
    setOpen1(true);
    setSelectedMember(id);
  };

  const handleClose1 = () => {
    setOpen1(false)
  };

  //get gallery by id
  const getGalleryDataById = useCallback(() => {
    setLoading(true);
    const data = {
      role: memberScope
    }
    getById({ id, data })
      .then((res) => {
        setLoading(false);
        if (res.data?.status) {
          setMainGallery({ ...res?.data?.data, rejectionReason: "" });
          const data = res?.data?.data?.list.map((item) => {
            if (item?.listIsApproved !== 2) {
              return {
                ...item,
                checked: false,
                listRejectionReason:
                  memberScope === "PORTAL_SUPER_ADMIN"
                    ? ""
                    : item?.listRejectionReason,
              };
            }
            else {
              return item
            }
          });
          const filteredData = data;
          setGetGalleryData(filteredData);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }, [getById, memberScope, id]);

  // get  all the gallery data by the id and set in the value
  useEffect(() => {
    if (id) {
      getGalleryDataById();
    }
  }, [id, getGalleryDataById]);

  // while clicking the single checkbox it will  get the value
  const handleCheckboxClick = (e) => {
    const { value } = e.target;
    const updatedItems = getGalleryData.map((item) =>
      item._id === value ? { ...item, checked: !item.checked } : item
    );
    setDisableReject(true);
    setItemsChecked(!itemsChecked);
    setGetGalleryData(updatedItems);
  };

  //to select all the checkbox and get the of the image list
  const selectItem = (e) => {
    const { checked } = e.target;
    setItemsChecked(checked);
    setDisableReject(true);
    const updatedItem = getGalleryData.map((item) => {
      if (item.listIsApproved === 0) {
        return { ...item, checked: checked, listRejectionReason: "" };
      }
      else {
        return item
      }
    });
    setGetGalleryData(updatedItem);
  };

  //Handling delete
  const handleDelete = () => {
    const data = { galleryId: mainGallery?._id, galleryListId: selectedMember };
    setLoading(true);
    deleteGallery(data).then((res) => {
      if (res?.data?.status) {
        setLoading(false);
        toast.error("Gallery Item deleted Successfully");
        handleClose();
        setTimeout(() => {
          getGalleryDataById();
        }, 500);
      } else {
        setLoading(false);
        toast.error(res?.data?.err?.message);
      }
    });
  };

  //Handling approve/reject
  const approveOrReject = (type) => {
    let data;
    if (type === 1) {
      data = {
        galleryId: id,
        galleryListId: getGalleryData
          .filter((item) => item.checked)
          .map(({ _id, listRejectionReason }) => ({
            id: _id,
            listRejectionReason: listRejectionReason.replace(/\n/g, " "),
          })),
        type: 1,
      };
    }
    if (type === 0) {
      data = {
        galleryId: id,
        galleryListId: getGalleryData
          .filter((item) => item.checked)
          .map(({ _id }) => ({ id: _id })),
        type: 0,
      };
    }
    approveOrRejectGalleryImage(data).then((res) => {
      if (res.data.status) {
        toast.success(res.data?.message);
        window.history.back();
        getGalleryDataById();
        setItemsChecked(false);
      }
    });
  };

  //handleChange
  const handleChangeMain = (e) => {
    const value = e.target.value.trim();
    if (value.length > 0) {
      setDisableReject(false);
    } else {
      setDisableReject(true);
    }
    const updatedData = getGalleryData.map((item) => {
      return { ...item, listRejectionReason: value };
    });
    setGetGalleryData(updatedData);
    setMainGallery({
      ...mainGallery,
      list: updatedData,
      rejectionReason: value,
    });
  };
  return (
    <div className='page-containter'>
      <TopHeader />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {(memberScope !== "PORTAL_SUPER_ADMIN" || memberScope !== "PORTAL_ADMIN" && mainGallery) ?
            <div className='mt-5 mb-3'>
              <div className=' About-us-header d-flex align-items-center justify-content-center mt-5'>
                <h2 className='signika-fontOEC text-center '>Gallery ({mainGallery && mainGallery?.category})</h2>
              </div>
            </div> :
            <div className='mt-5 mb-3'>
              <div className=' About-us-header d-flex align-items-center justify-content-center mt-5'>
                <h2 className='signika-fontOEC text-center '>Approve / Reject Gallery</h2>
              </div>
            </div>
          }
          <div className="text-end">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right", marginRight: 30 }}
            >
              Back
            </Button>
          </div>
          <div className="w-100 p-md-5 p-3 overallPadding">
            {(getGalleryData.some((item) => item?.listIsApproved === 0) && memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_ADMIN') && (
              <div className="select all">
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={
                        getGalleryData.some((item) => item?.checked) &&
                        !itemsChecked
                      }
                      checked={itemsChecked}
                      onClick={selectItem}
                    />
                  }
                  label={itemsChecked ? "Clear All" : "Select All"}
                  sx={{ float: "right", width: "200px" }}
                />
              </div>
            )}
            <div className="col-12">
              <div className="row mt-5 w-100" style={{ paddingLeft: 0 }}>
                {getGalleryData.length > 0 ?
                  getGalleryData.map((data, index) => (
                    <div className="col-lg-4 mb-5" key={index}>
                      <Card className="position-relative" sx={{ maxWidth: 345 }}>
                        {data?.listIsApproved === 0 && (
                          <h4
                            style={{ left: "87%" }}
                            className="card-title position-absolute top-0 viewcardBgtxt text-center"
                          >
                            {memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_ADMIN' && <Checkbox
                              value={data._id}
                              checked={data.checked}
                              onChange={handleCheckboxClick}
                              sx={{
                                color: "black",
                                "&:checked": {
                                  color: "pink",
                                },
                              }}
                            />}
                          </h4>
                        )}
                        <CardMedia
                          component="img"
                          alt="gallery"
                          height="140"
                          image={data.imageUrl}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {mainGallery?.category}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {data?.description}
                          </Typography>
                        </CardContent>
                        {(memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN' || memberScope === 'PORTAL_ADMIN') && (
                          <CardActions className="d-flex justify-content-end">
                            <div
                              className={`px-2 fw-bold p-1 d-flex align-items-center ${data?.listIsApproved === 2
                                ? "text-danger"
                                : data?.listIsApproved === 0
                                  ? "text-warning"
                                  : "text-success"
                                }`}
                            >
                              {data?.listIsApproved === 2
                                ? <>Rejected{userId === data?.createdBy?.id && <i onClick={() => handleOpen1(data._id)} title="View Reject Reason" className="fa fa-solid fa-eye text-danger cursor-pointer ms-1"></i>}</>
                                : data?.listIsApproved === 0
                                  ? "Pending"
                                  : "Approved"}
                              {(data.listIsApproved === 2) && (
                                <></>
                              )}
                              {(data?.listIsApproved === 2 || data?.listIsApproved === 0) &&
                                userId === data?.createdBy?.id && (
                                  <IconButton
                                    aria-label="delete"
                                    title="Delete"
                                    onClick={() => {
                                      handleOpen(data._id);
                                    }}
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </IconButton>
                                )}
                            </div>

                          </CardActions>
                        )}
                      </Card>
                    </div>
                  )) : <>
                    <div className='text-center text-danger mb-5 display-5 w-100'>No Data Available</div>
                  </>}
                {(itemsChecked ||
                  getGalleryData.some((item) => item?.checked)) && (
                    <div className="w-100 text-center">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{ maxWidth: "350px", minWidth: "300px" }}
                        multiline
                        inputProps={{ maxLength: "300", marginTop: 5 }}
                        onChange={(e) => handleChangeMain(e)}
                        label={
                          <Typography className="text-dark">
                            Reason For Rejection of all items{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {300 - mainGallery?.rejectionReason.length}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
            {(mainGallery?.list.some((item) => item?.listIsApproved === 0) && (memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_ADMIN') && itemsChecked) && (


              <div className="w-100 text-center">
                <Button
                  variant="contained"
                  color="error"
                  disabled={disableReject}
                  onClick={() => approveOrReject(1)}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: "20px" }}
                  color="success"
                  onClick={() => approveOrReject(0)}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
          {/* {(memberScope === "PORTAL_SUPER_ADMIN" || !memberScope || memberScope === 'PORTAL_MEMBER') ? (
            <div>
              <div className="text-end">
                <Button
                  onClick={() => window.history.back()}
                  variant="contained"
                  className="menus-color"
                  style={{ float: "right", marginRight: 30 }}
                >
                  Back
                </Button>
              </div>
              {getGalleryData.some((item) => item?.listIsApproved === 0) && (
                <div className="select all">
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={
                          getGalleryData.some((item) => item?.checked) &&
                          !itemsChecked
                        }
                        checked={itemsChecked}
                        onClick={selectItem}
                      />
                    }
                    label={itemsChecked ? "Clear All" : "Select All"}
                    sx={{ float: "right", width: "200px" }}
                  />
                </div>
              )}

              <div className=" container row mt-5" style={{ paddingLeft: 78 }}>
                {getGalleryData && getGalleryData.map((data, index) => (
                  <div className="col-lg-4 mb-5" key={index}>
                    <Card className="position-relative" sx={{ maxWidth: 345 }}>
                      {data?.listIsApproved === 0 && (
                        <h4
                          style={{ left: "87%" }}
                          className="card-title position-absolute top-0 viewcardBgtxt text-center"
                        >
                          <Checkbox
                            value={data._id}
                            checked={data.checked}
                            onChange={handleCheckboxClick}
                            sx={{
                              color: "black",
                              "&:checked": {
                                color: "pink",
                              },
                            }}
                          />
                        </h4>
                      )}
                      <CardMedia
                        component="img"
                        alt="gallery"
                        height="140"
                        image={data.imageUrl}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {mainGallery?.category}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {data?.description}
                        </Typography>
                      </CardContent>
                      {(memberScope === 'PORTAL_SUPER_ADMIN' || memberScope === 'PORTAL_SIG_ADMIN' || memberScope === 'PORTAL_CHAPTER_ADMIN') && (
                        <CardActions className="d-flex justify-content-end">
                          <div
                            className={`px-2 fw-bold p-1 d-flex align-items-center ${data?.listIsApproved === 2
                              ? "text-danger"
                              : data?.listIsApproved === 0
                                ? "text-warning"
                                : "text-success"
                              }`}
                          >
                            {data?.listIsApproved === 2
                              ? "Rejected"
                              : data?.listIsApproved === 0
                                ? "Pending"
                                : "Approved"}
                          {(data?.listIsApproved === 2 || data?.listIsApproved === 0) &&
                            userId === data?.createdBy?.id && (
                              <IconButton
                                        aria-label="delete"
                                        title="Delete"
                                        onClick={() => {
                                          handleOpen(data._id);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                            )}
                          </div>

                        </CardActions>
                      )}
                    </Card>
                  </div>
                ))}
                {(itemsChecked ||
                  getGalleryData.some((item) => item?.checked)) && (
                    <div className="w-100 text-center">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        sx={{ maxWidth: "350px", minWidth: "300px" }}
                        multiline
                        inputProps={{ maxLength: "300", marginTop: 5 }}
                        onChange={(e) => handleChangeMain(e)}
                        label={
                          <Typography className="text-dark">
                            Reason For Rejection Of All Items{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {300 - mainGallery?.rejectionReason.length}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
              </div>
              {mainGallery?.list.some((item) => item?.listIsApproved === 0) && (
                <div className="w-100 text-center">
                  <Button
                    variant="contained"
                    color="error"
                    disabled={disableReject}
                    onClick={() => approveOrReject(1)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "20px" }}
                    color="success"
                    onClick={() => approveOrReject(0)}
                  >
                    Approve
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div>
                <Container maxWidth={"xl"}>
                  <div className="text-end">
                    <Button
                      onClick={() => window.history.back()}
                      variant="contained"
                      className="menus-color"
                      style={{ float: "right", marginRight: 30 }}
                    >
                      Back
                    </Button>
                  </div>
                  <h3
                    className="heading_h3_bearers mt-4 ms-4"
                    style={{ textAlign: "center" }}
                  >
                    {getGalleryData.category}
                  </h3>
                  <Box sx={{ width: "80%" }} className="memberTable mb-5">
                    <Paper sx={{ width: "100%", mb: 2 }}>
                      <div className="float-right"></div>
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHead />
                          <TableBody>
                            {getGalleryData.length > 0 ? (
                              getGalleryData.map((galery, index) => (
                                <TableRow
                                  hover
                                  key={index}
                                  sx={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      index % 2 === 0 ? "#f0f0f0" : "white",
                                  }}
                                >
                                  <TableCell align="center">
                                    <img
                                      src={galery?.imageUrl}
                                      width={"150px"}
                                      height={"150px"}
                                      alt="gallery"
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "200px" }}
                                    align="center"
                                  >
                                    {galery?.description.slice(0, 50)}...
                                  </TableCell>
                                  <TableCell align="center">
                                    {galery?.listRejectionReason
                                      ? galery?.listRejectionReason
                                      : "---------------"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      color: `${galery?.listIsApproved === 0
                                        ? "#766bcb"
                                        : galery.listIsApproved === 1
                                          ? "green"
                                          : "red"
                                        }`,
                                    }}
                                  >
                                    {galery?.listIsApproved === 0
                                      ? "Pending"
                                      : galery.listIsApproved === 1
                                        ? "Approved"
                                        : "Rejected"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {userId === galery?.createdBy?.id &&
                                      galery.listIsApproved !== 1 ? (
                                      <IconButton
                                        aria-label="delete"
                                        title="Delete"
                                        onClick={() => {
                                          handleOpen(galery._id);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                    ) : (
                                      <>----------</>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  No Data Found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    
                    </Paper>
                  </Box>
                </Container>
              </div>
            </>
          )} */}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: "20px",
            outline: 'none',
          }}
        >
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e" }}
            variant="h6"
            component="h2"
          >
            Are You Surely Want to Delete this Image?
          </Typography>
          <div>
            <Button
              variant="outlined"
              color="success"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              className="mx-2"
              variant="contained"
              color="error"
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: "20px",
            outline: 'none',
          }}
        >
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e" }}
            variant="h6"
            component="h2"
          >
            Reject Reason
          </Typography>
          <div>
            {getGalleryData.map((item, index) => (
              <>
                {item._id === selectedMember && <p key={index}>{item?.listRejectionReason}</p>}
              </>
            ))}
          </div>
          <div>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose1}
            >
              close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Showgallery;
