import React, { useEffect, useState } from "react";
import {
  useLazyGetAllMemberShipDetailsQuery,
  useDisableMemberShipMutation,
} from "../../app/services/memberShipServices";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../Component/Header/TopHeader";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Spinner from "../../Component/spinner/spinner";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import joineltaibackground from "../../assets/images/joinetai/bgimage.png";



const ViewMembershipPlan = () => {
  const [memberShipPlans, setMemberShipPlans] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getAllMershipPlans] = useLazyGetAllMemberShipDetailsQuery();
  const [disableMembershipPlanApi] = useDisableMemberShipMutation();
  const [loadingPlanId, setLoadingPlanId] = useState(null);


  // getAllMershipPlans
  useEffect(() => {
    setLoading(true);
    getAllMershipPlans().then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setMemberShipPlans(res.data?.data);
      }
      else {
        setLoading(false);
      }
    });
  }, [getAllMershipPlans, memberShipPlans]);

  const showname = (name) => {
    if (name === '0') {
      return 'Individual Teacher'
    }
    else if (name === '1') {
      return 'Dual Teacher'
    }
    else if (name === '2') {
      return 'Individual Student'
    }
    else if (name === '3') {
      return 'Institutional'
    }
    else if (name==='4'){
      return 'Corporate Supporter'
    }
    else{
      return name
    }
  }

  //disableMemberShipPlan
  const disableMemberShipPlan = (id) => {
    if (id) {
      disableMembershipPlanApi(id)
        .then((res) => {
          setLoading(true);
          setLoadingPlanId(id);
          if (res?.data?.status) {
            setLoading(false);
            setLoadingPlanId(null);
            if (res?.data?.data.isDeleted === 2) {
              toast.error("Plan Disabled successfully!!!");
            } else {
              setLoading(false);
              setLoadingPlanId(null);
              toast.success("Plan Enabled Successfully!!!");
            }
            getAllMershipPlans().then((res) => {
              if (res.data?.status) {
                setMemberShipPlans(res.data?.data);
              }
            });
          } else {
            setLoading(false);
            setLoadingPlanId(null);
            toast.error(res?.data?.err?.message);
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  return (
    <div>
      <TopHeader />
      <Box
        sx={{
          width: "100%",
          height: "max-height",
          backgroundImage: `url(${joineltaibackground})`,
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">All Membership Plans</h2>
          </div>
        </div>
        {loading && !loadingPlanId ? (
          <Spinner />
        ) : (

          <div className=" subscribtion-card p-md-5 p-3 overallPadding">
            <div className="w-100 text-end mb-3">
              <Button
                onClick={() => navigate("/admin/addEditMembershipPlan")}
                variant="contained"
                className="menus-color rounded-0"
                type="submit"
              >
                Add Membership Plan
              </Button>
            </div>
            <Grid
              container
              spacing={{ xs: 4, md: 3.5 }}
              columns={{ xs: 4, sm: 4, md: 12 }}
            >
              {memberShipPlans.length > 0 ? (
                memberShipPlans.map((plan, index) => {
                  return (
                    <Grid item xs={12} sm={4} md={4} >
                      <div
                        className="membercard-container1"
                      >
                        <Card key={plan._id} sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                          <CardContent className="getall_customScroll">
                            <div className="text-center d-flex">
                              <p className="Subscribtion-card-heading menus-green-color fw-bold alata-bold text-capitalize mb-0">
                                <b>{showname(plan.planName)}</b>
                              </p>
                              {
                                !(plan.isDeleted === 2) &&
                                <Tooltip title="Edit" placement="top">
                                  <IconButton
                                    aria-label="EditIcon"
                                    onClick={() =>
                                      navigate("/admin/addEditMembershipPlan/" + plan._id)
                                    }
                                  >

                                    <EditIcon style={{ color: "green" }} />

                                  </IconButton>
                                </Tooltip>
                              }

                              <Tooltip placement="top" title={plan.isDeleted === 2 ? "Disable" : "Enable"}>
                                {loading && loadingPlanId === plan._id ? (
                                  <Spinner />
                                ) : plan.isDeleted === 2 ? (
                                  <IconButton aria-label="LockIcon" onClick={() => disableMemberShipPlan(plan._id)}>
                                    <LockIcon style={{ color: "red" }} />
                                  </IconButton>
                                ) : (
                                  <IconButton placement="top" aria-label="LockOpenIcon" onClick={() => disableMemberShipPlan(plan._id)}>
                                    <LockOpenIcon style={{ color: "green" }} />
                                  </IconButton>
                                )}
                              </Tooltip>

                            </div>

                            <div className="subscribtion-prize">
                              {plan.planType.map((item, i) => (
                                <Container
                                  sx={{ mt: 1 }}
                                  maxWidth="lg"
                                // key={index}
                                >
                                  <div className="row justify-content-center align-items-center">
                                    {/* <div className="col-8"> */}
                                    <p
                                      className="d-flex justify-content-between signika align-items-center fs-4 text-secondary"
                                    >
                                      <span>{item.planTypeName}({item?.duration}Year) :</span>  <span className="text-black fw-semibold">{(item.currencyType === 1) ? "$" : "₹"}{item.amount}</span>
                                    </p>
                                    {item?.offer > 0 && <p className="d-flex justify-content-between signika align-items-center fs-4 text-secondary"
                                    >
                                      <span>  Offer ({item?.offer}%) : </span><span className="text-black fw-semibold">{(item.currencyType === 1) ? "$" : "₹"}{item?.offerAmount}</span>
                                    </p>}
                                    {item?.discount > 0 && <p className="d-flex justify-content-between signika align-items-center fs-4 text-secondary"
                                    >
                                      <span> Renewal Discount ({item?.discount}%): </span><span className="text-black fw-semibold">{(item.currencyType === 1) ? "$" : "₹"}{item?.discountAmount}</span>
                                    </p>}
                                    {/* </div> */}
                                    {i < plan?.planType.length - 1 && <hr />}
                                  </div>
                                </Container>
                              ))}

                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={"card_join card-container"}>
                      <CardContent>
                        <div className="text-center">
                          <p className={"Subscribtion-card-heading"}>
                            No Membership Plans Are Added
                          </p>
                        </div>
                      </CardContent>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
      </Box>
    </div>
  );

};

export default ViewMembershipPlan;
