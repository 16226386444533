import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateAnnualConferenceMutation, useLazyGetAnnualConferenceByIdQuery,
  useUpdateAnnualConferenceMutation
} from '../../../app/services/annualConferenceService';
import { toast } from 'react-toastify';
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import placeholder from "../../../assets/images/placeholder/placeholder.png"
import Spinner from "../../../Component/spinner/spinner";


const AddEditImportAnnualConference = () => {
  const [createAnnualConference] = useCreateAnnualConferenceMutation();
  const navigate = useNavigate();
  const { id } = useParams()
  const [getById] = useLazyGetAnnualConferenceByIdQuery();
  const [updateAnnualConference] = useUpdateAnnualConferenceMutation();
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const [selectedDesktopImage, setSelectedDesktopImage] = useState();
  const [isValidImage, setIsValidImage] = useState(true);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { conferenceName: '', venue: '', date: '', imageUrl: '', hostedBy: '' }
  });

  useEffect(() => {
    getById(id).then((res) => {
      if (res?.data?.status) {
        Object.entries(res?.data?.data).forEach(([key, value]) => {
          setValue(key, value)
        })
      }
    })
      .catch((err) => console.log(err))
  }, [id, setValue, getById])

  const onSaveConference = (data) => {
    if (!isValidImage) {
      return; // Stop the form submission if the image is invalid or greater than 2MB
    }
    setLoading(true);

    if (id) {
      const updateData = {
        id,
        data
      }
      const base64Regex =
        /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
      const uploadImg = data?.imageUrl;
      if (base64Regex.test(data?.imageUrl)) {
        data.imageUrl = uploadImg; // No change needed if it's base64
      } else {
        data.imageUrl = data.imageUrl
          .split("/")
          .slice(-2)
          .join("/");
      }
      updateAnnualConference(updateData).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message)
          navigate('/admin/annualConferenceList')
        }
        else {
          toast.error(res?.data?.message)
        }
        setLoading(false);
      }).catch(err => console.log(err))
    }
    else {
      const newData = {
        ...data,
        createdBy: { id: userId, role: memberScope }
      }
      // Call the mutation to create the conference
      createAnnualConference(newData).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message)
          navigate('/admin/annualConferenceList')
          reset()
        } else {
          toast.error(res?.data?.err?.message)
        }
        setLoading(false);

      })
        .catch(err => console.error(err))
    }

  };

  // Handle image file change
  const handleDesktopImageChange = (event) => {
    const file = event.target.files[0];
    setImageErrorMessage(""); // Reset error state

    if (!file) {
      setImageErrorMessage("No file selected.");
      setIsValidImage(false);
      return;
    }

    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (!validTypes.includes(file.type)) {
      setImageErrorMessage("Please upload a valid JPEG or PNG image.");
      setIsValidImage(false);
      return;
    }

    if (file.size > maxSize) {
      setImageErrorMessage("File size exceeds 5MB.");
      setIsValidImage(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;

      // Validate image dimensions
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;

        if (width < 1380 || width > 1400) {
          setImageErrorMessage("Width must be between 1380px and 1400px.");
          setIsValidImage(false);
          return;
        }

        if (height < 360 || height > 380) {
          setImageErrorMessage("Height must be between 360px and 380px.");
          setIsValidImage(false);
          return;
        }

        // Set valid image data
        setValue("imageUrl", base64String); // Update form value
        setImageErrorMessage(""); // Clear any previous errors
        setIsValidImage(true);
      };

      img.onerror = () => {
        setImageErrorMessage("Invalid image file.");
        setIsValidImage(false);
      };

      img.src = base64String;
    };

    reader.readAsDataURL(file);
  };


  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{id ? "Edit Annual Conference List" : "Add Annual Conference List"}</h2>
          </div>
        </div>
        {loading ? <Spinner /> : (

          <form onSubmit={handleSubmit(onSaveConference)}>
            <div className='p-md-5 p-3 overallPadding' >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                <Button onClick={() => navigate(-1)} variant='contained' style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">Back</Button>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="conferenceName"
                    label={
                      <Typography className="text-dark">
                        Conference Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    placeholder="Enter Conference Name"
                    variant="outlined"
                    {...register("conferenceName", {
                      required: "Please enter a conference name",
                      minLength: { value: 3, message: "Conference name must be at least 3 characters long" }
                    })}
                    error={!!errors?.conferenceName}
                    helperText={errors?.conferenceName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="venue"
                    placeholder="Enter Venue"

                    label={
                      <Typography className="text-dark">
                        Venue <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    {...register("venue", {
                      required: "Please enter a venue",
                    })}
                    error={!!errors?.venue}
                    helperText={errors?.venue?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="date"
                    label={
                      <Typography className="text-dark">
                        Date <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    placeholder="Enter Date"
                    variant="outlined"
                    {...register("date", {
                      required: "Please enter a date",
                    })}
                    error={!!errors?.date}
                    helperText={errors?.date?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="hostedBy"
                    label={
                      <Typography className="text-dark">
                        Hosted By <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    placeholder="Enter  Hosted By"
                    variant="outlined"
                    {...register("hostedBy", {
                      required: "Please enter a host name",
                    })}
                    error={!!errors?.hostedBy}
                    helperText={errors?.hostedBy?.message}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>

                  {id ? (
                    <>
                      <img
                        src={selectedDesktopImage
                          ? selectedDesktopImage
                          : watch("imageUrl")
                            ? watch("imageUrl")
                            : placeholder}
                        alt="Logo Preview"
                        height="150"
                        className="img-responsive w-100"
                      />
                      <label htmlFor="imageUrl" style={{ display: "none" }}>

                        <TextField
                          inputProps={{
                            accept: "image/jpeg, image/png",
                          }}
                          id="imageUrl"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleDesktopImageChange}
                          alt="Journal Form"
                        />
                      </label>

                      <Button
                        variant="contained menus-color text-colors"
                        component="span"
                        style={{ whiteSpace: "nowrap" }}
                        className="mt-2"
                        onClick={() => document.getElementById("imageUrl").click()}

                      >
                        Choose File
                      </Button>
                      {imageErrorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {imageErrorMessage}
                        </div>
                      )}
                    </>

                  ) : (
                    <>

                      <div className='d-flex justify-content-center'>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          style={{
                            width: "100%",
                          }}
                          inputProps={{
                            accept: "image/jpeg, image/png",
                          }}
                          id="imageUrl"
                          label={
                            <Typography className="text-dark">
                              Image Url <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          variant="outlined"
                          type="file"
                          {...register("image", {
                            required: { value: !watch('imageUrl'), message: 'Please select a image' },
                            onChange: (e) => handleDesktopImageChange(e),
                          })}
                          error={!!errors?.image || imageErrorMessage} // Make sure the field name matches
                          helperText={errors?.image?.message || imageErrorMessage}
                        />
                      </div>
                    </>

                  )}
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
                <Button variant='contained' type="submit" style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">{id ? "Update" : "Submit"}</Button>
              </Box>
            </div>
          </form >
        )}
      </Box >
    </div >
  );
};

export default AddEditImportAnnualConference;
