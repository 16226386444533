import React from 'react'
import './partner.css'
import image15 from '../../../assets/images/partner/150/15.png'
// import { Container } from '@mui/material'
import { TeacherAssocation, Corporates, educationInsititue } from '../../../constant/constant'
const Partner = () => {
  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Our Partner (Past & Present)</h2>
      </div>
      <div className='p-md-5 p-3 overallPadding'>
      <div className='d-flex justify-content-center '>
        <img src={image15} alt='our-partner' className='ourExecutiveCommittee_partner-image' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Teacher Associations </h4>
        <h4 className='ourExecutiveCommittee_partner-image-number' style={{ color: "white" }}>30</h4>
        <h4 className='ourExecutiveCommittee_partner-image-number' style={{ color: "white" }}>30</h4>
      </div>
      <div className='mt-2 pb-3 '>
        {/* <Container maxWidth={"lg"}> */}
          <div className='d-flex'>
            <div className='row'>
              {TeacherAssocation.map((data, i) => {
                return (
                  <>
                    <div className='col-md-4 col-sm-6 col-xl-3 pt-1 pl-1 containter-partner'>
                      <div>
                        <div className='teacher-asso-card p-3'>
                          <div className='d-flex justify-content-center'>
                            <img src={data.img} width={"100px"} alt='our-partner' className='"our-partner-img' />
                          </div>
                          <p className='signika-font details-partner text-center'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
        {/* </Container> */}
      </div>
      <div className='d-flex align-item-center justify-content-center p-5'>
        <img src={image15} className='ourExecutiveCommittee_partner-image' alt='our-partner' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Corporates </h4>
        <h4 className='ourExecutiveCommittee_partner-image-number'>15</h4>
      </div>
      <div className='mt-2 pb-3'>
        {/* <Container maxWidth={"lg"}> */}
          <div className='row'>
            <div className='d-flex justify-content-center'>
              {Corporates.map((data, i) => {
                return (
                  <>
                    <div className='col-md-3 pt-1 pl-1 containter-partner'>
                      <div>
                        <div className='teacher-asso-card p-3'>
                          <div className='d-flex justify-content-center'>
                            {data.img ? (
                              <img src={data.img} alt="company" />
                            ) : (
                              <></>
                            )}
                          </div>
                          <p className='signika-font details-partner text-center'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
        {/* </Container> */}
      </div>
      <div className='d-flex justify-content-center p-5'>
        <img src={image15} className='ourExecutiveCommittee_partner-image' alt='our-partner' />
        <h4 className='ourExecutiveCommittee_partner-image-header'>Institutions</h4>
        <h4 className='ourExecutiveCommittee_partner-image-number'>10</h4>
      </div>
      <div className='mt-2 pb-3'>
          <div className='row'>
            <div className='d-flex justify-content-center'>
              {educationInsititue.map((data, i) => {
                return (
                  <>
                    <div className='col-md-3 pt-1 pl-1 containter-partner'>
                      <div>
                        <div className='teacher-asso-card p-1'>
                          <div className='d-flex justify-content-center'>
                            {data.img ? (
                              <img src={data.img} alt="company" />
                            ) : (
                              <></>
                            )}
                          </div>
                          <p className='signika-font details-partner text-center mb-0'>{data.companyName}</p>
                          <p className='signika-font details-partner text-center'>{data.country}</p>
                        </div>
                      </div>

                    </div>
                  </>
                )
              })}
            </div>
          </div>
      </div>
      </div>
    </div>
  )
}

export default Partner