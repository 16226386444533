import Missionicon1 from "../assets/images/AboutPage/about us/our mission - icons/1.png";
import Missionicon2 from "../assets/images/AboutPage/about us/our mission - icons/2.png";
import Missionicon3 from "../assets/images/AboutPage/about us/our mission - icons/3.png";
import Missionicon4 from "../assets/images/AboutPage/about us/our mission - icons/4.png";
import Missionicon5 from "../assets/images/AboutPage/about us/our mission - icons/5.png";
import Missionicon6 from "../assets/images/AboutPage/about us/our mission - icons/6.png";
import Historybadge1 from "../assets/images/histroy/Group 1392.png";
import Histroybadge2 from "../assets/images/histroy/Group 1393.png";
import Histroybadge3 from "../assets/images/histroy/Group 1394.png";
import Historybadge4 from "../assets/images/histroy/Group 1395.png";
import Historybadge5 from "../assets/images/histroy/Group 1396.png";
import Histroybadge6 from "../assets/images/histroy/Group 1397.png";
import Histroybadge7 from "../assets/images/histroy/Group 1398.png";
import Histroybadge8 from "../assets/images/histroy/Group 1399.png";
import Histroybadge9 from "../assets/images/histroy/Group 1400.png";
import Histroybadge10 from "../assets/images/histroy/Group 1401.png";
import Histroybadge11 from "../assets/images/histroy/Group 1402.png";
import Histroybadge12 from "../assets/images/histroy/Group 1403.png";
import Histroybadge13 from "../assets/images/histroy/Group 1404.png";
import Layer from "../assets/images/ourExecutiveCommittee/Layer_1.png";
import Elango from "../assets/images/ourExecutiveCommittee/Elango.png";
import Shravan from "../assets/images/ourExecutiveCommittee/Shravan Kumar.png";
import Kumari from "../assets/images/ourExecutiveCommittee/Kharbamon.png";
import Dhanesh from "../assets/images/ourExecutiveCommittee/Dhanesh Sinha.png";
import Lal from "../assets/images/ourExecutiveCommittee/Lal.png";
import Mangai from "../assets/images/ourExecutiveCommittee/Mangai.png";
import Prakash from "../assets/images/ourExecutiveCommittee/Prakash.png";
import Praveen from "../assets/images/ourExecutiveCommittee/Praveen Sam.png";
import Reddy from "../assets/images/ourExecutiveCommittee/Raghavendra Reddy.png";
import Ram from "../assets/images/ourExecutiveCommittee/Ram Bhishe.png";
import Vaibhav from "../assets/images/ourExecutiveCommittee/Vaibhav.png";
import xavier from "../assets/images/ourExecutiveCommittee/M S Xavier Pradheep Singh - India.png";
import sigEltaiimage from "../assets/images/SIG/Vector.png";
import Ourpartnerimage1 from "../assets/images/partner/150/1.png";
import Ourpartnerimage2 from "../assets/images/partner/150/2.png";
import Ourpartnerimage3 from "../assets/images/partner/150/3.png";
import Ourpartnerimage4 from "../assets/images/partner/150/4.png";
import Ourpartnerimage5 from "../assets/images/partner/150/5.png";
import Ourpartnerimage6 from "../assets/images/partner/150/6.png";
import Ourpartnerimage7 from "../assets/images/partner/150/7.png";
import Ourpartnerimage8 from "../assets/images/partner/150/8.png";
import Ourpartnerimage9 from "../assets/images/partner/150/9.png";
import Ourpartnerimage10 from "../assets/images/partner/150/10.png";
import Ourpartnerimage11 from "../assets/images/partner/150/11.png";
import Ourpartnerimage12 from "../assets/images/partner/150/12.png";
import Ourpartnerimage13 from "../assets/images/partner/150/13.png";
import Ourpartnerimage14 from "../assets/images/partner/150/14.png";
import DashBoadimage1 from "../assets/images/Membership/4.png";
import DashBoadimage2 from "../assets/images/Membership/3.png";
import DashBoadimage3 from "../assets/images/Membership/1.png";
import DashBoadimage4 from "../assets/images/Membership/2.png";
import DashBoadimage5 from "../assets/images/Membership/5.png";
import InstitutionalImage1 from "../assets/images/Institutional_Members/unversity_2.png";
import InstitutionalImage2 from "../assets/images/Institutional_Members/university_1.png";
//who we are
export const ourMissionData = [
  {
    id: 1,
    img: Missionicon1,
    caption:
      "To provide a forum for teachers of English to meet periodically and discuss problems relating to the teaching and learning of English Language and Literature in India",
  },
  {
    id: 2,
    img: Missionicon2,
    caption:
      "To help teachers interact with educational administrators on matters relating to the teaching of English",
  },
  {
    id: 3,
    img: Missionicon3,
    caption:
      "To disseminate latest information in the field of ELT among teachers of English",
  },
  {
    id: 4,
    img: Missionicon4,
    caption:
      "To undertake innovative projects aiming at the improvement of learners' proficiency in English",
  },
  {
    id: 5,
    img: Missionicon5,
    caption:
      "To promote professional solidarity among teachers of English at primary, secondary, and tertiarylevels",
  },
  {
    id: 6,
    img: Missionicon6,
    caption:
      "To promote professional excellence among its members in all possible ways",
  },
];

//vision
export const points = [
  {
    point:
      "To make India a hub of ELT-related activities and to conceptualise the experiences of practitioners in teaching and learning the English language and symbiotically share them with others.",
  },
  {
    point:
      "To develop into a movement spreading across the length and breadth of the country empowering teachers to make every learner of English globally competitive.",
  },
  {
    point:
      "To become a recognized leader, a catalyst, a facilitator, and a trendsetter in spreading English literacy across the country.",
  },
];

//mission
export const mission = [
  {
    mission:
      "To provide a forum for teachers of English to meet periodically and discuss problems relating to the  teaching and learning of English Language and Literature in India",
  },
  {
    mission:
      "To help teachers interact with educational administrators on matters relating to the teaching of English",
  },
  {
    mission:
      "To disseminate latest information in the field of ELT among teachers of English",
  },
  {
    mission:
      "To undertake innovative projects aiming at the improvement of learners’ proficiency in English",
  },
  {
    mission:
      "To promote professional solidarity among teachers of English at primary, secondary, and tertiary levels",
  },
  {
    mission:
      "To promote professional excellence among its members in all possible ways",
  },
];

//history
export const history = [
  {
    id: 1,
    title: "The Journal First",
    content:
      "Padmashri S. Natarajan started the journal, Journal of English Language Teaching, in 1965, a few years before he founded the association, to create awareness among English teachers of the need for professional development. The journal was given free as an incentive for becoming a member of the association when it would be started later. The journal still serves as a platform for English language educators to access research findings and share experiences.",
    img: Historybadge1,
  },
  {
    id: 2,
    title: "The Association Next",
    content:
      "Shri Natarajan first founded the Society for the Promotion of Education. Under its auspices, he started several subject teachers’ associations and ELTAI was born on 7 August 1969 with the starting membership of six, which gradually increased to about 250 in the first year. The association was started with an aim to promote professional development among English language educators. ",
    img: Histroybadge2,
  },
  {
    id: 3,
    title: "The Registration of the Association Next",
    content:
      " ELTAI was registered under the Registration of Societies Act 1860 and became an officially registered body on 5 July 1974. The first executive committee of ELTAI was formed. Prof. R. Krishnamurthi took charge as the President and Prof. M. Duraiswamy as the Secretary of ELTAI. Then onwards, ELTAI started expanding strategically by conducting workshops, and seminars for English language teachers and offering free copies of the bimonthly journal to members. ",
    img: Histroybadge3,
  },
  {
    id: 4,
    title: "Membership Drive",
    content:
      "A large number of seminars and workshops were conducted regularly by ELTAI at intra- and inter-institutional levels focusing on improving classroom practices. Several regional conferences were also organized by ELTAI. The participants at these professional development programmes were briefed about the benefits of joining ELTAI – the beginning of face-to-face membership drive on a regular and continual basis. ",
    img: Historybadge4,
  },
  {
    id: 5,
    title: "Annual national and International Conference",
    content:
      " ELTAI has been conducting annual conferences at the national level almost every year since its inception and combined national and international conferences since 2005 (36th Annual Conference and 1st International Conference held in Chennai). These annual events are being conducted with the help and support of host institutions and the local chapters. These hugely successful events have also been supported in a big way by the British Council and the Regional English Language Office (RELO). Participation in these conferences has been increasing every year. These days, ELTAI’s annual conferences attract closer to 1000 educators attending and presenting papers.  ",
    img: Historybadge5,
  },
  {
    id: 6,
    title: "Special Interest Groups (SIGs)",
    content:
      "ELTAI has three SIGs: Literature SIG, Computer Technology SIG, and Business English SIG. Membership is open to any active member of ELTAI on a voluntary basis. The former two SIGs started the two open access online journals, Journal of Teaching and Research in English Literature and Journal of Technology in ELT respectively. The SIGs are being reactivated. ",
    img: Histroybadge6,
  },
  {
    id: 7,
    title: "Online Discussion Forums",
    content:
      " Google groups have been formed not only for members of ELTAI but also for members of the SIGs for sharing of relevant information and views on topics of interest to teachers of English, with particular focus on classroom practices and strategies. ELTAI members may join any of these groups by sending an email to ELTAI expressing their interest.",
    img: Histroybadge7,
  },
  {
    id: 8,
    title: "Projects",
    content:
      " ELTAI has been awarded grants by the IATEFL-Hornby Trust three times for our projects: (1) Training trainers and teachers of English in Virtual Learning (2014); (2) Training trainers and teachers in using mobile phone technology in the teaching and learning of English (2016); and (3) Master Trainers’ workshop for our chapter heads in the use of digital tools for learner autonomy in communication skills (2019).  ",
    img: Histroybadge8,
  },
  {
    id: 9,
    title: "Textbook Production",
    content:
      "In 2018, ELTAI ventured into textbook production, collaborating with Cambridge University Press to develop the Monsoon series for CBSE schools. The series had eight books  ",
    img: Histroybadge9,
  },
  {
    id: 10,
    title: "The Association Next",
    content:
      "Shri Natarajan first founded the Society for the Promotion of Education. Under its auspices, he started several subject teachers’ associations and ELTAI was born on 7 August 1969 with the starting membership of six, which gradually increased to about 250 in the first year. The association was started with an aim to promote professional development among English language educators. ",
    img: Histroybadge10,
  },
  {
    id: 11,
    title: "Golden Jubilee Conference",
    content:
      " ELTAI had its 50th Annual Conference in 2019, hosted by Amity University, Noida. To commemorate its epic history, a book tracing the history of ELTAI, titled ELTAI: An Epic Journey of 50 Years was also published in the same year. ",
    img: Histroybadge11,
  },
  {
    id: 12,
    title: "Thriving in Pandemic",
    content:
      "With the closure of educational institutions worldwide during the Covid-19 Pandemic in 2020, ELTAI made its presence online by conducting webinars every Saturday and Sunday. Started on 3 May 2020, these webinars aim to share current trends in English language teaching, highlight recent literature, and connect educators with authors. Initially held twice a week and later every Sunday, these webinars are now held once a month, targeting teachers and students at different levels. ",
    img: Histroybadge12,
  },
  {
    id: 13,
    title: "India Reads",
    content:
      "ELTAI launched a new venture named ‘India Reads’, a movement spreading across the country to revive reading habits among school and college students. This initiative addresses the cognitive decline due to reduced reading in favour of visual media. The movement aims to cultivate lifelong readers, enhance comprehension and retention, and integrate reading into school schedules",
    img: Histroybadge13,
  },
];

//Our Executive Committee
//our eltailsMembers
export const eltaiMembers = [
  {
    name: "Dr. K. Elango",
    role: "CHIEF EXECUTIVE CHAIR",
    Address:
      "Professor & Head (Formerly), Department of English,Anna University,Chennai – 600025, Tamilnadu",
    mobile: "+91 8778494916",
    email: "elango.kandan@gmail.com",
    img: Elango,
  },
  {
    name: "Prof. T. Kumari Kharbamon",
    role: "PRESIDENT",
    Address:
      "PVice Chancellor,Martin Luther Christian University,Dongktieh, Block – 1, Nongrah,Shillong – 793006, Meghalaya",
    mobile: "+91 9366776314",
    email: "tkkharbamon@gmail.com",
    img: Kumari,
  },
  {
    name: "Dr. Shravan Kumar,",
    role: "VICE PRESIDENT",
    Address:
      "Professor and Dean,School of Liberal Arts,Uttaranchal University,Dehradun – 248007, Uttarakhand",
    mobile: "+91 7838361700",
    email: "kumar4shravan@gmail.com",
    img: Shravan,
  },
  {
    name: "Dr. Vaibhav J. Sabnis",
    role: "VICE PRESIDENT",
    Address:
      "Dr. Vaibhav J. Sabnis,Associate Professor,Department of English, Dr. B.A.M.College of Law,Dhule – 424002, Maharashtra",
    mobile: "+91 9422471143",
    email: "vjsabnis@yahoo.co.in",
    img: Vaibhav,
  },
  {
    name: "Dr. M. S. Xavier Pradheep Singh",
    role: "SECRETARY",
    Address:
      "Assistant Professor,Department of English,St. Joseph’s College (Autonomous)Tiruchirappalli – 620002, Tamilnadu",
    mobile: "+91 8220777313",
    email: "xavierpradheep.sjc@gmail.com",
    img: xavier,
  },
  {
    name: "Dr. Ramkishan Bhise",
    role: "JOINT SECRETARY",
    Address:
      "Department of Engineering Sciences and Humanities, SIES, Graduate School of Technology,Nerul, Navi Mumbai – 400706, Maharashtra",
    mobile: "9960569238",
    email: "ram.bhise2009@gmail.com",
    img: Ram,
  },
  {
    name: "Mr. Prakash R. H.",
    role: "JOINT SECRETARY",
    Address:
      "Govt P U College for Boys (High School Section)PWD Camp, Sindhanur,Raichur District - 584128, Karnataka",
    mobile: " 09900643943",
    email: "sldfprakash@gmail.com",
    img: Prakash,
  },
  {
    name: "Mr. P. R. Kesavulu",
    role: "TREASURER",
    Address:
      "Principal (Formerly)Jaya Matriculation Higher Secondary School,Chennai, Tamilnadu",
    mobile: "",
    email: "",
    img: Layer,
  },
  {
    name: "Dr. J. Mangayarkarasi",
    role: "COORDINATOR",
    Address:
      "Associate Professor of English,Ethiraj College for Women,Chennai-600 008",
    mobile: " +91 9444257308",
    email: "jmbwilson97@gmail.com",
    img: Mangai,
  },
];
//ourExecutiveCommitteeData1

//our eltailsMembers
export const ourExecutiveCommitteeData1 = [
  {
    name: "Dr. C. A. Lal.",
    Address:
      "Associate professor,School of Distance Education,University of Kerala,Thiruvananthpuram, Kerala",
    mobile: " +91 9446703790",
    email: "jojilal@gmail.com",
    img: Lal,
  },
  {
    name: "Dr. Irom Gambhir Singh.",
    Address:
      "Head & Associate Professor,Department of English & Cultural Studies, Manipur University,Canchipur, Imphal West, Manipur",
    mobile: " +91 8974398898",
    email: "iromgambhir3@gmail.com",
    img: Layer,
  },
  {
    name: "Dr. D. Praveen Sam",
    Address:
      "Assistant Professor,SSN College of Engineering,Chennai, Tamilnadu",
    mobile: " +91 9940077079",
    email: "praveen.samphd@gmail.comm",
    img: Praveen,
  },
  {
    name: "Dr. Nalina Singh",
    Address:
      ",Amity University, Lucknow Campus,Lucknow – 226028, Uttar Pradesh",
    mobile: " +91 9839227242",
    email: "nsingh@lko.amity.edu",
    img: Layer,
  },
  {
    name: "Dr. C. Raghavendra Reddy",
    Address:
      "Department of English,GITAM University,Visakhapatnam – 530045, Andhra Pradesh",
    mobile: " +91 9494295295",
    email: "crreddyenglish@gmail.com",
    img: Reddy,
  },
  {
    name: "Dr. Vishal Varia",
    Address: "Managing Director, Rosary School, Rajkot – 360005, Gujarat",
    mobile: "91 8980311666",
    email: "drvaria@rosaryschoolrajkot.org",
    img: Layer,
  },
  {
    name: "Mr. Dhanesh Ram Sinha",
    Address:
      "Vill+Po- Lal Bahadur NagarChichola Rajnandgaon – 491557, Chhattisgarh",
    mobile: " +91 9691723284",
    email: "sinhadhanesh32@gmail.com",
    img: Dhanesh,
  },
];
//Presidents
export const Presidents = [
  {
    id: 1,
    Name: "Prof. R. Krishnamurthi",
    years: "August 1974 – October 1985",
  },
  {
    id: 2,
    Name: "Dr. S. Rajagopalan",
    years: "November 1985 – July 2008",
  },
  {
    id: 3,
    Name: "Dr. Amol Padwad",
    years: "August 2008 – March 2012",
  },
  {
    id: 4,
    Name: "Dr. Sanjay Arora",
    years: "April 2012 – December 2014",
  },
  {
    id: 5,
    Name: "Dr. G. A. Ghanshyam",
    years: "January 2015 – October 2018",
  },
  {
    id: 6,
    Name: "Dr. Sanjay Arora",
    years: "November 2018 – March 2024",
  },
];

//Secretaries
export const Secretaries = [
  {
    id: 1,
    Name: "Prof. M. Duraiswamy",
    years: "August 1974 – June 1981",
  },
  {
    id: 2,
    Name: "Prof. B. Ardhanareeswaran",
    years: "July 1981 – October 1985",
  },
  {
    id: 3,
    Name: "Dr. K.K. Mohamed Iqbal",
    years: "Nov 1985 – August 19892",
  },
  {
    id: 4,
    Name: "Dr. V. Saraswathi",
    years: "Sep 1989 – March 2007",
  },
  {
    id: 5,
    Name: "Dr. K. Elango",
    years: "Apr 2007 – March 2024",
  },
];

//SIG
//sig condailter
export const eltaicontainterImage = [
  {
    id: 1,
    image: sigEltaiimage,
    header: "ELTAI Literature SIG",
    context:
      "The Literature SIG focuses on reading, teaching and researching literature. It provides a platform for teachers, researchers and students to explore and share effective methods of using literature to enhance critical thinking skills and English language learning. Members participate in workshops,seminars, and discussions on various aspects of teaching literature, sharing best practices, resources,and research findings. The Literature SIG also publishes a quarterly peer-reviewed online journal,Journal of Teaching and Research in English Literature (JTREL). Membership to this SIG freely available to all short-term and long-term ELTAI members.",
    button: "Visit ELTAI Literature SIG",
  },
  {
    id: 2,
    image: sigEltaiimage,
    header: "Computer Technology SIG",
    context:
      "Dedicated to the use of technology in English language and literature teaching, the Computer Technology SIG offers training and resources on digital tools, educational software, and online teaching methods. This SIG facilitates webinars, tutorials, and forums where members can exchange ideas and experiences related to the application of technology in English language and literature education, ensuring they remain at the forefront of technological advancements in the field. The Computer Technology SIG also publishes a peer-reviewed online journal, Journal of Technology for ELT (JTELT). Membership to this SIG freely available to all short-term and long-term ELTAI members.",
    button: "Visit ELTAI Computer Technology SIG",
  },
  {
    id: 3,
    image: sigEltaiimage,
    header: "Business English SIG",
    context:
      "The Business English SIG specialises in teaching English for business and professional communication. It provides resources and training on business communication skills, technical writing, and industry-specific language needs. Members benefit from workshops and seminars that focus on effective strategies for teaching business English, enabling them to meet the demands of learners in professional contexts. Membership to this SIG freely available to all short-term and long-term ELTAI members.",
    button: "Visit ELTAI Business English SIG",
  },
];
//unorderlist
export const unOrderList = [
  {
    id: 1,
    header: "Targeted Professional Development",
    context:
      " Access specialised training sessions, workshops, and seminars that address specific aspects of learning, teaching and researching English language and literature.",
  },
  {
    id: 2,
    header: "Networking",
    context:
      "Connect with educators and researchers who share similar interests and challenges,fostering a supportive professional community.",
  },

  {
    id: 3,
    header: "Resource Sharing",
    context:
      " Gain access to a wealth of specialised resources, including teaching materials,research articles, and best practices",
  },
  {
    id: 4,
    header: "Collaborative Projects",
    context:
      "  Participate in collaborative research and development projects, contributing to advancements in the field.",
  },

  {
    id: 5,
    header: "Advocacy and Influence",
    context:
      "  Advocate for specific needs and issues within the broader ELTAI community and influence policy and practice in specialised areas of education. Activities and Initiatives",
  },
];

//orderlist
export const OrderList = [
  {
    id: 1,
    header: "Workshops and Webinars",
    context:
      "Regular events where members learn about the latest trends, tools, and techniques in their area of interest",
  },
  {
    id: 2,
    header: "Publications",
    context:
      "Journals, newsletters, and online resources tailored to each SIG’s focus, offering insights and research findings.",
  },

  {
    id: 3,
    header: "Discussion Forums",
    context:
      "  Online platforms for engaging in discussions, asking questions, and sharing experiences related to their specialised field.",
  },
  {
    id: 4,
    header: "Conferences and Symposia",
    context:
      "  Opportunities to present papers, attend sessions, and network with other professionals at national and regional conferences",
  },
];

//Our partner
export const TeacherAssocation = [
  {
    id: 1,
    img: Ourpartnerimage1,
    companyName: "IATEFL ",
    country: "(The United Kingdom) ",
  },
  {
    id: 2,
    img: Ourpartnerimage2,
    companyName: "TESOL  ",
    country: "(The USA) ",
  },
  {
    id: 3,
    img: Ourpartnerimage3,
    companyName: "NELTA  ",
    country: "(The Nepal) ",
  },
  {
    id: 4,
    img: Ourpartnerimage4,
    companyName: "BELTA  ",
    country: "(The Bangadesh) ",
  },
  {
    id: 5,
    img: Ourpartnerimage5,
    companyName: "TEFLIN  ",
    country: "(The Indonesia) ",
  },
  {
    id: 6,
    img: Ourpartnerimage6,
    companyName: "MELTA  ",
    country: "(The Malaysia) ",
  },
  {
    id: 7,
    img: Ourpartnerimage7,
    companyName: "PALT  ",
    country: "((Philippines)  ",
  },
  {
    id: 8,
    img: Ourpartnerimage8,
    companyName: "GENTEFL  ",
    country: "(Thailand) ",
  },
  {
    id: 9,
    img: Ourpartnerimage9,
    companyName: "CamTESOL  ",
    country: "(Cambodia) ",
  },
  {
    id: 10,
    img: Ourpartnerimage10,
    companyName: "KoreaTESOL  ",
    country: "(Korea) ",
  },
  {
    id: 11,
    img: Ourpartnerimage11,
    companyName: "HALL  ",
    country: "(Hong Kong) ",
  },
  {
    id: 12,
    img: Ourpartnerimage12,
    companyName: "BCTEAL   ",
    country: "(Canada) ",
  },
];
export const Corporates = [
  {
    id: 1,
    img: Ourpartnerimage13,
    companyName: "Cambridge University  ",
    country: "(Press) ",
  },
];
export const educationInsititue = [
  {
    id: 14,
    img: Ourpartnerimage14,
    companyName: "Assem Royal Global University Guwahati ",
    country: "Assem ",
  },
];
// Our Institutional Members
export const AndharPradeshInstitute = [
  {
    id: 1,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 2,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
  {
    id: 3,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 4,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
  {
    id: 5,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 6,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
  {
    id: 7,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 8,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
  {
    id: 9,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 10,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
  {
    id: 11,
    img: InstitutionalImage1,
    companyName: "Holly Cross College",
    country: "(Autonomus Trichy) ",
  },
  {
    id: 12,
    img: InstitutionalImage2,
    companyName: "Utranchal University Deharadun",
    country: "",
  },
];

//annual-conference
// eltaikeyconfrence
export const keyFeaturesofEltai = [
  {
    id: 1,
    header: "Diverse Themes and Topics",
    content:
      "Each conference is centered around a theme that reflects current trends and challenges in English language teaching. Topics span a wide range, including pedagogy, technology integration, assessment, curriculum development, and more.",
  },
  {
    id: 2,
    header: "Renowned Speakers and Experts",
    content:
      "The conferences feature keynote addresses and plenary sessions by distinguished speakers and experts from around the world. These sessions provide valuable insights and perspectives on the latest developments in the field",
  },
  {
    id: 3,
    header: "Interactive Workshops and Seminars",
    content:
      "Attendees can participate in hands-on workshops and interactive seminars that offer practical strategies and tools for enhancing their teaching practices. These sessions are led by experienced educators and trainers.",
  },
  {
    id: 4,
    header: "Paper Presentations and Panels",
    content:
      "Participants have the opportunity to present their research findings and innovative projects through paper presentations and panel discussions. These sessions facilitate knowledge sharing and provide a platform for academic discourse.",
  },
  {
    id: 5,
    header: "Networking Opportunities",
    content:
      "Each conference is centered around a theme that reflects current trends and challenges in English language teaching. Topics span a wide range, including pedagogy, technology integration, assessment, curriculum development, and more.",
  },
  {
    id: 6,
    header: "Exhibition and Resources",
    content:
      "The conferences often include an exhibition area where publishers,educational technology providers, and other organizations showcase their products and services. This provides attendees with access to the latest resources and tools for their classrooms. Benefits of Attending ELTAI Annual Conferences",
  },
  {
    id: 7,
    header: "Exhibition and Resources",
    content:
      "The conferences often include an exhibition area where publishers,educational technology providers, and other organizations showcase their products and services. This provides attendees with access to the latest resources and tools for their classrooms. Benefits of Attending ELTAI Annual Conferences",
  },
];
export const AttendingEltaiAnnualConferences = [
  {
    id: 1,
    header: "Professional Growth",
    content:
      "Gain insights from leading experts and stay updated on the latest trends and research in English language teaching.",
  },
  {
    id: 2,
    header: "Skill Enhancement",
    content:
      "Participate in practical workshops and seminars to acquire new teaching strategies and methodologies",
  },
  {
    id: 3,
    header: "Networking",
    content:
      "Connect with peers, share experiences, and build professional relationships that can lead to future collaboration",
  },
  {
    id: 4,
    header: "Recognition",
    content:
      "Present your research and innovative practices, and receive recognition from the academic community",
  },
  {
    id: 5,
    header: "Resource Access",
    content:
      "Explore new educational tools, materials, and technologies that can enhance your teaching practice.",
  },
];
export const AnnualConferenceTable = [
  {
    id: 1,
    name: "",
    venue: "Dehradum",
    date: "2023",
    media: "Report,Gallery",
  },
  {
    id: 2,
    name: "",
    venue: "Mumbai",
    date: "2022",
    media: "Report,Gallery",
  },
  {
    id: 3,
    name: "",
    venue: "Online",
    date: "2021",
    media: "Report,Gallery",
  },
  {
    id: 4,
    name: "14th international & Golden Jubilee annualconference on Right to English",
    venue: "Amity University,Noida",
    date: "2020",
    media: "Report,Gallery",
  },
  {
    id: 5,
    name: "13th international & 49th annual conference on NextGen Learners: New Demands, New Responses",
    venue: "Career Point World School, Bilaspur, Chhattisgarh",
    date: "9 to 12 October 2019",
    media: "Report,Gallery",
  },
  {
    id: 6,
    name: "12th International & 48th Annual Conference on English Language Acquisition: Western Theories and Eastern Practices",
    venue: "St. Teresa’s College (Autonomous), Ernakulam, Kerala",
    date: "29 June to 1 July 2018",
    media: "Report,Gallery",
  },
  {
    id: 7,
    name: "11th International & 47th Annual Conference on Content-based Instruction and Learning: Redefining the English Curriculum",
    venue: "Vasavi College of Engineering, Hyderabad",
    date: "30 June to 2 July 2016 ",
    media: "Report,Gallery",
  },
  {
    id: 8,
    name: "10th International & 46th Annual Conference on Learning and Teaching English in India: Setting Standards",
    venue: "Raj Kumar Goel Institute of Technology for Women, Ghaziabad",
    date: "09 to 11 July 2015 ",
    media: "",
  },
  {
    id: 9,
    name: "9th International and 45th Annual Conference ",
    venue: "Vivekananda Global University, Jaipur",
    date: "21 to 23 August 2014 ",
    media: "",
  },
  {
    id: 10,
    name: "8th International & 44th Annual Conference ",
    venue: "SRM University, Chennai",
    date: "18 and 19 July 2013 ",
    media: "",
  },
  {
    id: 11,
    name: "10th International & 46th Annual Conference on Learning and Teaching English in India: Setting Standards",
    venue: "Raj Kumar Goel Institute of Technology for Women, Ghaziabad",
    date: "09 to 11 July 2015 ",
    media: "",
  },
  {
    id: 9,
    name: "9th International and 45th Annual Conference",
    venue: "Vivekananda Global University, Jaipur ",
    date: "21 to 23 August 2014",
    media: "",
  },
  {
    id: 10,
    name: "8th International & 44th Annual Conference",
    venue: "SRM University, Chennai",
    date: "18 and 19 July 2013",
    media: "",
  },
  {
    id: 11,
    name: "7th International & 43rd Annual Conference",
    venue: "Velammal Engineering College, Chennai",
    date: "19 to 21 July 2012",
    media: "",
  },
  {
    id: 12,
    name: "6th International & 42nd Annual Conference",
    venue: "VIT University, Vellore",
    date: "16 to 18 June 2011",
    media: "",
  },
  {
    id: 13,
    name: "5th International & 41st Annual Conference",
    venue: "Anna Adarsh College for Women, Chennai",
    date: "05 to 07 August 2010",
    media: "",
  },
  {
    id: 14,
    name: "4th International & 40th Annual Conference",
    venue: "Justice Basheer Ahmed Sayeed College for Women, Chennai",
    date: "06 to 08 August 2009",
    media: "",
  },
  {
    id: 15,
    name: "3rd International & 39th Annual Conference",
    venue: "Sathyabama University, Chennai",
    date: "08 to 10 February 2008",
    media: "",
  },
  {
    id: 16,
    name: "2nd International & 38th Annual Conference",
    venue: "B. S. A. Crescent Engineering College, Chennai, India",
    date: "09 to 10 February 2007",
    media: "",
  },
  {
    id: 17,
    name: "37th National Level Annual Conference",
    venue: "Ethiraj College for Women, Chennai, India",
    date: "0 to 11 February 2006",
    media: "",
  },
  {
    id: 18,
    name: "3rd International & 39th Annual Conference",
    venue: "Sathyabama University, Chennai  ",
    date: "08 to 10 February 2008",
    media: "",
  },
  {
    id: 19,
    name: "35th National Level Annual conference",
    venue: "Anna University, Chennai, India",
    date: "06 to 07 February 2004",
    media: "",
  },
  {
    id: 20,
    name: "34th Annual Conference",
    venue: "Women’s Christian College, Chennai",
    date: "31 January to 01 February 2003",
    media: "",
  },
];
//india state
export const indianStatesAndUTs = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Ladakh",
  "Jammu & Kashmir",
];

//Qualifications
export const Qualifications = [
  { id: 1, label: "Undergraduate Course" },
  { id: 2, label: "Postgraduate Course" },
  { id: 3, label: "M.Phil. (Full Time)" },
  { id: 4, label: "Ph.D. (Full Time)" },
  { id: 98, label: "Other" },
];

//New Qualifications
export const Qualifications1 = [
  { id: 1, label: "B.A." },
  { id: 2, label: "M.A." },
  { id: 3, label: "B.Ed.," },
  { id: 4, label: "M.Ed.," },
  { id: 5, label: "M.Phil. (Full time)" },
  { id: 6, label: "Ph.D. (Full time)" },
  { id: 98, label: "Other" },
];

//Qualifications
export const OTHER_QUALIFICATIONS = 98;

export const membershipImage = [
  {
    id: 1,
    img: DashBoadimage3,
    caption:
      "Open to all individuals who are pre-service, in-service, or retired teachers of English language and literature at schools, colleges, or universities",
    role: "For 1 Teacher",
    Title: "Individual Teacher Membership",
    externalLink: "/add-edit-teacher",
  },
  {
    id: 5,
    img: DashBoadimage5,
    caption:
      "Open to married teaching couples residing at the same address who wish to join ELTAI under a joint plan. Each member enjoys individual benefits, while a single copy of the journal is provided to reduce costs",
    role: "For 2 Teachers",
    Title: "Dual Teacher Membership",
    externalLink: "/add-dual-teacher",
  },
  {
    id: 2,
    img: DashBoadimage4,
    caption:
      "Open to undergraduate and postgraduate students, as well as full-time Ph.D. research scholars in English language, literature, and related disciplines. ",
    role: "For 1 Student",
    Title: "Individual Student Membership",
    externalLink: "/Add-Edit-Student-Member",
  },
  {
    id: 3,
    img: DashBoadimage2,
    caption:
      "Open to educational institutions, including schools, colleges, and universities",
    role: "For 1 Institution",
    Title: "Institutional Membership",
    externalLink: "/add-edit-institution",
  },
  {
    id: 4,
    img: DashBoadimage1,
    caption:
      "Open to companies, particularly those in publishing, educational technology, and professional development, as well as other organisations seeking to support ELTAI’s mission while enhancing their visibility within the teaching community",
    role: "For 1 Organisation",
    Title: "Corporate Supporter Membership",
    externalLink: "/add-Edit-corporate",
  },
];

export const TypeOfInstitution = [
  {
    id: 1,
    type: "Government",
  },
  {
    id: 2,
    type: "Government-aided",
  },
  {
    id: 3,
    type: "Private",
  },
];

export const memshipPayment = {
  Individuals: [
    {
      id: 1,
      type: "Annual",
      amount: 400,
    },
    {
      id: 2,
      type: "Short term (3 Years)",
      amount: 1000,
    },
    {
      id: 3,
      type: "Long term (10 Years)",
      amount: 3000,
    },
  ],
  "Educational institutions": [
    {
      id: 1,
      type: "Short term (3 Years)",
      amount: 1200,
    },
    {
      id: 2,
      type: "Long term (10 Years)",
      amount: 4000,
    },
  ],

  "Foreign Membership": [
    {
      id: 1,
      type: "Annual",
      amount: "$30",
    },
    {
      id: 2,
      type: "Short term (3 Years)",
      amount: "$75",
    },
    {
      id: 1,
      type: "Long term (10 Years)",
      amount: "$150",
    },
  ],
};
export const industries = [
  { id: 1, name: "Education" },
  { id: 2, name: "Publishing" },
  { id: 3, name: "Technology" },
  { id: 4, name: "Healthcare" },
  { id: 5, name: "Finance" },
  { id: 6, name: "Manufacturing" },
  { id: 7, name: "Retail" },
  { id: 8, name: "Media and Entertainment" },
  { id: 9, name: "Professional Services" },
  { id: 10, name: "Non-Profit and NGOs" },
  { id: 11, name: "Government and Public Sector" },
  { id: 12, name: "Transportation and Logistics" },
  { id: 13, name: "Energy and Utilities" },
  { id: 14, name: "Construction and Real Estate" },
  { id: 15, name: "Agriculture and Food Production" },
  { id: 16, name: "Tourism and Hospitality" },
  { id: 17, name: "Automotive" },
  { id: 18, name: "Telecommunications" },
];

export const currently_teaching = [
  { id: 1, label: "Yes" },
  { id: 2, label: "No. I am looking for an opportunity" },
  { id: 3, label: "No. I am retired" },
];

//Contact Information Visibility
export const ContactInformationVisibility = [
  {
    id: 1,
    name: "Address",
  },
  {
    id: 2,
    name: "Mobile Number",
  },
  {
    id: 3,
    name: "WhatsApp Number",
  },
  {
    id: 4,
    name: "Email Address",
  },
  {
    id: 5,
    name: "Telephone Number",
  },
];

//Teachers Eligibility Examinations
export const EligibilityExaminations = [
  {
    id: 1,
    name: "SET",
  },
  {
    id: 2,
    name: "NET",
  },
  {
    id: 3,
    name: "JRF",
  },
  {
    id: 4,
    name: "TET",
  },
  {
    id: 5,
    name: "CTET",
  },
  {
    id: 6,
    name: "Other",
  },
];

//ProfessionalDevelopment
export const ProfessionalDevelopment = [
  { id: 1, name: "Attending webinars" },
  { id: 2, name: "Attending workshops" },
  { id: 3, name: "Listening to lectures" },
  { id: 4, name: "Presenting papers at conferences" },
  { id: 5, name: "Writing articles for journals" },
  { id: 6, name: "Taking part in online discussions" },
  { id: 7, name: "Joining professional associations of teachers" },
  { id: 8, name: "Finding a mentor for your professional development" },
  { id: 9, name: "Enrolling in advanced degree programs" },
  { id: 10, name: "Learning and utilising new digital tools" },
  { id: 11, name: "Reading current literature and research in your field" },
  { id: 12, name: "Participating in student exchange programmes" },
  { id: 13, name: "Developing and sharing learning materials and resources" },
  { id: 14, name: "Engaging in professional learning communities" },
  { id: 15, name: "Other" },
];

//LiteraryStudies
export const LiteraryStudies = [
  { id: 1, name: "Adaptation Studies" },
  { id: 2, name: "American Literature" },
  { id: 3, name: "British Literature" },
  { id: 4, name: "Children's and Young Adult Literature" },
  { id: 5, name: "Comparative Literature" },
  { id: 6, name: "Contemporary Literature" },
  { id: 7, name: "Drama and Theatre Studies" },
  { id: 8, name: "Dystopian and Utopian Fiction" },
  { id: 9, name: "Ecocriticism" },
  { id: 10, name: "Gender and Literature" },
  { id: 11, name: "Gothic Literature" },
  { id: 12, name: "Graphic Novels and Comics Studies" },
  { id: 13, name: "Literary Nonfiction" },
  { id: 14, name: "Literary Theory and Criticism" },
  { id: 15, name: "Medieval and Renaissance Literature" },
  { id: 16, name: "Modernist Literature" },
  { id: 17, name: "Mythology and Folklore" },
  { id: 18, name: "Poetry" },
  { id: 19, name: "Popular Fiction and Genre Studies" },
  { id: 20, name: "Postcolonial Literature" },
  { id: 21, name: "Postmodern Literature" },
  { id: 22, name: "Prose and Fiction Studies" },
  { id: 23, name: "Translation of Literary Texts" },
  { id: 24, name: "World Literature" },
  { id: 25, name: "Other" },
];

//Linguistics
export const Linguistics = [
  { id: 1, name: "Applied Linguistics" },
  { id: 2, name: "Biolinguistics" },
  { id: 3, name: "Cognitive Linguistics" },
  { id: 4, name: "Computational Linguistics" },
  { id: 5, name: "Corpus Linguistics" },
  { id: 6, name: "Discourse Analysis" },
  { id: 7, name: "Educational Linguistics" },
  { id: 8, name: "Forensic Linguistics" },
  { id: 9, name: "Historical Linguistics" },
  { id: 10, name: "Language Acquisition" },
  { id: 11, name: "Language and Gender" },
  { id: 12, name: "Language and Globalization" },
  { id: 13, name: "Language and Identity" },
  { id: 14, name: "Language and Migration" },
  { id: 15, name: "Language Contact and Creolization" },
  { id: 16, name: "Language Documentation and Preservation" },
  { id: 17, name: "Language Ideologies" },
  { id: 18, name: "Language Policy and Planning" },
  { id: 19, name: "Morphology" },
  { id: 20, name: "Multimodal Communication" },
  { id: 21, name: "Neurolinguistics" },
  { id: 22, name: "Phonetics and Phonology" },
  { id: 23, name: "Pragmatics" },
  { id: 24, name: "Psycholinguistics" },
  { id: 25, name: "Semantics" },
  { id: 26, name: "Sociolinguistics" },
  { id: 27, name: "Syntax" },
  { id: 28, name: "Other" },
];

//EnglishLanguageTeaching
export const EnglishLanguageTeaching = [
  { id: 1, name: "Bilingual and Multilingual Education" },
  { id: 2, name: "Classroom Management" },
  { id: 3, name: "Community-Based Language Learning" },
  { id: 4, name: "Content and Language Integrated Learning (CLIL)" },
  { id: 5, name: "Curriculum and Syllabus Design" },
  { id: 6, name: "Differentiated Instruction" },
  { id: 7, name: "English for Academic Purposes (EAP)" },
  { id: 8, name: "English for Specific Purposes (ESP)" },
  { id: 9, name: "Error Correction and Feedback Strategies" },
  { id: 10, name: "Inclusive Education in ELT" },
  { id: 11, name: "Language Assessment and Testing" },
  { id: 12, name: "Listening and Speaking Skills Development" },
  { id: 13, name: "Literacy and Reading Strategies" },
  { id: 14, name: "Online and Distance Education" },
  { id: 15, name: "Peer Teaching and Collaborative Learning" },
  { id: 16, name: "Pronunciation Teaching" },
  { id: 17, name: "Second Language Acquisition (SLA)" },
  { id: 18, name: "Task-Based Language Teaching" },
  { id: 19, name: "Teacher Training and Professional Development" },
  { id: 20, name: "Teaching English as a Foreign Language (TEFL)" },
  { id: 21, name: "Teaching English as a Second Language (TESL)" },
  { id: 22, name: "Technology in ELT" },
  { id: 23, name: "Vocabulary Acquisition and Instruction" },
  { id: 24, name: "Writing Pedagogy" },
  { id: 25, name: "World/Global Englishes" },
  { id: 26, name: "Other" },
];

//InterdisciplinaryAreas
export const InterdisciplinaryAreas = [
  { id: 1, name: "Animal Studies" },
  { id: 2, name: "Creative Writing" },
  { id: 3, name: "Critical Pedagogy" },
  { id: 4, name: "Cultural Studies" },
  { id: 5, name: "Digital Humanities" },
  { id: 6, name: "Educational Leadership and Administration" },
  { id: 7, name: "Educational Technology" },
  { id: 8, name: "Environmental Humanities" },
  { id: 9, name: "Ethics in Education" },
  { id: 10, name: "Film and Literature" },
  { id: 11, name: "Health Humanities" },
  { id: 12, name: "Intercultural Communication" },
  { id: 13, name: "Linguistic Anthropology" },
  { id: 14, name: "Media and Communication Studies" },
  { id: 15, name: "Memory Studies" },
  { id: 16, name: "Music and Language Learning" },
  { id: 17, name: "Philosophy of Language" },
  { id: 18, name: "Rhetoric and Composition" },
  { id: 19, name: "Social Justice in Education" },
  { id: 20, name: "Speech and Language Pathology" },
  { id: 21, name: "Translation Studies" },
  { id: 22, name: "Trauma Studies" },
  { id: 23, name: "Visual and Performing Arts in Education" },
  { id: 24, name: "Visual Literacy" },
  { id: 25, name: "Other" },
];

export const Salutation = [
  {
    id: 0,
    Name: "Mr",
  },
  {
    id: 1,
    Name: "Ms",
  },
  {
    id: 2,
    Name: "Mx",
  },
  {
    id: 3,
    Name: "Dr",
  },
  {
    id: 4,
    Name: "Prof",
  },
];

export const typeOfOrganisation = [
  {
    id: 1,
    type: "Government",
  },
  {
    id: 2,
    type: "Private",
  },
  {
    id: 3,
    type: " Non-Profit",
  },
];

//Teachers Qualification
export const TeachersQualification = [
  { id: 1, name: "B.A." },
  { id: 2, name: "M.A." },
  { id: 3, name: "B.Ed.," },
  { id: 4, name: "M.Ed.," },
  { id: 5, name: "M.Phil." },
  { id: 6, name: "Ph.D." },
  { id: 7, name: "Post-Doc" },
  { id: 8, name: "CELTA" },
  { id: 9, name: "DELTA" },
  { id: 10, name: "Dip.TESOL" },
  { id: 11, name: "Other" },
];

//Teachers Experience
export const TeachersExperience = [
  {
    id: 1,
    name: "0 - 2",
  },
  {
    id: 2,
    name: "3 - 5",
  },
  {
    id: 3,
    name: "6 - 10",
  },
  {
    id: 4,
    name: "11 - 15",
  },
  {
    id: 5,
    name: "16 - 20",
  },
  {
    id: 6,
    name: "21 - 25",
  },
  {
    id: 7,
    name: "Above 25",
  },
];

//Currently Teaching
export const CurrentlyTeaching = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 2,
    name: "No. I am looking for an opportunity",
  },
  {
    id: 3,
    name: "No. I am retired",
  },
];

//Teachers Primarily Teaching
export const PrimarilyTeachingForInstitution = [
  { id: 1, label: "Pre-Primary/Nursery School" },
  { id: 2, label: "Primary School" },
  { id: 3, label: "Middle School" },
  { id: 4, label: "Secondary School" },
  { id: 5, label: "Higher Secondary School" },
  { id: 6, label: "Pre-Degree / Junior College" },
  { id: 7, label: "Polytechnic Institution" },
  { id: 8, label: "Arts/Science/Commerce College" },
  { id: 9, label: "Professional College (Engineering)" },
  { id: 10, label: "Professional College (Medicine)" },
  { id: 11, label: "Professional College (MBA)" },
  { id: 12, label: "Professional College (Law)" },
  { id: 13, label: "Teacher Training Institute" },
  { id: 14, label: "Teacher Education College" },
  { id: 15, label: "Deemed-to-be-University" },
  { id: 16, label: "University" },
  { id: 99, label: "Other" },
];
//Teachers Primarily Teaching
export const PrimarilyTeachingForTeacher = [
  { id: 1, label: "Primary School" },
  { id: 2, label: "Middle School" },
  { id: 3, label: "Secondary School" },
  { id: 4, label: "Higher Secondary School" },
  { id: 5, label: "Pre-Degree College" },
  { id: 6, label: "Polytechnic Institution" },
  { id: 7, label: "Arts/Science/Commerce College (UG)" },
  { id: 8, label: "Arts/Science/Commerce College (PG)" },
  { id: 9, label: "Professional College (Engineering)" },
  { id: 10, label: "Professional College (Medicine)" },
  { id: 11, label: "Professional College (MBA)" },
  { id: 12, label: "Professional College (Law)" },
  { id: 13, label: "Teacher Training Institute" },
  { id: 14, label: "Teacher Education College" },
  { id: 15, label: "University" },
  { id: 99, label: "Other" },
];
//Teachers Primarily Teaching Other
export const OTHER_PRIMARILY_TEACHING = 99;

//Nature of work
export const NatureOfWork = [
  {
    id: 1,
    name: "Government",
  },
  {
    id: 2,
    name: "Government-aided",
  },
  {
    id: 3,
    name: "Private",
  },
];

//Professional Engagement
export const ProfessionalEngagement = [
  { id: 1, label: "Delivering Talks or Workshops" },
  { id: 2, label: "Writing Collaborative Research Papers" },
  { id: 3, label: "Participating in Webinars or Panels" },
  { id: 4, label: "Mentoring Other Teachers &amp; Researchers" },
  { id: 5, label: "Collaborating on Curriculum Development" },
  { id: 6, label: "Sharing Teaching Materials and Resources" },
];
export const Modules = [
  { id: 0, name: "Our Update" },
  { id: 1, name: "Forum" },
  { id: 2, name: "Event" },
  { id: 3, name: "Gallery" },
  { id: 4, name: "Announcement" },
];

export const membershipDetails = [
  {
    heading: "Add New Member",
    content: "Register a new ELTAI member by entering their details manually",
    url: "/admin/eventForm",
  },
  {
    heading: "Renew Existing Membership",
    content: "Extend an existing membership for continued benefits and access",
    url: "",
  },
  {
    heading: "Approve Pending Memberships",
    content: "Review and approve membership applications awaiting verification",
    url: "allMemberList",
  },
  {
    heading: "Manage Membership",
    content:
      "Edit membership details, temporarily suspend access without deletion, or permanently remove a member’s profile and records from the system",
    url: "/admin/eventList",
  },
  {
    heading: "Generate Membership Cards",
    content:
      "Issue official membership certificates or cards for verified members",
    url: "",
  },
  {
    heading: "Generate Address Labels",
    content: "Produce printable address labels for selected members or groups",
    url: "",
  },
  {
    heading: "Generate Membership Reports",
    content:
      "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
    url: "",
  },
];

export const eventDetails = [
  {
    heading: "Add Event Type",
    content: "Create a new event type",
    url: "/admin/eventForm",
  },
  {
    heading: "Add Event",
    content:
      "Create a new event by entering details such as title, date, location, and description",
    url: "/admin/eventForm",
  },
  {
    heading: "Manage Events",
    content:
      "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
    url: "",
  },
  {
    heading: "Approve Events",
    content:
      "Review and approve events added by Chapters and SIG for website listing",
    url: "/admin/eventList",
  },
  {
    heading: "Manage Completed Events",
    content:
      "Add and update details for finished events, including participant data, feedback, and associated media",
    url: "",
  },
  {
    heading: "Generate Event Reports",
    content:
      "Create detailed reports of events including participant data, feedback, and associated media",
    url: "",
  },
];

export const chapterDetails = [
  {
    heading: "Add New Chapter",
    content:
      "Create a new ELTAI chapter by entering details such as name, location, and leadership information",
    url: "",
  },
  {
    heading: "Approve Chapter Details",
    content:
      "Review and approve changes in chapter details made by chapter heads",
    url: "",
  },
  {
    heading: "Manage Chapter",
    content:
      "Edit chapter details, temporarily disable its operations, or permanently delete the chapter and its information from the system",
    url: "",
  },
  {
    heading: "View Chapters List",
    content:
      "Display a list of all active chapters, providing quick access to their details and status",
    url: "",
  },
  {
    heading: "Reward Chapters",
    content: "Add badges to chapters in recognition of their achievementss",
    url: "",
  },
  {
    heading: "Generate Chapter Reports",
    content:
      "Create reports on chapter activities, membership statistics, and performance metrics",
    url: "",
  },
  {
    heading: "Monitor Chapter’s Share",
    content:
      "Track the accrued and debited funds in the chapter’s 10% share of membership fees",
    url: "",
  },
];

export const sigDetails = [
  {
    heading: "Add New SIG",
    content: "Create a new Special Interest Group by entering required details",
    url: "",
  },
  {
    heading: "Approve SIG",
    content:
      "Review and approve applications for new Special Interest Groups seeking official recognition within ELTAI",
    url: "",
  },
  {
    heading: "Manage SIG",
    content:
      "Edit SIG details, temporarily disable its activities, or permanently delete the SIG and its information from the system",
    url: "",
  },
  {
    heading: "View SIGs List",
    content:
      "Display a list of all active SIGs for quick access to their details and status",
    url: "",
  },
  {
    heading: "Generate SIG Reports",
    content:
      "Create reports on SIG activities, membership statistics, and engagement metrics",
    url: "",
  },
];

export const partnerDetails = [
  {
    heading: "Add New Partner",
    content:
      "Create a new partnership by entering details such as partner name, contact information, and collaboration focus",
    url: "",
  },
  {
    heading: "Manage Partners",
    content:
      "Edit partner details, temporarily disable their status, or permanently remove them and their information from the system",
    url: "",
  },
  {
    heading: "View Partners List",
    content:
      "Display a comprehensive list of all active partners for quick access to their details and status",
    url: "",
  },
  {
    heading: "Generate Partner Reports",
    content:
      "Create reports on partnership activities, contributions, and performance metrics",
    url: "",
  },
];

export const resourceDetails = [
  {
    heading: "Add Resource",
    content:
      "Upload new learning resources, materials, and references to the resource library for members",
    url: "",
  },
  {
    heading: "Manage Resources",
    content:
      "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
    url: "",
  },
  {
    heading: "Generate Reports",
    content:
      "Create detailed reports on uploaded resources, including usage statistics and categorisation",
    url: "",
  },
];

export const communicationDetails = [
  {
    heading: "Post/Schedule Announcements",
    content:
      "Create and publish important updates or notices for the community",
    url: "",
  },
  {
    heading: "Manage Announcements",
    content: "Edit, delete, or temporarily hide existing announcements",
    url: "",
  },
  {
    heading: "Approve Announcements",
    content: "Review and approve announcements before they are published",
    url: "",
  },
  {
    heading: "Post/Schedule News",
    content: "Share news articles or updates relevant to ELTAI and its members",
    url: "",
  },
  {
    heading: "Manage News",
    content: "Edit, delete, or temporarily hide previously posted news",
    url: "",
  },
  {
    heading: "Approve News",
    content: "Review and approve news submissions for publication",
    url: "",
  },
  {
    heading: "Send/Schedule Emails",
    content:
      "Draft and send targeted email communications to members or groups",
    url: "",
  },
  {
    heading: "Approve Emails",
    content:
      "Review and approve email drafts before they are sent to recipients",
    url: "",
  },
  {
    heading: "Manage Header Banner",
    content:
      "Add, edit, disable, or delete header banners on the homepage of the website",
    url: "",
  },
  {
    heading: "Manage Subscribers",
    content:
      "Oversee subscriber lists for newsletters or specific communication channels",
    url: "",
  },
  {
    heading: "Manage Discussion Forums",
    content:
      "Start, edit, delete, or temporarily disable discussion threads for member engagement",
    url: "",
  },
  {
    heading: "Manage Spotlights",
    content:
      "Add, edit, delete, or temporarily disable spotlight images on the homepage",
    url: "",
  },
  {
    heading: "Generate Communication Reports",
    content:
      "Create reports on communication activities, including announcements, news, emails, and forum engagement",
    url: "",
  },
];

export const galleryDetails = [
  {
    heading: "Create Album",
    content: "Organise photos by creating a new album in the gallery",
    url: "/admin/gallery-table",
  },
  {
    heading: "Upload Photo",
    content: "Add individual images to the gallery or specific albums",
    url: "",
  },
  {
    heading: "Bulk Upload Photos",
    content: "Upload multiple images at once for streamlined gallery updates",
    url: "",
  },
  {
    heading: "Approve Photos",
    content:
      "Review and approve photos submitted by chapters, SIGs, or other groups before they are published",
    url: "",
  },
  {
    heading: "Manage Gallery",
    content:
      "Edit album details, delete photos, or temporarily disable albums from public view",
    url: "",
  },
  {
    heading: "Generate Gallery Reports",
    content:
      "Create reports on uploaded images, albums, and gallery engagement",
    url: "",
  },
];

// Assistant Admin Management Details

export const assistantAdminEventDetails = [
  {
    heading: "Add Event Type",
    content: "Create a new event type",
    url: "/admin/eventForm",
  },
  {
    heading: "Add Event",
    content:
      "Create a new event by entering details such as title, date, location, and description",
    url: "/admin/eventForm",
  },
  {
    heading: "Manage Events",
    content:
      "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
    url: "",
  },
  {
    heading: "Approve Events",
    content:
      "Review and approve events added by Chapters and SIG for website listing",
    url: "/admin/eventList",
  },
  {
    heading: "Manage Completed Events",
    content:
      "Add and update details for finished events, including participant data, feedback, and associated media",
    url: "",
  },
  {
    heading: "Generate Event Reports",
    content:
      "Create detailed reports of events including participant data, feedback, and associated media",
    url: "",
  },
];

export const assistantAdminChapterDetails = [
  {
    heading: "Add New Chapter",
    content:
      "Create a new ELTAI chapter by entering details such as name, location, and leadership information",
    url: "",
  },
  {
    heading: "Approve Chapter Details",
    content:
      "Review and approve changes in chapter details made by chapter heads",
    url: "",
  },
  {
    heading: "Manage Chapter",
    content:
      "Edit chapter details, temporarily disable its operations, or permanently delete the chapter and its information from the system",
    url: "",
  },
  {
    heading: "View Chapters List",
    content:
      "Display a list of all active chapters, providing quick access to their details and status",
    url: "",
  },
  {
    heading: "Generate Chapter Reports",
    content:
      "Create reports on chapter activities, membership statistics, and performance metrics",
    url: "",
  },
];

export const assistantAdminSigDetails = [
  {
    heading: "Add New SIG",
    content: "Create a new Special Interest Group by entering required details",
    url: "",
  },
  {
    heading: "Approve SIG",
    content:
      "Review and approve applications for new Special Interest Groups seeking official recognition within ELTAI",
    url: "",
  },
  {
    heading: "Manage SIG",
    content:
      "Edit SIG details, temporarily disable its activities, or permanently delete the SIG and its information from the system",
    url: "",
  },
  {
    heading: "View SIGs List",
    content:
      "Display a list of all active SIGs for quick access to their details and status",
    url: "",
  },
  {
    heading: "Generate SIG Reports",
    content:
      "Create reports on SIG activities, membership statistics, and engagement metrics",
    url: "",
  },
];

export const assistantAdminPartnerDetails = [
  {
    heading: "Add New Partner",
    content:
      "Create a new partnership by entering details such as partner name, contact information, and collaboration focus",
    url: "",
  },
  {
    heading: "Manage Partners",
    content:
      "Edit partner details, temporarily disable their status, or permanently remove them and their information from the system",
    url: "",
  },
  {
    heading: "View Partners List",
    content:
      "Display a comprehensive list of all active partners for quick access to their details and status",
    url: "",
  },
  {
    heading: "Generate Partner Reports",
    content:
      "Create reports on partnership activities, contributions, and performance metrics",
    url: "",
  },
];

export const assistantAdminResourceDetails = [
  {
    heading: "Add Resource",
    content:
      "Upload new learning resources, materials, and references to the resource library for members",
    url: "",
  },
  {
    heading: "Manage Resources",
    content:
      "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
    url: "",
  },
  {
    heading: "Generate Reports",
    content:
      "Create detailed reports on uploaded resources, including usage statistics and categorisation",
    url: "",
  },
];

export const assistantAdminCommunicationDetails = [
  {
    heading: "Post/Schedule Announcements",
    content:
      "Create and publish important updates or notices for the community",
    url: "",
  },
  {
    heading: "Manage Announcements",
    content: "Edit, delete, or temporarily hide existing announcements",
    url: "",
  },
  {
    heading: "Approve Announcements",
    content: "Review and approve announcements before they are published",
    url: "",
  },
  {
    heading: "Post/Schedule News",
    content: "Share news articles or updates relevant to ELTAI and its members",
    url: "",
  },
  {
    heading: "Manage News",
    content: "Edit, delete, or temporarily hide previously posted news",
    url: "",
  },
  {
    heading: "Approve News",
    content: "Review and approve news submissions for publication",
    url: "",
  },
  {
    heading: "Send/Schedule Emails",
    content:
      "Draft and send targeted email communications to members or groups",
    url: "",
  },
  {
    heading: "Approve Emails",
    content:
      "Review and approve email drafts before they are sent to recipients",
    url: "",
  },
  {
    heading: "Manage Header Banner",
    content:
      "Add, edit, disable, or delete header banners on the homepage of the website",
    url: "",
  },
  {
    heading: "Manage Subscribers",
    content:
      "Oversee subscriber lists for newsletters or specific communication channels",
    url: "",
  },
  {
    heading: "Manage Discussion Forums",
    content:
      "Start, edit, delete, or temporarily disable discussion threads for member engagement",
    url: "",
  },
  {
    heading: "Manage Spotlights",
    content:
      "Add, edit, delete, or temporarily disable spotlight images on the homepage",
    url: "",
  },
  {
    heading: "Generate Communication Reports",
    content:
      "Create reports on communication activities, including announcements, news, emails, and forum engagement",
    url: "",
  },
];

export const assistantAdminGalleryDetails = [
  {
    heading: "Create Album",
    content: "Organise photos by creating a new album in the gallery",
    url: "",
  },
  {
    heading: "Upload Photo",
    content: "Add individual images to the gallery or specific albums",
    url: "",
  },
  {
    heading: "Bulk Upload Photos",
    content: "Upload multiple images at once for streamlined gallery updates",
    url: "",
  },
  {
    heading: "Approve Photos",
    content:
      "Review and approve photos submitted by chapters, SIGs, or other groups before they are published",
    url: "",
  },
  {
    heading: "Manage Gallery",
    content:
      "Edit album details, delete photos, or temporarily disable albums from public view",
    url: "",
  },
  {
    heading: "Generate Gallery Reports",
    content:
      "Create reports on uploaded images, albums, and gallery engagement",
    url: "",
  },
];

// Chapter Admin Management Details

export const chapterAdminMembershipDetails = [
  {
    heading: "Add New Member",
    content: "Register a new ELTAI member by entering their details manually",
    url: "/admin/eventForm",
  },
  {
    heading: "Bulk Upload New Members",
    content: "Add multiple new members at once using a pre-formatted file",
    url: "allMemberList",
  },
  {
    heading: "Generate Membership Reports",
    content:
      "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
    url: "",
  },
];

export const chapterAdminEventDetails = [
  {
    heading: "Add Event",
    content:
      "Create a new event by entering details such as title, date, location, and description",
    url: "/admin/eventForm",
  },
  {
    heading: "Manage Events",
    content:
      "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
    url: "",
  },
  {
    heading: "Manage Completed Events",
    content:
      "Add and update details for finished events, including participant data, feedback, and associated media",
    url: "",
  },
  {
    heading: "Generate Event Reports",
    content:
      "Create detailed reports of events including participant data, feedback, and associated media",
    url: "",
  },
];

export const chapterAdminChapterDetails = [
  {
    heading: "Add/Edit Chapter Details",
    content:
      "Add or modify details of your chapter, such as description and core team",
    url: "/admin/eventForm",
  },
  {
    heading: "Check Chapter’s Share",
    content:
      "Check the accrued and debited funds in the chapter’s 10% share of membership fees",
    url: "",
  },
  {
    heading: "Generate Chapter Reports",
    content:
      "Create reports on chapter activities, membership statistics, and performance metrics",
    url: "",
  },
];

export const chapterAdminResourceDetails = [
  {
    heading: "Add Resource",
    content:
      "Upload new learning resources, materials, and references to the resource library for members",
    url: "",
  },
  {
    heading: "Manage Resources",
    content:
      "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
    url: "",
  },
  {
    heading: "Generate Reports",
    content:
      "Create detailed reports on uploaded resources, including usage statistics and categorisation",
    url: "",
  },
];

export const chapterAdminCommunicationDetails = [
  {
    heading: "Post/Schedule Announcements",
    content:
      "Create and publish important updates or notices for the community",
    url: "",
  },
  {
    heading: "Manage Announcements",
    content: "Edit, delete, or temporarily hide existing announcements",
    url: "",
  },
  {
    heading: "Post/Schedule News",
    content: "Share news articles or updates relevant to ELTAI and its members",
    url: "",
  },
  {
    heading: "Manage News",
    content: "Edit, delete, or temporarily hide previously posted news",
    url: "",
  },
  {
    heading: "Approve News",
    content: "Review and approve news submissions for publication",
    url: "",
  },
  {
    heading: "Send/Schedule Emails",
    content:
      "Draft and send targeted email communications to members or groups",
    url: "",
  },
  {
    heading: "Approve Emails",
    content:
      "Review and approve email drafts before they are sent to recipients",
    url: "",
  },
  {
    heading: "Manage Header Banner",
    content:
      "Add, edit, disable, or delete header banners on the homepage of the website",
    url: "",
  },
  {
    heading: "Manage Subscribers",
    content:
      "Oversee subscriber lists for newsletters or specific communication channels",
    url: "",
  },
  {
    heading: "Manage Discussion Forums",
    content:
      "Start, edit, delete, or temporarily disable discussion threads for member engagement",
    url: "",
  },
  {
    heading: "Manage Spotlights",
    content:
      "Add, edit, delete, or temporarily disable spotlight images on the homepage",
    url: "",
  },
  {
    heading: "Generate Communication Reports",
    content:
      "Create reports on communication activities, including announcements, news, emails, and forum engagement",
    url: "",
  },
];

export const chapterAdminGalleryDetails = [
  {
    heading: "Create Album",
    content: "Organise photos by creating a new album in the gallery",
    url: "",
  },
  {
    heading: "Upload Photo",
    content: "Add individual images to the gallery or specific albums",
    url: "",
  },
  {
    heading: "Bulk Upload Photos",
    content: "Upload multiple images at once for streamlined gallery updates",
    url: "",
  },
  {
    heading: "Manage Gallery",
    content:
      "Edit album details, delete photos, or temporarily disable albums from public view",
    url: "",
  },
  {
    heading: "Generate Gallery Reports",
    content:
      "Create reports on uploaded images, albums, and gallery engagement",
    url: "",
  },
];

// SIG Admin Management Details

export const sigAdminMembershipDetails = [
  {
    heading: "Generate Membership Reports",
    content:
      "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
    url: "",
  },
];

export const sigAdminEventDetails = [
  {
    heading: "Add Event",
    content:
      "Create a new event by entering details such as title, date, location, and description",
    url: "/admin/eventForm",
  },
  {
    heading: "Manage Events",
    content:
      "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
    url: "",
  },
  {
    heading: "Manage Completed Events",
    content:
      "Add and update details for finished events, including participant data, feedback, and associated media",
    url: "",
  },
  {
    heading: "Generate Event Reports",
    content:
      "Create detailed reports of events including participant data, feedback, and associated media",
    url: "",
  },
];

export const sigAdminSigDetails = [
  {
    heading: "Add/Edit SIG Details",
    content:
      "Create reports on SIG activities, membership statistics, and engagement metrics",
    url: "/admin/eventForm",
  },
  {
    heading: "Generate SIGs Reports",
    content:
      "Create reports on SIG activities membership statistics, and engagement metrics",
    url: "",
  },
];

export const sigAdminResourceDetails = [
  {
    heading: "Add Resource",
    content:
      "Upload new learning resources, materials, and references to the resource library for members",
    url: "",
  },
  {
    heading: "Manage Resources",
    content:
      "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
    url: "",
  },
  {
    heading: "Generate Reports",
    content:
      "Create detailed reports on uploaded resources, including usage statistics and categorisation",
    url: "",
  },
];

export const sigAdminCommunicationDetails = [
  {
    heading: "Post/Schedule Announcements",
    content:
      "Create and publish important updates or notices for the community",
    url: "",
  },
  {
    heading: "Manage Announcements",
    content: "Edit, delete, or temporarily hide existing announcements",
    url: "",
  },
  {
    heading: "Post/Schedule News",
    content: "Share news articles or updates relevant to ELTAI and its members",
    url: "",
  },
  {
    heading: "Manage News",
    content: "Edit, delete, or temporarily hide previously posted news",
    url: "",
  },
  {
    heading: "Send/Schedule Emails",
    content:
      "Draft and send targeted email communications to members or groups",
    url: "",
  },
  {
    heading: "Manage Discussion Forums",
    content:
      "Start, edit, delete, or temporarily disable discussion threads for member engagement",
    url: "",
  },
];

export const sigAdminGalleryDetails = [
  {
    heading: "Create Album",
    content: "Organise photos by creating a new album in the gallery",
    url: "",
  },
  {
    heading: "Upload Photo",
    content: "Add individual images to the gallery or specific albums",
    url: "",
  },
  {
    heading: "Bulk Upload Photos",
    content: "Upload multiple images at once for streamlined gallery updates",
    url: "",
  },
  {
    heading: "Manage Gallery",
    content:
      "Edit album details, delete photos, or temporarily disable albums from public view",
    url: "",
  },
  {
    heading: "Generate Gallery Reports",
    content:
      "Create reports on uploaded images, albums, and gallery engagement",
    url: "",
  },
];

// SuperAdmin Mangement
export const SuperAdminManagement = {
  membershipDetails: [
    {
      name: "Membership Management",
      heading: "Add New Member",
      content: "Register a new ELTAI member by entering their details manually",
      url: "",
      type: "A-1",
    },
    {
      heading: "Renew Existing Membership",
      content:
        "Extend an existing membership for continued benefits and access",
      url: "",
      type: "A-2",
    },
    {
      heading: "Approve Pending Memberships",
      content:
        "Review and approve membership applications awaiting verification",
      url: "allMemberList",
      type: "A-3",
    },
    {
      heading: "Manage Membership",
      content:
        "Edit membership details, temporarily suspend access without deletion, or permanently remove a member’s profile and records from the system",
      url: "/admin/eventList",
      type: "A-4",
    },
    {
      heading: "Generate Membership Cards",
      content:
        "Issue official membership certificates or cards for verified members",
      url: "",
      type: "A-5",
    },
    {
      heading: "Generate Address Labels",
      content:
        "Produce printable address labels for selected members or groups",
      url: "",
      type: "A-6",
    },
    {
      heading: "Generate Membership Reports",
      content:
        "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
      url: "",
      type: "A-7",
    },
  ],

  eventDetails: [
    {
      name: "Event Management",
      heading: "Add Event Type",
      content: "Create a new event type",
      url: "/admin/eventType",
      type: "B-1",
    },
    {
      heading: "Add Event",
      content:
        "Create a new event by entering details such as title, date, location, and description",
      url: "/admin/eventForm",
      type: "B-2",
    },
    {
      heading: "Manage Events",
      content:
        "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
      url: "/admin/eventList",
      type: "B-3",
    },
    {
      heading: "Approve Events",
      content:
        "Review and approve events added by Chapters and SIG for website listing",
      url: "/admin/approval",
      type: "B-4",
    },
    {
      heading: "Manage Completed Events",
      content:
        "Add and update details for finished events, including participant data, feedback, and associated media",
      url: "",
      type: "B-5",
    },
    {
      heading: "Generate Event Reports",
      content:
        "Create detailed reports of events including participant data, feedback, and associated media",
      url: "",
      type: "B-6",
    },
  ],

  chapterDetails: [
    {
      name: "Chapter Management",
      heading: "Add New Chapter",
      content:
        "Create a new ELTAI chapter by entering details such as name, location, and leadership information",
      url: "/chapterForm",
      type: "C-1",
    },
    {
      heading: "Approve Chapter Details",
      content:
        "Review and approve changes in chapter details made by chapter heads",
      url: "/admin/view-Chapter",
      type: "C-2",
    },
    {
      heading: "Manage Chapter",
      content:
        "Edit chapter details, temporarily disable its operations, or permanently delete the chapter and its information from the system",
      url: "/admin/view-Chapter",
      type: "C-3",
    },
    {
      heading: "View Chapters List",
      content:
        "Display a list of all active chapters, providing quick access to their details and status",
      url: "/admin/view-Chapter",
      type: "C-4",
    },
    {
      heading: "Reward Chapters",
      content: "Add badges to chapters in recognition of their achievements",
      url: "",
      type: "C-5",
    },
    {
      heading: "Generate Chapter Reports",
      content:
        "Create reports on chapter activities, membership statistics, and performance metrics",
      url: "",
      type: "C-6",
    },
    {
      heading: "Monitor Chapter’s Share",
      content:
        "Track the accrued and debited funds in the chapter’s 10% share of membership fees",
      url: "",
      type: "C-7",
    },
  ],

  sigDetails: [
    {
      name: "SIG Management",
      heading: "Add New SIG",
      content:
        "Create a new Special Interest Group by entering required details",
      url: "/admin/sigGroupForm",
      type: "D-1",
    },
    {
      heading: "Approve SIG",
      content:
        "Review and approve applications for new Special Interest Groups seeking official recognition within ELTAI",
      url: "/admin/addEditSigGroups",
      type: "D-2",
    },
    {
      heading: "Manage SIG",
      content:
        "Edit SIG details, temporarily disable its activities, or permanently delete the SIG and its information from the system",
      url: "/admin/addEditSigGroups",
      type: "D-3",
    },
    {
      heading: "View SIGs List",
      content:
        "Display a list of all active SIGs for quick access to their details and status",
      url: "/admin/addEditSigGroups",
      type: "D-4",
    },
    {
      heading: "Generate SIG Reports",
      content:
        "Create reports on SIG activities, membership statistics, and engagement metrics",
      url: "",
      type: "D-5",
    },
  ],

  partnerDetails: [
    {
      name: "Partner Management",
      heading: "Add New Partner",
      content:
        "Create a new partnership by entering details such as partner name, contact information, and collaboration focus",
      url: "",
      type: "E-1",
    },
    {
      heading: "Manage Partners",
      content:
        "Edit partner details, temporarily disable their status, or permanently remove them and their information from the system",
      url: "",
      type: "E-2",
    },
    {
      heading: "View Partners List",
      content:
        "Display a comprehensive list of all active partners for quick access to their details and status",
      url: "",
      type: "E-3",
    },
    {
      heading: "Generate Partner Reports",
      content:
        "Create reports on partnership activities, contributions, and performance metrics",
      url: "",
      type: "E-4",
    },
  ],

  resourceDetails: [
    {
      name: "Resource Management",
      heading: "Add Resource",
      content:
        "Upload new learning resources, materials, and references to the resource library for members",
      url: "",
      type: "F-1",
    },
    {
      heading: "Manage Resources",
      content:
        "Edit, approve, permanently delete, or temporarily hide existing resources in the library",
      url: "",
      type: "F-2",
    },
    {
      heading: "Generate Reports",
      content:
        "Create detailed reports on uploaded resources, including usage statistics and categorisation",
      url: "",
      type: "F-3",
    },
  ],

  communicationDetails: [
    {
      name: "Communication Management",
      heading: "Post/Schedule Announcements",
      content:
        "Create and publish important updates or notices for the community",
      url: "/admin/addEditImport-Announment",
      type: "G-1",
    },
    {
      heading: "Manage Announcements",
      content: "Edit, delete, or temporarily hide existing announcements",
      url: "/admin/importannoucenment",
      type: "G-2",
    },
    {
      heading: "Approve Announcements",
      content: "Review and approve announcements before they are published",
      url: "",
      type: "G-3",
    },
    {
      heading: "Post/Schedule News",
      content:
        "Share news articles or updates relevant to ELTAI and its members",
      url: "",
      type: "G-4",
    },
    {
      heading: "Manage News",
      content: "Edit, delete, or temporarily hide previously posted news",
      url: "",
      type: "G-5",
    },
    {
      heading: "Approve News",
      content: "Review and approve news submissions for publication",
      url: "",
      type: "G-6",
    },
    {
      heading: "Send/Schedule Emails",
      content:
        "Draft and send targeted email communications to members or groups",
      url: "",
      type: "G-7",
    },
    {
      heading: "Approve Emails",
      content:
        "Review and approve email drafts before they are sent to recipients",
      url: "",
      type: "G-8",
    },
    {
      heading: "Manage Header Banner",
      content:
        "Add, edit, disable, or delete header banners on the homepage of the website",
      url: "",
      type: "G-9",
    },
    {
      heading: "Manage Subscribers",
      content:
        "Oversee subscriber lists for newsletters or specific communication channels",
      url: "",
      type: "G-10",
    },
    {
      heading: "Manage Discussion Forums",
      content:
        "Start, edit, delete, or temporarily disable discussion threads for member engagement",
      url: "/admin/forumTable",
      type: "G-11",
    },
    {
      heading: "Manage Spotlights",
      content:
        "Add, edit, delete, or temporarily disable spotlight images on the homepage",
      url: "",
      type: "G-12",
    },
    {
      heading: "Generate Communication Reports",
      content:
        "Create reports on communication activities, including announcements, news, emails, and forum engagement",
      url: "",
      type: "G-13",
    },
  ],

  galleryDetails: [
    {
      name: "Gallery Management",
      heading: "Create Album",
      content: "Organise photos by creating a new album in the gallery",
      url: "/admin/add-edit-Gallery",
      type: "H-1",
    },
    {
      heading: "Upload Photo",
      content: "Add individual images to the gallery or specific albums",
      url: "/admin/add-edit-Gallery",
      type: "H-2",
    },
    {
      heading: "Bulk Upload Photos",
      content: "Upload multiple images at once for streamlined gallery updates",
      url: "/admin/add-edit-Gallery",
      type: "H-3",
    },
    {
      heading: "Approve Photos",
      content:
        "Review and approve photos submitted by chapters, SIGs, or other groups before they are published",
      url: "/admin/gallery-table",
      type: "H-4",
    },
    {
      heading: "Manage Gallery",
      content:
        "Edit album details, delete photos, or temporarily disable albums from public view",
      url: "/admin/gallery-table",
      type: "H-5",
    },
    {
      heading: "Generate Gallery Reports",
      content:
        "Create reports on uploaded images, albums, and gallery engagement",
      url: "",
      type: "H-6",
    },
  ],
};

// AssitantAdmin Management
export const AssistantAdminManagement = {
  assistantAdminEventDetails: [
    {
      name: "Event Management",
      heading: "Add Event Type",
      content: "Create a new event type",
      url: "/assistantadmin/eventList",
      type: "B-1",
    },
    {
      heading: "Add Event",
      content:
        "Create a new event by entering details such as title, date, location, and description",
      url: "/assistantadmin/eventForm",
      type: "B-2",
    },
    {
      heading: "Manage Events",
      content:
        "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
      url: "/assistantadmin/eventList",
      type: "B-3",
    },
    {
      heading: "Approve Events",
      content:
        "Review and approve events added by Chapters and SIG for website listing",
      url: "/assistantadmin/eventList",
      type: "B-4",
    },
    {
      heading: "Manage Completed Events",
      content:
        "Add and update details for finished events, including participant data, feedback, and associated media",
      url: "",
      type: "B-5",
    },
    {
      heading: "Generate Event Reports",
      content:
        "Create detailed reports of events including participant data, feedback, and associated media",
      url: "",
      type: "B-6",
    },
  ],

  assistantAdminChapterDetails: [
    {
      name: "Chapter Management",
      heading: "Add New Chapter",
      content:
        "Create a new ELTAI chapter by entering details such as name, location, and leadership information",
      url: "/chapterForm",
      type: "C-1",
    },
    {
      heading: "Approve Chapter Details",
      content:
        "Review and approve changes in chapter details made by chapter heads",
      url: "/assistantadmin/view-Chapter",
      type: "C-2",
    },
    {
      heading: "Manage Chapter",
      content:
        "Edit chapter details, temporarily disable its operations, or permanently delete the chapter and its information from the system",
      url: "/assistantadmin/view-Chapter",
      type: "C-3",
    },
    {
      heading: "View Chapters List",
      content:
        "Display a list of all active chapters, providing quick access to their details and status",
      url: "/assistantadmin/view-Chapter",
      type: "C-5",
    },
    {
      heading: "Generate Chapter Reports",
      content:
        "Create reports on chapter activities, membership statistics, and performance metrics",
      url: "",
      type: "C-6",
    },
  ],

  assistantAdminSigDetails: [
    {
      name: "SIG Management",
      heading: "Add New SIG",
      content:
        "Create a new Special Interest Group by entering required details",
      url: "/assistantAdmin/sigGroupForm",
      type: "D-1",
    },
    {
      heading: "Approve SIG",
      content:
        "Review and approve applications for new Special Interest Groups seeking official recognition within ELTAI",
      url: "/assistantadmin/addEditSigGroups",
      type: "D-2",
    },
    {
      heading: "Manage SIG",
      content:
        "Edit SIG details, temporarily disable its activities, or permanently delete the SIG and its information from the system",
      url: "/assistantadmin/addEditSigGroups",
      type: "D-3",
    },
    {
      heading: "View SIGs List",
      content:
        "Display a list of all active SIGs for quick access to their details and status",
      url: "/assistantadmin/addEditSigGroups",
      type: "D-4",
    },
    {
      heading: "Generate SIG Reports",
      content:
        "Create reports on SIG activities, membership statistics, and engagement metrics",
      url: "",
      type: "D-5",
    },
  ],

  assistantAdminPartnerDetails: [
    {
      name: "Partner Management",
      heading: "Add New Partner",
      content:
        "Create a new partnership by entering details such as partner name, contact information, and collaboration focus",
      url: "/assistantadmin/add-edit-partner",
      type: "E-1",
    },
    {
      heading: "Manage Partners",
      content:
        "Edit partner details, temporarily disable their status, or permanently remove them and their information from the system",
      url: "/assistantadmin/partner-table",
      type: "E-2",
    },
    {
      heading: "View Partners List",
      content:
        "Display a comprehensive list of all active partners for quick access to their details and status",
      url: "/assistantadmin/partner-table",
      type: "E-3",
    },
    {
      heading: "Generate Partner Reports",
      content:
        "Create reports on partnership activities, contributions, and performance metrics",
      url: "",
      type: "E-4",
    },
  ],

  assistantAdminResourceDetails: [
    {
      name: "Resource Management",
      heading: "Add Resource",
      content:
        "Upload new learning resources, materials, and references to the resource library for members",
      url: "",
      type: "F-1",
    },
    {
      heading: "Manage Resources",
      content:
        "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
      url: "",
      type: "F-2",
    },
    {
      heading: "Generate Reports",
      content:
        "Create detailed reports on uploaded resources, including usage statistics and categorisation",
      url: "",
      type: "F-3",
    },
  ],

  assistantAdminCommunicationDetails: [
    {
      name: "Communication Management",
      heading: "Post/Schedule Announcements",
      content:
        "Create and publish important updates or notices for the community",
      url: "/assistantAdmin/addEditImport-Announment",
      type: "G-1",
    },
    {
      heading: "Manage Announcements",
      content: "Edit, delete, or temporarily hide existing announcements",
      url: "/assistantadmin/importannoucenment",
      type: "G-2",
    },
    {
      heading: "Approve Announcements",
      content: "Review and approve announcements before they are published",
      url: "/assistantadmin/importannoucenment",
      type: "G-3",
    },
    {
      heading: "Post/Schedule News",
      content:
        "Share news articles or updates relevant to ELTAI and its members",
      url: "/assistantAdmin/addEditJournal",
      type: "G-4",
    },
    {
      heading: "Manage News",
      content: "Edit, delete, or temporarily hide previously posted news",
      url: "/assistantadmin/journalTable",
      type: "G-5",
    },
    {
      heading: "Approve News",
      content: "Review and approve news submissions for publication",
      url: "/assistantadmin/journalTable",
      type: "G-6",
    },
    {
      heading: "Send/Schedule Emails",
      content:
        "Draft and send targeted email communications to members or groups",
      url: "",
      type: "G-7",
    },
    {
      heading: "Approve Emails",
      content:
        "Review and approve email drafts before they are sent to recipients",
      url: "",
      type: "G-8",
    },
    {
      heading: "Manage Header Banner",
      content:
        "Add, edit, disable, or delete header banners on the homepage of the website",
      url: "",
      type: "G-9",
    },
    {
      heading: "Manage Subscribers",
      content:
        "Oversee subscriber lists for newsletters or specific communication channels",
      url: "",
      type: "G-10",
    },
    {
      heading: "Manage Discussion Forums",
      content:
        "Start, edit, delete, or temporarily disable discussion threads for member engagement",
      url: "/assistantadmin/forumTable",
      type: "G-11",
    },
    {
      heading: "Manage Spotlights",
      content:
        "Add, edit, delete, or temporarily disable spotlight images on the homepage",
      url: "/assistantadmin/spotlight-table",
      type: "G-12",
    },
    {
      heading: "Generate Communication Reports",
      content:
        "Create reports on communication activities, including announcements, news, emails, and forum engagement",
      url: "",
      type: "G-13",
    },
  ],

  assistantAdminGalleryDetails: [
    {
      name: "Gallery Management",
      heading: "Create Album",
      content: "Organise photos by creating a new album in the gallery",
      url: "/assistantAdmin/add-edit-Gallery",
      type: "H-1",
    },
    {
      heading: "Upload Photo",
      content: "Add individual images to the gallery or specific albums",
      url: "/assistantAdmin/add-edit-Gallery",
      type: "H-2",
    },
    {
      heading: "Bulk Upload Photos",
      content: "Upload multiple images at once for streamlined gallery updates",
      url: "/assistantAdmin/add-edit-Gallery",
      type: "H-3",
    },
    {
      heading: "Approve Photos",
      content:
        "Review and approve photos submitted by chapters, SIGs, or other groups before they are published",
      url: "/assistantadmin/gallery-table",
      type: "H-4",
    },
    {
      heading: "Manage Gallery",
      content:
        "Edit album details, delete photos, or temporarily disable albums from public view",
      url: "/assistantadmin/gallery-table",
      type: "H-5",
    },
    {
      heading: "Generate Gallery Reports",
      content:
        "Create reports on uploaded images, albums, and gallery engagement",
      url: "",
      type: "H-6",
    },
  ],
};

// ChapterAdminManagement
export const ChapterAdminManagement = {
  chapterAdminMembershipDetails: [
    {
      name: "Membership Management",
      heading: "Add New Member",
      content: "Register a new ELTAI member by entering their details manually",
      url: "/admin/eventForm",
      type: "A-9",
    },
    {
      heading: "Bulk Upload New Members",
      content: "Add multiple new members at once using a pre-formatted file",
      url: "allMemberList",
      type: "A-10",
    },
    {
      heading: "Generate Membership Reports",
      content:
        "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
      url: "",
      type: "A-11",
    },
  ],

  chapterAdminEventDetails: [
    {
      name: "Event Management",
      heading: "Add Event",
      content:
        "Create a new event by entering details such as title, date, location, and description",
      url: "/admin/eventForm",
      type: "B-2",
    },
    {
      heading: "Manage Events",
      content:
        "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
      url: "",
      type: "B-3",
    },
    {
      heading: "Manage Completed Events",
      content:
        "Add and update details for finished events, including participant data, feedback, and associated media",
      url: "",
      type: "B-5",
    },
    {
      heading: "Generate Event Reports",
      content:
        "Create detailed reports of events including participant data, feedback, and associated media",
      url: "",
      type: "B-6",
    },
  ],

  chapterAdminChapterDetails: [
    {
      name: "Chapter Management",
      heading: "Add/Edit Chapter Details",
      content:
        "Add or modify details of your chapter, such as description and core team",
      url: "/admin/eventForm",
      type: "C-8",
    },
    {
      heading: "Check Chapter’s Share",
      content:
        "Check the accrued and debited funds in the chapter’s 10% share of membership fees",
      url: "",
      type: "C-9",
    },
    {
      heading: "Generate Chapter Reports",
      content:
        "Create reports on chapter activities, membership statistics, and performance metrics",
      url: "",
      type: "C-10",
    },
  ],

  chapterAdminResourceDetails: [
    {
      name: "resource Management",
      heading: "Add Resource",
      content:
        "Upload new learning resources, materials, and references to the resource library for members",
      url: "",
      type: "F-1",
    },
    {
      heading: "Manage Resources",
      content:
        "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
      url: "",
      type: "F-2",
    },
    {
      heading: "Generate Reports",
      content:
        "Create detailed reports on uploaded resources, including usage statistics and categorisation",
      url: "",
      type: "F-3",
    },
  ],

  chapterAdminCommunicationDetails: [
    {
      name: "Communication Management",
      heading: "Post/Schedule Announcements",
      content:
        "Create and publish important updates or notices for the community",
      url: "",
      type: "G-14",
    },
    {
      heading: "Manage Announcements",
      content: "Edit, delete, or temporarily hide existing announcements",
      url: "",
      type: "G-15",
    },
    {
      heading: "Post/Schedule News",
      content:
        "Share news articles or updates relevant to ELTAI and its members",
      url: "",
      type: "G-16",
    },
    {
      heading: "Manage News",
      content: "Edit, delete, or temporarily hide previously posted news",
      url: "",
      type: "G-18",
    },
    {
      heading: "Send/Schedule Emails",
      content:
        "Draft and send targeted email communications to members or groups",
      url: "",
      type: "G-29",
    },
  ],

  chapterAdminGalleryDetails: [
    {
      name: "Gallery Management",
      heading: "Create Album",
      content: "Organise photos by creating a new album in the gallery",
      url: "",
      type: "H-1",
    },
    {
      heading: "Upload Photo",
      content: "Add individual images to the gallery or specific albums",
      url: "",
      type: "H-2",
    },
    {
      heading: "Bulk Upload Photos",
      content: "Upload multiple images at once for streamlined gallery updates",
      url: "",
      type: "H-3",
    },
    {
      heading: "Manage Gallery",
      content:
        "Edit album details, delete photos, or temporarily disable albums from public view",
      url: "",
      type: "H-5",
    },
    {
      heading: "Generate Gallery Reports",
      content:
        "Create reports on uploaded images, albums, and gallery engagement",
      url: "",
      type: "H-6",
    },
  ],
};

// SigAdminManagement
export const SigAdminManagement = {
  sigAdminMembershipDetails: [
    {
      name: "Membership Management",
      heading: "Generate Membership Reports",
      content:
        "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
      url: "",
      type: "A-12",
    },
  ],

  sigAdminEventDetails: [
    {
      name: "Event Management",
      heading: "Add Event",
      content:
        "Create a new event by entering details such as title, date, location, and description",
      url: "/admin/eventForm",
      type: "B-2",
    },
    {
      heading: "Manage Events",
      content:
        "Review and approve events for website listing, edit event details, permanently delete events, or temporarily hide them from public view",
      url: "",
      type: "B-3",
    },
    {
      heading: "Manage Completed Events",
      content:
        "Add and update details for finished events, including participant data, feedback, and associated media",
      url: "",
      type: "B-5",
    },
    {
      heading: "Generate Event Reports",
      content:
        "Create detailed reports of events including participant data, feedback, and associated media",
      url: "",
      type: "B-6",
    },
  ],

  sigAdminSigDetails: [
    {
      name: "SIG Management",
      heading: "Add/Edit SIG Details",
      content:
        "Create reports on SIG activities, membership statistics, and engagement metrics",
      url: "/admin/eventForm",
      type: "D-6",
    },
    {
      heading: "Generate SIGs Reports",
      content:
        "Create reports on SIG activities membership statistics, and engagement metrics",
      url: "",
      type: "D-7",
    },
  ],

  sigAdminResourceDetails: [
    {
      name: "Resource Management",
      heading: "Add Resource",
      content:
        "Upload new learning resources, materials, and references to the resource library for members",
      url: "",
      type: "F-1",
    },
    {
      heading: "Manage Resources",
      content:
        "Edit, Approve, permanently delete, or temporarily hide existing resources in the library",
      url: "",
      type: "F-2",
    },
    {
      heading: "Generate Reports",
      content:
        "Create detailed reports on uploaded resources, including usage statistics and categorisation",
      url: "",
      type: "F-3",
    },
  ],

  sigAdminCommunicationDetails: [
    {
      name: "Communication Management",
      heading: "Post/Schedule Announcements",
      content:
        "Create and publish important updates or notices for the community",
      url: "",
      type: "G-18",
    },
    {
      heading: "Manage Announcements",
      content: "Edit, delete, or temporarily hide existing announcements",
      url: "",
      type: "G-19",
    },
    {
      heading: "Post/Schedule News",
      content:
        "Share news articles or updates relevant to ELTAI and its members",
      url: "",
      type: "G-20",
    },
    {
      heading: "Manage News",
      content: "Edit, delete, or temporarily hide previously posted news",
      url: "",
      type: "G-21",
    },
    {
      heading: "Send/Schedule Emails",
      content:
        "Draft and send targeted email communications to members or groups",
      url: "",
      type: "G-22",
    },
    {
      heading: "Manage Discussion Forums",
      content:
        "Start, edit, delete, or temporarily disable discussion threads for member engagement",
      url: "",
      type: "G-23",
    },
  ],

  sigAdminGalleryDetails: [
    {
      name: "Gallery Management",
      heading: "Create Album",
      content: "Organise photos by creating a new album in the gallery",
      url: "",
      type: "H-1",
    },
    {
      heading: "Upload Photo",
      content: "Add individual images to the gallery or specific albums",
      url: "",
      type: "H-2",
    },
    {
      heading: "Bulk Upload Photos",
      content: "Upload multiple images at once for streamlined gallery updates",
      url: "",
      type: "H-3",
    },
    {
      heading: "Manage Gallery",
      content:
        "Edit album details, delete photos, or temporarily disable albums from public view",
      url: "",
      type: "H-5",
    },
    {
      heading: "Generate Gallery Reports",
      content:
        "Create reports on uploaded images, albums, and gallery engagement",
      url: "",
      type: "H-6",
    },
  ],
};

export const MembershipBenefitsData = [
  {
    Category: "Growth Matters: Skill Building & Professional Development",
    data: [
      {
        name: "E-certificates",
        description:
          "Earn e-certificates for participating in free webinars organised by ELTAI, recognising your commitment to professional development.",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Enhanced Learning Opportunities",
        description:
          " Gain access to a range of conferences, seminars, workshops, and webinars specifically designed to improve skills and knowledge in English language and literature teaching and research. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Faculty Development Programs",
        description:
          "Engage in Faculty Development Programs focused on updating your knowledge and refining teaching methodologies for effective classroom practices. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Capacity Building Programmes",
        description:
          "Participate in Capacity Building Programmes that enhance research capabilities and employability skills, preparing you for advancements in your professional journey. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Consultancy Services",
        description:
          " Benefit from access to consultancy services that focus on enhancing English communication skills. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Professional Development Partnerships",
        description:
          " Partner with ELTAI to offer customised professional development programs for your institution’s educators and students, enhancing skills and knowledge within your organization. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Resource Matters: Access to Exclusive Tools & Materials ",
    data: [
      {
        name: "Print Copies of JELT",
        description:
          "Receive print copies of the Journal of English Language Teaching, our bi-monthly peer-reviewed journal, delivered directly to you. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Print Copies of JTREL",
        description:
          "Receive print copies of the Journal of Teaching and Research in English Literature, our quarterly peer-reviewed journal, delivered directly to you. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Archives of English Literature Research",
        description:
          " Gain access to the archives of the Journal of Teaching and Research in English Literature, our quarterly peer-reviewed online journal, for valuable research insights. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Archives of Technology for ELT",
        description:
          " Access the archives of the Journal of Technology for ELT, our peer-reviewed online journal, offering the latest advancements and research in educational technology. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Archives of ELT Practitioner",
        description:
          "Explore the archives of ELT Practitioner, our quarterly online peer-reviewed journal, featuring practical resources and articles for English language teaching. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Conference Proceedings Archives",
        description:
          "Review the archives of our conference proceedings to engage with the discussions and findings presented at ELTAI events. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Training Manuals Archives",
        description:
          "Access the archives of training manuals prepared for our workshops, providing practical guidance and strategies for effective teaching. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Members-only Resources (under construction)",
        description:
          "Enjoy exclusive access to a wealth of teaching materials, lesson plans, and classroom resources tailored specifically for English language educators. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Webinar and Conference Recordings",
        description:
          " Access recordings of all our webinars and past conference sessions, allowing you to revisit valuable content at your convenience. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Job Posting Access",
        description:
          "Access a wide range of job postings from various institutions, connecting you with opportunities tailored for qualified English language professionals. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Job Posting Access",
        description:
          "Share institutional job openings with all ELTAI members, reaching a broad audience of qualified English language professionals. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Connection Matters: Networking & Collaborative Opportunities ",
    data: [
      {
        name: "Member Search Feature",
        description:
          "Utilise our member search feature to connect with other members for various professional purposes, including finding supervisors, speakers, and collaborators. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Enhanced Visibility",
        description:
          "Maximise your visibility and discoverability within the ELTAI community through our member search feature on the website, facilitating connections that can enhance your professional journey. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Expansive Networking Opportunities",
        description:
          "Connect with a diverse network of English language teaching professionals and institutions for collaboration, support, and sharing best practices. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Free Membership in Special Interest Groups (SIGs)",
        description:
          "Join any of our Special Interest Groups at no cost, including Literature SIG, Technology SIG, Business English SIG, etc. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Online Forums and Discussion Groups",
        description:
          "Participate in online forums and discussion groups to engage in meaningful discussions and share experiences with fellow educators. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Global Networking Opportunities",
        description:
          "Take advantage of opportunities to engage with international educators and organisations through ELTAI’s partnerships with other teacher associations abroad, enriching your perspective on language teaching. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Professional Affiliations",
        description:
          "Connect with partner member organisations for enhanced networking and collaboration, broadening your professional horizons. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Exclusive Event Participation",
        description:
          "Participate in ELTAI events as exhibitors or guest speakers, increasing engagement with educators and expanding institutional reach. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Collaboration Opportunities",
        description:
          "Gain access to collaboration opportunities with other institutions and professionals within the ELTAI network for joint projects, faculty exchanges, and research partnerships. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Money Matters: Discounts & Financial Benefits ",
    data: [
      {
        name: "Discounted Event Registration",
        description:
          "Enjoy a 10% discount on registration fees for the ELTAI Annual Conference, seminars, workshops, Faculty Development Programs (FDPs), and Capacity Building Programs (CBPs), making it more affordable to attend. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Reduced Membership Rates for International TAs",
        description:
          "Access reduced rates for membership in international teacher associations, such as IATEFL, helping you connect with global networks at a lower cost. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Eligibility for Professional Development Scholarships",
        description:
          "Apply for scholarships to support your attendance at online events hosted by ELTAI, providing financial assistance for continuous learning. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Scholarship Opportunities for ELTAI Conference Presentations",
        description:
          "Be eligible to apply for scholarships to present papers at ELTAI Annual Conferences, facilitating your contributions to the field without financial burden. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Funding for International Conference Presentations",
        description:
          "Apply for scholarships to present papers at international conferences organised by our partner teacher associations abroad, helping to expand your reach and impact in the global education community. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
    ],
  },
  {
    Category: "Leadership Matters: Developing Leadership Skills ",
    data: [
      {
        name: "Leadership Skills Development Programs",
        description:
          "Participate in specialised training programs aimed at developing educational leadership skills in English language and literature teaching, equipping you to take on leadership roles effectively. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Opportunities for Leadership Roles",
        description:
          "Gain opportunities to serve on committees, boards, and local chapters, fostering your leadership skills through active involvement and collaboration with fellow educators. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Engagement in Local Chapter Activities",
        description:
          "Actively participate in activities, meetings, and projects organised by local chapters, enhancing community engagement while building your leadership experience. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Research Matters: Knowledge Sharing & Innovation ",
    data: [
      {
        name: "Contribution Opportunities",
        description:
          "Contribute articles, research findings, and insights to ELTAI's journals and newsletters, enhancing your profile and sharing your expertise with the community. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Showcase Your Work",
        description:
          "Present your research, innovative teaching strategies, and practices at ELTAI webinars, providing an excellent platform to increase your visibility within the teaching community. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Publishing Opportunities",
        description:
          "Publish books, research papers, and articles through ELTAI, gaining recognition for your contributions to the field of English language teaching. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Collaborative Research Initiatives",
        description:
          "Engage in collaborative research projects with fellow members, institutions, and international partners, addressing pressing issues in English education and contributing to meaningful advancements in the field. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Joint Research Initiatives",
        description:
          "Engage in joint research projects with other institutions and ELTAI members, promoting innovative research in English language and literature education. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Knowledge-Sharing Events",
        description:
          "Participate in or host knowledge-sharing events within the ELTAI network, broadening exposure to new ideas and practices in education. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Advocacy Matters: Community Engagement & Policy Influence ",
    data: [
      {
        name: "Policy Advocacy and Representation",
        description:
          "Actively participate in Chapter and General Body Meetings, voicing your opinions on local, national, and international educational policies that impact the teaching profession and English language and literature education. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Community Engagement",
        description:
          "Engage with a diverse community of educators from various backgrounds, promoting inclusivity and exchanging multicultural perspectives in language teaching. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Support for Underrepresented Groups",
        description:
          "Get involved in initiatives specifically designed to support underrepresented groups in English language teaching, contributing to the creation of a more inclusive professional environment. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Recognition Matters: Awards & Professional Acknowledgement ",
    data: [
      {
        name: "ELTAI Membership Card",
        description:
          "Receive a printed / e-copy of your ELTAI membership card, showcasing your affiliation with the organisation.(PVC material) ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        description:
          "Receive a printed / e-copy of your ELTAI membership card, showcasing your affiliation with the organisation.(e-copy) ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "ELTAI Membership Certificate",
        description:
          "Obtain an e-copy of your ELTAI membership certificate as a formal recognition of your membership. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Awards for Best Presentations",
        description:
          "Gain eligibility for Best Presentation Awards at ELTAI Annual Conferences, acknowledging outstanding contributions and presentations. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Recognition for Contributions",
        description:
          "Be eligible for special awards that recognise significant contributions to the field of English language and literature teaching, including awards for innovative approaches, which motivate continuous improvement in teaching practices. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 1,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Institutional Recognition on ELTAI’s Website",
        description:
          "Receive recognition on ELTAI’s website, boosting visibility in the national and international education community. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Showcase Products or Services:",
        description:
          "Opportunity for institutions to promote their educational products, services, or achievements to ELTAI members. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
  {
    Category: "Experience Matters: Internships & Exchange Programs ",
    data: [
      {
        name: "Access Local and International Internships",
        description:
          "Explore a variety of local and international internship opportunities facilitated through ELTAI’s extensive professional network, designed to enhance hands-on experience. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Offer Local and International Internships",
        description:
          "Provide valuable local and international internship opportunities to ELTAI members, leveraging ELTAI’s network to connect with aspiring educators and researchers. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Pursue Certification Programmes",
        description:
          "Participate in certification programmes that provide opportunities to earn recognised credentials in specific areas of English language and literature teaching, enhancing your professional qualifications.  ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Offer Certification Programmes",
        description:
          "Offer certification programmes that enable ELTAI members to earn recognised credentials in specific areas of English language and literature teaching, supporting their professional development and enhancing the overall quality of education. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Participate in Educational Exchange Programmes",
        description:
          "Engage in exchange programmes that offer teachers and students the chance to immerse themselves in diverse educational environments and practices, broadening their teaching perspectives. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Facilitate Educational Exchange Programmes",
        description:
          "Facilitate educational exchange programmes that allow your teachers and students to immerse themselves in diverse educational environments and practices, thereby enriching their perspectives and enhancing the learning experience within your institution. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 0,
            "Long - term": 0,
            "Short - term_1": 0,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 0,
          },
        ],
      },
    ],
  },

  {
    Category: "Guidance Matters: Mentorship, Support & Feedback ",
    data: [
      {
        name: "Mentorship Programmes",
        description:
          "Access mentorship opportunities where seasoned educators and researchers provide guidance and support to newer teachers and researchers in their professional development. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Structured Peer Collaboration",
        description:
          "Participate in organised peer collaboration sessions designed to facilitate the sharing of best practices and address common challenges in English language and literature teaching. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
      {
        name: "Constructive Feedback Opportunities",
        description:
          "Receive constructive feedback on teaching methods, instructional materials, and strategies for engaging students, fostering continuous improvement in teaching practice. ",
        plans: [
          {
            Annual: 0,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 0,
            "Short - term_2": 0,
            "Long - term_1": 0,
            "Long - term_2": 0,
          },
        ],
      },
    ],
  },
  {
    Category: "Update Matters: News & Industry Insights ",
    data: [
      {
        name: "Regular Knowledge Updates",
        description:
          "Receive ongoing updates featuring the latest research findings and resources in English language teaching, ensuring you remain well-informed about advancements in the field. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
      {
        name: "Monthly Newsletter",
        description:
          "Enjoy a monthly newsletter packed with essential updates, upcoming events, and job postings tailored for English language teaching professionals. ",
        plans: [
          {
            Annual: 1,
            "Short - term": 1,
            "Long - term": 1,
            "Short - term_1": 1,
            "Short - term_2": 1,
            "Long - term_1": 1,
            "Long - term_2": 1,
          },
        ],
      },
    ],
  },
];

export const planType = [
  {
    planName: 'Individual Teacher',
    planId: '0',
    noOfUser:'1',
    planType: [
      {
        planTypeName:'Annual',
        planTypeId:'0'
      },
      {
        planTypeName:'Short-term',
        planTypeId:'1'
      },
      {
        planTypeName:'Long-term',
        planTypeId:'2'
      }
    ]
  },
  {
    planName: 'Dual Teacher',
    planId: '1',
    noOfUser:'2',
    planType: [
      {
        planTypeName:'Short-term',
        planTypeId:'0'
      },
      {
        planTypeName:'Long-term',
        planTypeId:'1'
      }
    ]
  },
  {
    planName: 'Individual Student',
    planId: '2',
    noOfUser:'1',
    planType: [
    {
      planTypeName:'Short-term',
      planTypeId:'0'
    }
  ]
  },
  {
    planName: 'Institutional',
    planId: '3',
    noOfUser:'1',
    planType: [
      {
        planTypeName:'Short-term',
        planTypeId:'0'
      },
      {
        planTypeName:'Long-term ',
        planTypeId:'1'
      }
    ]
  },
  {
    planName: 'Corporate Supporter ',
    planId: '4',    
    noOfUser:'1',
    planType: [
      {
        planTypeName:'Long-term',
        planTypeId:'0'
      }
    ]
  }
]