import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import "../../style/css/upcomingeventssection.css";
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetAllEventsPaginationMutation } from "../../app/services/eventService";
import { Spinner } from "react-bootstrap";
import { usePaginationAnnualConferenceMutation } from "../../app/services/annualConferenceService";


function UpComingEventsSection() {
  const [getAllEventList] = useGetAllEventsPaginationMutation();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const memberScope = useSelector(selectCurrentScope); //get scope
  const [getAllAnnouncementPagination] = usePaginationAnnualConferenceMutation();
  const [allConference, setAllConference] = useState([]);




  //format Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day of the month
    const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase(); // Get short month and convert to uppercase
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    var data = {
      page: 1,
      itemsPerPage: 1,
    };
    getAllAnnouncementPagination(data).then((res) => {
      if (res?.data?.status) {
        setAllConference(res?.data?.data?.annualConferenceDetails)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }, [getAllAnnouncementPagination])

  useEffect(() => {
  }, [allConference])

  //Get all events list
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: 3,
      role: ""
    };
    getAllEventList(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        // setTotalPage(res?.data?.data?.totalCount || 0);
        setAllEvents(res.data?.data?.eventDetails || []);
      } else {
        setLoading(false);
      }
    });
  }, [getAllEventList, page]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1); // Increment page count
    getAllPagination();
  };

  const handlePrevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : 1)); // Decrement page count but ensure it doesn't go below 1
    getAllPagination();
  };


  return (
    <>
      <div className="events p-md-5 p-3 overallPadding">
        <div className="d-flex justify-content-between mb-3">
          <h2 className="headingConfig custom-header ">Our Upcoming Events</h2>
          <div className="d-flex align-items-center">
            {/* <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="arrowConfig" /> </p> */}
            <div className="ms-auto align-items-center mt-2 mt-md-0">
              {memberScope === "PORTAL_SUPER_ADMIN" && (
                <Link to="/admin/eventFeed">
                  <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" /> </p>
                </Link>
              )}
              {memberScope === "PORTAL_ADMIN" && (
                <Link to="/assistantAdmin/eventFeed">
                  <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" /> </p>
                </Link>
              )}
              {memberScope === "PORTAL_SIG_ADMIN" && (
                <Link to="/sigAdmin/eventFeed">
                  <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" /> </p>
                </Link>
              )}
              {memberScope === "PORTAL_CHAPTER_ADMIN" && (
                <Link to="/chapterAdmin/eventFeed">
                  <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" /> </p>
                </Link>
              )}
              {memberScope === "PORTAL_MEMBER" && (
                <Link to="/member/eventFeed">
                  <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="ms-1 arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="ms-1 arrowConfig" /> </p>
                </Link>
              )}
              {(memberScope === "" ||
                memberScope === undefined ||
                memberScope === null) && (
                  <Link to="/eventFeed">
                    <p className="viewAllEvents mb-0">View All <EastIcon />  <ArrowBackIosIcon onClick={handleNextPage} className="arrowConfig" />  <ArrowForwardIosIcon onClick={handlePrevPage} className="arrowConfig" /> </p>
                  </Link>
                )}
            </div>
          </div>
        </div>
        {allConference && allConference?.length >= 1 ? (
          <>
            {allConference?.map((data) =>
              <div className="conferenceCard">
                <img src={data.imageUrl} alt="img" className="conference" />
                <div className="bg-light">
                  <div className="fullSection">
                    <div className="d-flex justify-content-between pe-4 ps-4">
                      <p className="bg-article text-light p-2">{data.date}</p>
                      <p className="bg-article text-light h-25 p-2 annaulConference">Annual Conference</p>
                    </div>
                    <h1 className="ps-4 m-0 text-start commonColor">{data.conferenceName}</h1>
                    <div className="d-flex justify-content-between pe-4 ps-4">
                      <div className="mt-3">
                        <p className="commonColor mb-0">hosted by</p>
                        <p className="commonColor mb-0">{data.hostedBy}</p>
                      </div>
                      <div className="d-flex align-items-end">
                        <Link to={'https://www.eltaiconferences.in'} target="_blank">
                          <p className="commonColor mb-0">Know more <EastIcon className="ms-1" /></p></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (<></>)
        }

        {loading ? (
          <Spinner />
        ) : (
          <>
            {allEvents && allEvents?.length >= 1 ? (
              <div className=" events pt-5">
                <div className="row ">
                  {allEvents.map((data) => (
                    <div className="col-sm-12 col-md-6 col-lg-4 p-0  d-flex justify-content-center">
                      <div className="ecard mb-lg-0 mb-4">
                        <img src={data.imageUrl} alt="" />
                        <div className="eventFullSection">
                          <div className="d-flex justify-content-between pe-4 ps-4">
                            {data.endDate ? (
                              <p className="p-2 eventDate mb-0">
                                {formatDate(data.startDate.split("T")[0])} <br /> - <br /> {formatDate(data.endDate.split("T")[0])}
                              </p>
                            ) : (
                              <p className="oneDayEvent">
                                {formatDate(data.startDate.split("T")[0])}
                              </p>
                            )}
                            <p className="bg-article h-25 ps-1 pe-1">Article</p>
                          </div>
                          <h3 className="ps-4 pt-2 truncate-text">{data.title}</h3>
                          <div className="d-flex justify-content-between flex-wrap px-4">
                            <div>
                              <p className="mb-0 commonColor">Posted by</p>
                              <b className="mb-0 commonColor">{data.organizerName}</b>
                            </div>
                            <Link className="d-flex align-items-end"
                              style={{
                                color: "#FF8000",
                              }}
                              to={
                                memberScope === "PORTAL_SUPER_ADMIN"
                                  ? `/admin/VieweventFeed/${data?._id}` :
                                  memberScope === "PORTAL_ADMIN"
                                    ? `/assistantAdmin/VieweventFeed/${data?._id}`
                                    : memberScope === "PORTAL_SIG_ADMIN"
                                      ? `/sigAdmin/VieweventFeed/${data?._id}`
                                      : memberScope === "PORTAL_CHAPTER_ADMIN"
                                        ? `/chapterAdmin/VieweventFeed/${data?._id}`
                                        : memberScope === "PORTAL_MEMBER"
                                          ? `/MEMBER/VieweventFeed/${data?._id}`
                                          : `/VieweventFeed/${data?._id}`
                              }
                              role="button"
                            >
                              <b className="mb-0 d-flex align-items-center commonColor" >know more <EastIcon className="mt-1 ms-1" /></b>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            ) : (<></>)
            }
          </>
        )}
      </div>

    </>
  );
}

export default UpComingEventsSection;
