import { api } from "./api";

const PartnerServices = api.injectEndpoints({
    endpoints: (builder) => ({

        //saveJournal
        savePartner: builder.mutation({
            query: (data) => ({
                url: "partner/savePartner",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: data,
            })
        }),
        paginationPartner: builder.mutation({
            query: (data) => ({
                url: "partner/getAllPartnerPagination",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: data,
            }),
        }),
        getPartnerById: builder.query({
            query: (id) => "partner/get/" + id,
        }),
        updatePartner: builder.mutation({
            query: (data) => ({
                url: `partner/update/${data.id}`,
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: data,
            }),
          }),
          deletePartner: builder.mutation({
            query: (id) => ({
              url: "partner/delete/"+ id,
              method: "PUT",
              headers: { "Content-Type": "application/json" },
            }),
          }),
          getEnableDisable: builder.query({
            query: (id) => ({
              url: "partner/enableDisable/" + id,
              method: "put"
            })
          }),
    }),
    overrideExisting: false,
});

export const {
    useSavePartnerMutation, usePaginationPartnerMutation,useLazyGetPartnerByIdQuery,useUpdatePartnerMutation,
    useDeletePartnerMutation,useLazyGetEnableDisableQuery
} = PartnerServices;