import React, { useRef, useEffect } from 'react';
import HeroSection from '../Component/HomePage/HeroSection'
import AboutSection from '../Component/HomePage/AboutSection'
// import MembershipSection from '../Component/HomePage/MembershipSection'
// import YouTubeSection from '../Component/HomePage/YoutubeSection'
// import Journals from '../Component/HomePage/Journals'
// import UpcomingEvents from '../Component/HomePage/UpcomingEvents'
import Gallery from '../Component/HomePage/Gallery'
// import Partners from '../Component/HomePage/Partners'
// import Testimonials from '../Component/HomePage/Testimonials'
// import News from '../Component/HomePage/News'
// import Announcements from '../Component/HomePage/Announcements'
import UpComingEventsSection from '../Component/HomePage/UpComingEventsSection'
import '../style/css/parallex.css'
import SpotLight from '../Component/HomePage/SpotLight/SpotLight'


function HomePage() {
  // const heroRef = useRef(null);
  const aboutRef = useRef(null);
  // const membershipRef = useRef(null);
  // const youtubeRef = useRef(null);
  // const journalsRef = useRef(null);
  const chaptersRef = useRef(null);
  const upcomingEventsRef = useRef(null);
  const spotLightRef=useRef(null)
  const galleryRef = useRef(null);
  // const partnersRef = useRef(null);
  // const testimonialsRef = useRef(null);
  // const newsRef = useRef(null);
  // const announcementsRef = useRef(null);
  // Add refs for other sections as needed

  useEffect(() => {
    // const heroSection = heroRef.current;
    const aboutSection = aboutRef.current;
    // const membershipSection = membershipRef.current;
    // const youtubeSection = youtubeRef.current;
    // const journalSection = journalsRef.current;
    const chapterSection = chaptersRef.current;
    const upComingEventsSection = upcomingEventsRef.current;
    const gallerySection = galleryRef.current;
    // const partnersSection = partnersRef.current;
    // const testimonialsSection = testimonialsRef.current;
    const spotLightSection=spotLightRef.current
    // const newsSection = newsRef.current;
    // const announcementsSection = announcementsRef.current;
    // Get refs for other sections as needed

    //scrollHandler
    const scrollHandler = () => {
      const scrollPosition = window.scrollY + window.innerHeight;

      // Check if the section is in view
      // if (heroSection.offsetTop < scrollPosition) {
      //   heroSection.classList.add('visible');
      // }
      if (aboutSection.offsetTop < scrollPosition) {
        aboutSection.classList.add('visible');
      }
      // if (membershipSection.offsetTop < scrollPosition) {
      //   membershipSection.classList.add('visible');
      // }
      // if (youtubeSection.offsetTop < scrollPosition) {
      //   youtubeSection.classList.add('visible');
      // }
      // if (journalSection.offsetTop < scrollPosition) {
      //   journalSection.classList.add('visible');
      // }
      if (chapterSection.offsetTop < scrollPosition) {
        chapterSection.classList.add('visible');
      }
      if (spotLightSection.offsetTop < scrollPosition) {
        spotLightSection.classList.add('visible');
      }
      if (upComingEventsSection.offsetTop < scrollPosition) {
        upComingEventsSection.classList.add('visible');
      }
      if (gallerySection.offsetTop < scrollPosition) {
        gallerySection.classList.add('visible');
      }
      // if (partnersSection.offsetTop < scrollPosition) {
      //   partnersSection.classList.add('visible');
      // }
      // if (testimonialsSection.offsetTop < scrollPosition) {
      //   testimonialsSection.classList.add('visible');
      // }
      // if (newsSection.offsetTop < scrollPosition) {
      //   newsSection.classList.add('visible');
      // }
      // if (announcementsSection.offsetTop < scrollPosition) {
      //   announcementsSection.classList.add('visible');
      // }
      // Add similar checks for other sections
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <>
      {/* <HeroSection></HeroSection>
      <AboutSection></AboutSection>
      <MembershipSection></MembershipSection>
      <Journals></Journals>
      <Chapters ></Chapters>
      <UpcomingEvents></UpcomingEvents>
      <Gallery></Gallery>
      <Partners></Partners>
      <Testimonials></Testimonials>
      <News></News> */}
      <div className="parallax-container">
        {/* <div className="parallax" ref={heroRef}>
          <HeroSection />
        </div> */}
        <HeroSection />
        <div className="parallax" ref={aboutRef} style={{ background: '#effdf5' }}>
          <AboutSection />
        </div>
        {/* <div className="parallax" ref={membershipRef}>
          <MembershipSection />
        </div>
        <div className="parallax" ref={youtubeRef}>
          <YouTubeSection />
        </div>    */}
         <div className="parallax" ref={spotLightRef}>
          <SpotLight />
        </div>
        <div className="parallax" ref={upcomingEventsRef}>
          <UpComingEventsSection />
        </div>
        {/* <div className="parallax" ref={announcementsRef}>
          <Announcements />
        </div> */}
        {/* <div className="parallax" ref={journalsRef}>
          <Journals />
          </div> */}
        <div className="parallax" ref={chaptersRef}>
          {/* <Chapters /> */}
        </div>

        {/* <div className="parallax" ref={newsRef}>
          <News />
        </div> */}
        <div className="parallax" ref={galleryRef}>
          <Gallery />
        </div>
        {/* <div className="parallax" ref={testimonialsRef}>
          <Testimonials />
        </div> */}
        {/* <div className="parallax" ref={partnersRef}>
          <Partners />
        </div> */}
      </div>
    </>
  )
}

export default HomePage