import React, { useState, useEffect } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import { Box, Button } from "@mui/material";
import { IconButton } from "@mui/material";
import {
  usePaginationGalleryMutation,
} from "../../../app/services/galleryService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, Link } from "react-router-dom";
import "../../../style/css/pagination.css";
import ReactPaginate from "react-paginate"; // Import CSS file for pagination styles
import "./GalleryTable.css";
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import Spinner from "../../../Component/spinner/spinner";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";

//table Property
function EnhancedTableHead() {
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Gallery Image",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Album Name",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

const GalleryTable = () => {
  const [loading, setLoading] = useState(false);
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const itemsPerPage = 5;
  let [totalCount, setTotalCount] = useState(0);
  const [getAllGallerys, setGetAllGallerys] = useState([]);
  const navigate = useNavigate();
  const [PaginationGallery] = usePaginationGalleryMutation();
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  //paginationGallery
  useEffect(() => {
    setLoading(true)
    PaginationGallery({ page: currentPage + 1, itemsPerPage, role: (memberScope) ? memberScope : "" })
      .unwrap()
      .then((res) => {
        if (res.status) {
          setLoading(false)
          setGetAllGallerys(res.data.galleryDetails);
          setTotalCount(res?.data?.totalCount)
        }
        else {
          setLoading(false);
          toast.error(res?.err?.message)
        }
      });
  }, [PaginationGallery, currentPage, itemsPerPage, memberScope, userId]);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  //handleView 
  const handleView = (id) => {
    if (id && memberScope === "PORTAL_SUPER_ADMIN") {
      navigate(`/admin/showGallery/${id}`);
    } else if (id && memberScope === "PORTAL_SIG_ADMIN") {
      navigate(`/sigAdmin/showGallery/${id}`);
    } else if (id && memberScope === "PORTAL_CHAPTER_ADMIN") {
      navigate(`/chapterAdmin/showGallery/${id}`);
    } else if (id && memberScope === "PORTAL_MEMBER") {
      navigate(`/member/showGallery/${id}`);
    } else if (id && memberScope === "PORTAL_ADMIN") {
      navigate(`/assistantAdmin/showGallery/${id}`);
    }
  };

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Gallery List</h2>
        </div>
      </div>
      <div className="p-md-1 p-3 overallPadding">
        <div className="text-end w-100" style={{ marginBottom: "15px" }}>
          <Link
            to={
              memberScope === "PORTAL_SUPER_ADMIN"
                ? "/admin/add-edit-Gallery"
                : memberScope === "PORTAL_ADMIN"
                  ? "/assistantAdmin/add-edit-Gallery"
                  : memberScope === "PORTAL_SIG_ADMIN"
                    ? "/sigAdmin/add-edit-Gallery"
                    : "/chapterAdmin/add-edit-Gallery"
            }
          >
            <Button
              variant="contained"
              style={{ marginRight: "5px" }}
              className="justify-content-end menus-color"
            >
              Add Gallery
            </Button>
          </Link>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <Box >
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {getAllGallerys.length > 0 ?
                      getAllGallerys
                        .map((galery, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              cursor: "pointer",
                              backgroundColor:
                                index % 2 === 0 ? "#f0f0f0" : "white",
                            }}
                          >
                            <TableCell align="center">
                              <img
                                src={galery?.list[0]?.imageUrl}
                                width={"150px"}
                                height={"150px"}
                                alt="gallery"
                              />
                            </TableCell>

                            <TableCell align="center">
                              {galery?.category}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="View">
                                <IconButton aria-label="view" onClick={() => { handleView(galery._id) }}>
                                  <VisibilityIcon className="icon-vieweye-color" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="w-100 d-flex justify-content-center mt-5 pb-4">
                <ReactPaginate
                  pageCount={totalPages} // Replace with the total number of pages
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={(currentPage + 1) > 1 ? "Previous" : null}
                  nextLabel={((currentPage + 1) > (totalPages - 1)) ? null : "Next"}
                  disabledClassName={"disablebtn"}
                  forcePage={currentPage}
                />
              </div>
              <br />
            </Box>
          </div>
        )
        }
      </div>
    </div >
  );
};

export default GalleryTable;