import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
// import { Checkbox } from "@mui/material";
import "../../style/css/journalForm.css";
import TopHeader from "../../Component/Header/TopHeader";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useSaveJournalMutation,
  useLazyGetJournalByIdQuery,
  useUpdateJournalMutation,
} from "../../app/services/journalServices";
import {
  selectCurrentScope,
  // selectCurrentRoleId,
} from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { selectUserId } from "../../app/auth/authSlice";
import { selectCurrentSig } from "../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../app/features/chapterAdminSlice";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";

function AddEditJournal() {
  const memberScope = useSelector(selectCurrentScope);
  // const roleId = useSelector(selectCurrentRoleId);
  const { journalId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedOptionTab, setSelectedOptionTab] = useState("Author");
  const [selectedFile, setSelectedFile] = useState(null);
  const [saveJournal] = useSaveJournalMutation();
  const [getJournalById] = useLazyGetJournalByIdQuery(journalId);
  const [updateJournal] = useUpdateJournalMutation();
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [isValidImage, setIsValidImage] = useState(true);
  const userId = useSelector(selectUserId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;

  // useForm hook for form data handling
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "0",
      headline: "",
      summary: "",
      body: "",
      tags: '',
      thumbnailImage: "",
      date: "",
      authorName: "",
      SIGGroupsId: [],
      createdBy: {
        id: "",
        role: "",
      },
      chaptersId: [],
      // isPublic: false,
      // isRssFeed: false,
    },
  });

  useEffect(() => {
    if (journalId) {
      setLoading(true);
      getJournalById(journalId).then((res) => {
        if (res.data.status) {
          setLoading(false);
          const data = res.data?.data;
          Object.entries(data).forEach(([key, value]) => {
            setValue("date", data?.date?.split("T")[0]);
            setValue(key, value);
            setSelectedOptionTab(selectedOptionTab);
          });
        } else {
          setLoading(false);
        }
      });
    }
  }, [journalId, getJournalById, setValue, selectedOptionTab]);

  //savejournal
  const onSaveJournal = (formData) => {
    if (!isValidImage) {
      return; // Stop the form submission if the image is invalid or greater than 2MB
    }
    setLoading(true);
    trigger().then((isValid) => {
      if (isValid) {
        var newsData = {
          ...formData,
          releaseTime: setTime("releaseTime", formData.releaseTime),
          publisherTime: setTime("publisherTime", formData.publisherTime),
        };
        setData([...data, newsData]);
        let tableRoute;
        switch (memberScope) {
          case "PORTAL_SUPER_ADMIN":
            tableRoute = "/admin/JournalTable";
            break;
          case "PORTAL_ADMIN":
            tableRoute = "/assistantAdmin/JournalTable";
            break;
          case "PORTAL_SIG_ADMIN":
            tableRoute = "/sigAdmin/JournalTable";
            break;
          case "PORTAL_CHAPTER_ADMIN":
            tableRoute = "/chapterAdmin/JournalTable";
            break;
          default:
            tableRoute = "/";
            break;
        }
        if (
          (userId && memberScope === "PORTAL_SUPER_ADMIN") ||
          (userId && memberScope === "PORTAL_ADMIN") ||
          (userId && memberScope === "PORTAL_SIG_ADMIN") ||
          (userId && memberScope === "PORTAL_CHAPTER_ADMIN")
        ) {
          newsData.createdBy = {
            id: userId,
            role: memberScope,
          };
        }
        if (userId && memberScope === "PORTAL_SIG_ADMIN") {
          newsData.SIGGroupsId = [sigId];
        } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
          newsData.chaptersId = [chapterId];
        }
        if (journalId) {
          let requestIdAndData;
          const base64Regex =
            /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
          requestIdAndData = {
            id: journalId,
            data: {
              ...formData,
              thumbnailImage: base64Regex.test(formData?.thumbnailImage)
                ? formData.thumbnailImage
                : formData.thumbnailImage.split("/").slice(-2).join("/"),
            },
          };
          updateJournal(requestIdAndData)
            .then((res) => {
              setLoading(false);
              if (res.data.status) {
                reset();
                navigate(tableRoute);
                toast.success("Our Updates Updated Successfully");
              } else {
                toast.error(res.data?.err?.message);
              }
            })
            .catch((error) => {
              toast.error(error);
            });
        } else {
          saveJournal(newsData)
            .then((res) => {
              setLoading(false);
              if (res.data?.status) {
                reset();
                navigate(tableRoute);
                toast.success("Our Updates Saved Successfully");
              } else {
                setLoading(false);
                toast.error(res.data?.err?.message);
              }
            })
            .catch((error) => { });
        }
      }
    });
  };

  //handleImageChange
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImageErrorMessage('No file selected.')
      setIsValidImage(false);
    }
    if (file && !["image/jpeg", "image/png"].includes(file.type)) {
      setImageErrorMessage("Please upload a JPEG or PNG image.");
      setIsValidImage(false);
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      // Check if file size is greater than 2 MB
      setImageErrorMessage("File size exceeds 2 MB.");
      setIsValidImage(false);
      return;
    } else if (file) {
      setImageErrorMessage(""); // Clear the error message if the file is valid
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedFile(reader.result);
        setValue("thumbnailImage", base64String);
      };
      reader.readAsDataURL(file);
      setIsValidImage(true);
    }
  };

  //setTime
  const setTime = (name, newTime) => {
    try {
      let hours = newTime.$d.getHours();
      const AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      const formattedTime =
        hours.toString().padStart(2, "0") +
        ":" +
        newTime.$d.getMinutes().toString().padStart(2, "0") +
        " " +
        AmOrPm;
      setValue(name, formattedTime);
      return formattedTime;
    } catch {
      return newTime;
    }
  };

  //Validate year
  const validateYearLength = (value) => {
    const year = new Date(value).getFullYear().toString();
    if (year.length > 4) {
      return "Year cannot exceed 4 digits";
    }
    return true;
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{journalId ? "Edit Our Updates" : "Add Our Updates"}</h2>
        </div>
      </div>
      <div maxWidth={'xl'} className="p-md-5 p-3 overallPadding">
        <div className="d-flex justify-content-end align-items-end">
          <div>
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" ,borderRadius:0}}
            >
              Back
            </Button>

          </div>
        </div>
        {/* </div> */}
        {loading ? (
          <Spinner />
        ) : (
          <div
            maxWidth="md"
            style={{ marginTop: "20px", marginBottom: "50px" }}
          >
            <form className='w-100 p-0 m-0'onSubmit={handleSubmit(onSaveJournal)}>
              <div className="row mb-3 row-gap-3">
                <div className="col-md-4">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="headline"
                    label={
                      <Typography className="text-dark">
                        HeadLine <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    {...register("headline", {
                      required: "Please Enter Headline",
                    })}
                    placeholder="Enter  Headline"
                    error={!!errors?.headline}
                    helperText={errors?.headline?.message}
                  />
                </div>
                <div className="col-md-4 ">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="summary"
                    label={
                      <Typography className="text-dark">
                        Summary <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder=" Enter  summary"
                    {...register("summary", { required: "Please Enter Summary" })}
                    error={!!errors?.summary}
                    helperText={errors?.summary?.message}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="releaseDate"
                    label={
                      <Typography className="text-dark">
                        Date <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder="Select Date"
                    type="date"
                    {...register("date", { required: "Please Enter a Date", validate: validateYearLength, })}
                    error={!!errors?.date}
                    helperText={errors?.date?.message}
                  />
                </div>
              </div>
              <div className="row mb-3 row-gap-3">
                <div className="col-md-4">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="authorName"
                    label={
                      <Typography className="text-dark">
                        Author Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder="Author Name"
                    {...register("authorName", {
                      required: "Please Enter Author Name",
                    })}
                    error={!!errors?.authorName}
                    helperText={errors?.authorName?.message}
                  />
                </div>
                <div className="col-md-4">
                  {journalId ? (
                    <div>
                      {selectedFile ? (
                        <img src={selectedFile} alt="Journal Form" height="150" className="img-responsive w-100" />
                      ) : (
                        <img
                          src={`${getValues("thumbnailImage")}?t=${new Date().getTime()}`}
                          height="150"
                          alt="Journal Form"
                          className="img-responsive w-100"
                        />
                      )}
                      <TextField
                        inputProps={{
                          accept: "image/jpeg, image/png",
                        }}
                        id="thumbnailImage"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        alt="Journal Form"
                      />
                      <label
                        htmlFor="thumbnailImage"
                        style={{ marginLeft: "10px" }}
                      >
                        <Button
                          variant="contained menus-color text-colors"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                          className="mt-2"
                        >
                          Choose File
                        </Button>
                      </label>
                      {imageErrorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {imageErrorMessage}
                        </div>
                      )}
                    </div>
                  ) : (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      style={{
                        width: "100%",
                      }}
                      inputProps={{
                        accept: "image/jpeg, image/png",
                      }}
                      id="thumbnailImage"
                      label={
                        <Typography className="text-dark">
                          Thumbnail Image <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="file"
                      {...register("image", {
                        required: "Please Select an Image", onChange: (e) => handleImageChange(e)
                      })}
                      error={!!errors?.image || imageErrorMessage}
                      helperText={errors?.image?.message || imageErrorMessage}
                    />
                  )}
                </div>
                <div className="col-md-4 ">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="tags"
                    label={
                      <Typography className="text-dark">
                        Tag <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder="Example: Food or Lifestyle"
                    {...register("tags", { required: "Please Enter tags" })}
                    error={!!errors?.tags}
                    helperText={errors?.tags?.message}
                  />
                </div>

              </div>
              <div className="row row-gap-3">
                <div className="col-md-4">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="body"
                    label={
                      <Typography className="text-dark">
                        Body <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder="Enter Detailed  Body"
                    {...register("body", { required: "Please Enter Body" })}
                    error={!!errors?.body}
                    helperText={errors?.body?.message}
                    multiline
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'flex-start',
                    marginTop: 5,
                  }}
                >
                  <Button
                    variant="contained"
                    className="menus-color"
                    type="submit"
                    sx={{ mr: 3 }}
                  >
                    {journalId ? "Update" : "Submit"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
        </div>
      </>
      );
}

      export default AddEditJournal;
