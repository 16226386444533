import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import '../../../Pages/Admin/AssitantAdmin/AssistantAdmin.css';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import { useGetAssistantAdminMutation, useDeleteAssistantAdminMutation } from '../../../app/services/assistantAdmin';
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import { Box,Typography,Modal,Tooltip } from '@mui/material';
 
 
 
const AssistantAdmin = () => {
    const [getAllAssistantAdmin] = useGetAssistantAdminMutation();
    const [deleteAssistantAdmin] = useDeleteAssistantAdminMutation();
    const [adminData, setAdminData] = useState();
     const [openModal, setOpenModal] = useState(false);
 
     const handleOpenModal = (id) => {
        setOpenModal(true);
        // setSelectedMember(id);
      };
   
      const handleClose = () => {
        setOpenModal(false);
      };
 
 
    const getAlladminDataFn = useCallback(() => {
        const data = {
            role: "PORTAL_ADMIN"
        };
 
        getAllAssistantAdmin(data)
            .then((res) => {
                if (res?.data?.status) {
                    console.log("Res:", res?.data?.data);
                    setAdminData(res?.data?.data);
                }
                else {
                    setAdminData([])
                }
            })
            .catch((err) => console.log("Error fetching data:", err));
    }, [getAllAssistantAdmin])
 
 
    useEffect(() => {
        getAlladminDataFn()
    }, [getAlladminDataFn]);
 
 
    const handleDelete = (id) => {
        deleteAssistantAdmin(id).then(res => {
            if (res.data?.status) {
                toast.success('Assistant Admin Deleted Successfully!!!')
                getAlladminDataFn()
            }
            else {
                toast.error('Assistant Admin Delete Failed!!!')
            }
        })
    }
 
 
    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Assistant Admin</h2>
                </div>
            </div>
            <div className="p-3 p-md-5 overallPadding">
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <div></div>
                    {/* {adminData.length} */}
                    {adminData?.length !== 2 ?(
                    <div className="d-flex justify-content-end">
 
                        <Link
                            to={"/admin/AddEditAssistantAdmin"}
                        >
                            <Button
                                variant="contained"
                                style={{ marginRight: "35px", color: 'white' }}
                                className="justify-content-end menus-color"
                            >
                                Add Assistant Admin
                            </Button>
                        </Link>
                    </div>):""
                   }
                </div>
                {/* <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-center mt-5 mb-5'> */}
                <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-around mt-5 mb-5'>
                    {adminData?.map((data, i) => (
                        <>
                            <div className='page'>
                                <div class="notice-board page-left">
                                     <Tooltip title="Delete">
                                     <DeleteIcon style={{ color: "red",cursor: "pointer"}} className='float-end ms-2' onClick={() => handleOpenModal(data._id)} />
                                     </Tooltip>
                                    <Tooltip title="Edit"className='float-end'>
                                    <Link to={'/admin/AddEditAssistantAdmin/' + data._id}>
                                        <EditIcon style={{ color: "green" }} className='float-end' />
                                    </Link>
                                    </Tooltip>
                                 
                                    <div class="pinned-card">
                                        <div class="realistic-pin"></div>
                                        <h3>Assistant Admin</h3>
                                        <p><b>Name :</b> {data.name}</p>
                                        <p><b>Email :</b> {data.email}</p>
                                        <p><b>Mobile No:</b> {data.phone}</p>
                                    </div>
                                    <b className='text-center page-number'>{i + 1}</b>
                                </div>
                            </div>
                            <Modal
                      open={openModal}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
 
                        className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 400,
                          backgroundColor: 'white',
                          boxShadow: 24,
                          padding: "20px",
                          outline: 'none',
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          style={{ color: "#611f1e" }}
                          variant="h6"
                          component="h2"
                        >
                          Are You Surely Want to Delete this Assistant Admin?
                        </Typography>
                        <div>
                          <Button
                            variant="outlined"
                            style={{
                              color: "#2e7d32",
                              border: "1px solid rgba(46, 125, 50, 0.5)",
                              padding: "5px 15px",
                              borderRadius: "4px",
                              cursor: "pointer"
                            }}
                            onClick={handleClose}
                          >
                            No
                          </Button>
                          <Button
                            className="mx-2"
                            variant="contained"
                            style={{
                              backgroundColor: "#dc3545",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "4px",
                              cursor: "pointer"
                            }}
                            onClick={() => handleDelete(data._id)}
                          >
                            Yes
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                        </>
 
                    ))
                    }
 
                </div>
            </div>
 
        </>
    )
}
 
export default AssistantAdmin;