import React, { useState } from "react";
import "../style/css/event.css";
import {
  useGetAllEventsPaginationMutation
} from "../app/services/eventService";
import { useSelector } from "react-redux";
import { selectCurrentScope } from "../app/auth/authSlice";
import { selectCurrentMember } from "../app/features/memberSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../Component/spinner/spinner";
import ReactPaginate from "react-paginate";
import { Button } from "@mui/material";

function EventFeed() {
  const [loading, setLoading] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isDisplay, setDisplay] = useState(false);
  const currentScope = useSelector(selectCurrentScope);
  const user = useSelector(selectCurrentMember);
  const navigate = useNavigate();
  let [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 5;
  const [page, setPage] = useState(0);
  const [getAllEvents] = useGetAllEventsPaginationMutation();

  //register 
  const registeredBtn = (event) => {
    const membersId = event?.membersId;
    const memberRegister = membersId?.filter((id) => id === user?._id);
    if (memberRegister?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // on click the event have to route to the view event page
  const viewevent = (eventId) => {
    if (currentScope === "PORTAL_SUPER_ADMIN") {
      navigate("/admin/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_CHAPTER_ADMIN") {
      navigate("/chapterAdmin/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_SIG_ADMIN") {
      navigate("/sigAdmin/VieweventFeed/" + eventId);
    } else if (currentScope === "PORTAL_MEMBER") {
      navigate("/member/VieweventFeed/" + eventId);
    } else if (currentScope === undefined || currentScope === null) {
      const event = selectedEvents.find(event => event._id === eventId);
      if (event.isPublic === true || event.isEnableRegistration === false) {
        navigate("/VieweventFeed/" + eventId);
      } else if (event.isPublic === false && event.isEnableRegistration === true) {
        window.open('https://forms.gle/kthcZi1BQXjWtE5DA', '_blank');
      }
    }
  };

  //getAll event
  React.useEffect(() => {
    setLoading(true);
    getAllEvents({ page: page + 1, itemsPerPage: rowsPerPage, role: (currentScope) ? currentScope : "" }).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setDisplay(true);
        const approvedEvents = res?.data?.data.eventDetails.filter((item) => item.isApproved === 1) || [];
        if (approvedEvents.length === 0 && page > 0) {
          setPage(page - 1);
        }
        setSelectedEvents(approvedEvents);
        setTotalCount(res?.data?.data.totalCount || 0);
      } else {
        setLoading(false);
        setDisplay(false);
        setSelectedEvents([]);
        setTotalCount(0);
      }
    });
  }, [page, rowsPerPage, getAllEvents, currentScope]);

  const formatTime = (timeString) => {
    const [hour] = timeString.split(':');
    let hourNum = parseInt(hour);

    if (hourNum > 12) hourNum -= 12;
    if (hourNum === 0) hourNum = 12;

    return `${hourNum}`;
  };

  //handle page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  return (
    <div className="mb-5">
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="pt-5">
              <div className="chapter-heading">
                <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Upcoming Events</h2>
              </div>
            </div>
            <div className="p-md-5 p-3  overallPadding">
            <div className="d-flex justify-content-end mt-3">
              <Button
                onClick={() => window.history.back()}
                variant="contained"
                className="menus-color"
                style={{ float: "right", marginRight: 58 ,borderRadius:0}}
              >
                Back
              </Button>
            </div>
            <div className="mt-5 mb-5 upcoming-class-div">
              <div style={{ width: "100%" }}>
                {selectedEvents.map((event, index) => (
                  <div className="row align-items-center" key={index} onClick={() => viewevent(event._id)} style={{ cursor: "pointer" }}>
                    <div className="py-lg-3 pt-sm-2 col-lg-3 col-md-3 col-12 text-center">
                      <p className="upcoming-class-p">
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short' }).format(new Date(event?.startDate))}{" "}
                        -{" "}
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short' }).format(new Date(event?.endDate))}
                      </p>
                      {event.startTime && (
                        <p className="upcoming-class-p-1">
                          {formatTime(event.startTime)} {event.startTime.includes('AM') ? 'am' : 'pm'} - {formatTime(event.endTime)} {event.endTime.includes('AM') ? 'am' : 'pm'}
                        </p>
                      )}
                      <div className="mt-4 upcoming-class-img-div">
                        <img
                          src={event.imageUrl}
                          alt="eventfeed"
                          style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-center">
                      <div className="vertical-line"></div>
                    </div>
                    <div className="py-lg-3 col-lg-8 col-md-8  col-11 upcoming-class-div-1">
                      <p className="upcoming-class-p-2">{event.title}</p>
                      <p className="upcoming-class-p-3">{event.description}</p>
                      {currentScope === "PORTAL_MEMBER" && registeredBtn(event) && (
                        <span
                          class="upcoming-class-button"
                        >
                          Joined
                          <span class="visually-hidden">Joined</span>
                        </span>
                      )}
                      {(currentScope === undefined || currentScope === null) &&
                        (event.isPublic === false && event.isEnableRegistration === true) && (
                          <a href="https://forms.gle/kthcZi1BQXjWtE5DA" target='blank' ><span className='upcoming-class-button'>Register</span></a>
                        )}
                    </div>
                    <hr className="upcoming-horizontal-line" />
                  </div>
                ))}
              </div>
            </div>
            </div>
            {!isDisplay && (
              <h3 className="d-flex justify-content-center text-danger mt-5">
                No Data Available
              </h3>
            )}
          </>
        )}
        {selectedEvents.length > 0 ? (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={rowsPerPage}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={page > 0 ? "Previous" : null}
            nextLabel={page + 1 < pageCount ? "Next" : null}
            forcePage={page}
            disabledClassName={"disablebtn"}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default EventFeed;
