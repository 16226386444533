import React from "react";
import logo from "../assets/images/logos/ELTA@I.svg";
import logoWord from '../assets/images/logos/ELTAI.png'
import tree from "../assets/images/logos/Tree.png";
import atLogo from "../assets/images/logos/atLogo.png";
import Button from "@mui/material/Button";
const OurLogo = () => {
  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center alata-bold text-light py-3 mt-1">
            Our Logo
          </h2>
        </div>
      </div>
      <div className="p-md-5 p-3 overallPadding">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="alata-medium menus-green-color">
            ROOTED IN TRADITION, GROWING TOWARDS TOMORROW
          </h2>
          <h5 className="alata-medium menus-green-color">
            The Philosophy Behind the New Logo of ELTAI
          </h5>
          <div className="col-lg-5 col-md-6 col-sm-12 mt-3">
            <img src={logo} alt="Our Logo" className="img-fluid w-100" />
          </div>
          <div className="col-lg-4 col-md-5 col-sm-10 mt-3">
            <p className="lead poppins-medium text-black justified">
              Our logo is more than a visual identifier;
              <br />
              it encapsulates the values, mission, and vision of the English
              Language Teachers’ Association of India (ELTAI).
            </p>
          </div>
        </div>
        <div className="row ms-1 p-md-4 p-lg-4 p-2 border-green-color w-100 px-md-5 px-lg-5 px-2">
          <div className="col-md-6 p-3">
            <p className="lead poppins-medium text-black justified">
              The new logo of ELTAI was unveiled by Dr. Ranoj Pegu, the
              Honourable Minister of Education, Assam, at the 18th International
              and 54th Annual Conference in Guwahati, Assam on 23 October 2024.
              This rebranding reflects ELTAI’s unwavering commitment to growth,
              modernisation, and its mission to empower English language and
              literature teachers across India. A heartfelt thanks to Dr. K.
              Elango for the inspiring metaphor of the banyan tree with its prop
              roots, and to Dr.M. S. Xavier Pradheep Singh for his creative
              vision in modernising this imagery.
            </p>
          </div>
          <div className="col-md-6 text-center p-3">
            <iframe
              className="w-100"
              src="https://www.youtube.com/embed/Fnl-kacu0zA?si=B6MeGPDjuPjGNceC?autoplay=1"
              title="ELTAI Logo Animation Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
            ></iframe>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-12">
            <h5 className="alata-semibold menus-green-color">
              The Indian Banyan Tree: Strength, Stability, and Nurturing Growth
            </h5>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img
              src={tree}
              className="img-fluid w-100"
              alt="Indian Baniyan Tree"
            />
          </div>
          <div className="col-md-8">
            <p className="lead poppins-medium text-black justified">
              At the heart of our logo stands the Indian Banyan Tree, a powerful
              symbol of enduring strength, resilience, growth, and
              interconnectedness. Known for its expansive canopy and
              deep-reaching prop roots, the banyan tree mirrors ELTAI’s role in
              supporting and connecting English language educators across India.
              Just as the banyan tree provides shelter and sustenance, ELTAI
              offers a nurturing network that fosters growth and sustains
              educational excellence. The cascading branches represent the
              spread of knowledge, reaching teachers and learners across diverse
              linguistic and cultural landscapes, embracing the vibrant
              multiplicity of India. <br />
              <br />
              Over the past 55 years, ELTAI has transformed from a small group
              of regional educators into a nationwide movement, now encompassing
              5,500 active members, over 70 chapters across the country, 100+
              collaborating institutions, and partnerships with 12 international
              Teacher Associations. Like the banyan tree’s prop roots, which
              extend far and wide to provide stability and nourishment, ELTAI’s
              chapters reach into communities throughout India, offering
              support, resources, and opportunities to educators from diverse
              linguistic and cultural backgrounds. This remarkable expansion
              symbolises the steady growth of the association—a vast canopy of
              knowledge, sheltering and nurturing educators across the nation.
            </p>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-12">
            <h5 className="alata-semibold menus-green-color">
              The “@” Symbol: Embracing Modernity and Connectivity
            </h5>
          </div>
          <div className="col-md-10 col-12">
            <p className="lead poppins-medium text-black justified">
              At the core of ELTAI, the “@” symbol reflects our commitment to
              bridging traditional teaching practices with the modern, digital
              world. It signifies connectivity, inclusivity, and our embrace of
              technology, enabling teachers to exchange ideas, access resources,
              and foster connections in the digital age. In today’s
              interconnected world, the “@” becomes a beacon of our evolving
              approach, linking educators within India and across international
              borders.
            </p>
          </div>
          <div className="col-md-2 col-12 d-flex justify-content-center align-items-start">
            <img src={atLogo} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="row p-4">
          <h5 className="alata-semibold menus-green-color">
            A Harmonious Colour Palette
          </h5>
          <div className="col-md-3 col-12">
            <div className="row gap-md-4 gap-3 mb-2">
              <div className="col-4 green-box"></div>
              <div className="col-4 red-box"></div>
              <div className="col-4 orange-box"></div>
            </div>
          </div>
          <div className="col-md-9 col-12">
            <p className="lead poppins-medium text-black justified">
              The logo’s colours—rich green and warm red—reflect the spirit and
              energy of our association. Green, the colour of growth and
              harmony, represents ELTAI’s dedication to cultivating learning
              communities and fostering professional development. The red in the
              tree roots and text symbolizes passion, vitality, and commitment
              to our mission of transforming English language teaching in India.
            </p>
          </div>
        </div>
        <div className="row">
          <h5 className="alata-semibold menus-green-color mb-5 mt-2">
            Bold Typography and Structured Design: Strength and Unity
          </h5>
          <div className="col-md-10 col-12">
            <p className="lead poppins-medium text-black justified">
              The bold, traditional font used for “ELTAI” conveys authority,
              professionalism, and reliability—qualities that define our
              association’s national standing. Framed within a structured
              border, the logo represents unity and organisation, underscoring
              our commitment to guiding, supporting, and uplifting English
              language and literature teachers.
            </p>
          </div>
          <div className="col-md-2 col-12">
            <img src={logoWord} alt="ELTAI LOGO" className="w-100 img-fluid" />
          </div>
        </div>
        <div className="row text-center mb-3 mt-3">
          <div className="">
            <a href={logo} download>
              <Button variant="contained" className="menus-green-btn rounded-0 poppins-medium px-4 py-3 text-capitalize">
                Download Our Logo
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurLogo;
