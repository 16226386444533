import React from 'react'
import '../style/css/officeBearers.css'
import TopHeader from "../Component/Header/TopHeader";
import officebearer1 from '../../src/assets/images/Eltai-bearer/office/Sanjay.png'
import officebearer2 from '../../src/assets/images/Eltai-bearer/office/Dr.png'
import officebearer3 from '../../src/assets/images/Eltai-bearer/office/Dr-G-Reddi-Sekhar-Reddy.png'
import officebearer4 from '../../src/assets/images/Eltai-bearer/office/Elango-2 1.png'
import officebearer5 from '../../src/assets/images/Eltai-bearer/office/Dr-Ram-B-Bhise.png'
import officebearer6 from '../../src/assets/images/Eltai-bearer/office/Prakash-R-H.png'
import officebearer7 from '../../src/assets/images/Eltai-bearer/office/Kesavulu.png'
import officebearer8 from '../../src/assets/images/Eltai-bearer/office/mangaiyarkarasi 1.png'



const PresentOfficeBearers = () => {
  //image Contained
  const bearersData = [
    { id: "1", img: officebearer1, name: "Prof (Dr) Sanjay Arora", position: "National President,ELTAI" },
    { id: "2", img: officebearer2, name: "Prof (Dr) Shravan Kumar", position: "National Vice President,ELTAI" },
    { id: "3", img: officebearer3, name: "Prof (Dr) G. Reddi Sekhar Reddy", position: "National Vice President,ELTAI" },
    { id: "4", img: officebearer4, name: "Prof (Dr) K. Elango", position: "National Secretary,ELTAI" },
    { id: "5", img: officebearer5, name: "Prof (Dr) Ramkishan Bhise", position: "National Joint Secretary,ELTAI" },
    { id: "6", img: officebearer6, name: "Mr. Prakash R. H.", position: "National Joint Secretary,ELTAI" },
    { id: "7", img: officebearer7, name: "Mr. P.R. Kesavulu", position: "Treasurer,ELTAI" },
    { id: "8", img: officebearer8, name: "Prof (Dr) J. Mangayarkarasi", position: "Coordinator,ELTAI" },
  ];

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Present Office Bearers</h2>
        </div>
      </div>
      <div className='container-fulid p-md-5 p-3  overallPadding '>
        <div className='row '>
          {bearersData.map((bearers, index) => (
            <div key={index} className='col-lg-3 col-md-6 col-sm-12 mt-5 mb-5'>
              <div className='card_img_bearers'>
                <div className='row card_background mt-5 ' >
                  <div className='card card_barrer border-white'  >
                    <div className="card-img-overlay cardoverlay" >
                      <img src={bearers.img} className='office_card' alt='' />
                    </div>
                  </div>
                </div>
                <div className='card_bearers' style={{ backgroundColor: 'white' }}>
                  <p className='p_bearers'>{bearers.name}</p>
                  <p className='text-center positiontxt mb-2 mt-2' style={{ color: 'white' }}>{bearers.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PresentOfficeBearers
