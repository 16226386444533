import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";
import { toast } from "react-toastify";
import placeholder from "../../assets/images/placeholder/placeholder.png";
import {
  useLazyGetSpotlightByIdQuery,
  useSaveSpotlightMutation,
  useUpdateSpotlightByIdMutation,
} from "../../app/services/spotlightService";
import { useSelector } from "react-redux";
import { selectCurrentScope } from "../../app/auth/authSlice";

const SpotlightForm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      heading: "",
      content: "",
      image: "",
    },
    mode: "onChange",
  });
  const [saveSpotlight] = useSaveSpotlightMutation();
  const [updateSpotlight] = useUpdateSpotlightByIdMutation();
  const [getSpotlightById] = useLazyGetSpotlightByIdQuery();
  const navigate = useNavigate();
  const [selectedDesktopImage, setSelectedDesktopImage] = useState();
  const memberScope = useSelector(selectCurrentScope);

  //get Testimonial By Id
  useEffect(() => {
    if (id) {
      setLoading(true);
      getSpotlightById(id).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          const data = res.data?.data;
          if (data?.image.length > 0) {
            // Add a timestamp to the URL to force reload (cache-busting)
            const cacheBustedUrl = `${data?.image}?t=${new Date().getTime()}`;
            setValue("image", cacheBustedUrl);
          } else {
            setValue("image", "");
          }
          setValue("heading", data.heading);
          setValue("content", data.content);
        } else {
          setLoading(false);
        }
      });
    }
  }, [id, getSpotlightById, setValue]);

  //Event image handling
  const handleDesktopImageChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedDesktopImage(null);
      setError("desktopImage", { message: "Please select a image file" });
      setValue("image", "");
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedDesktopImage(null);
      setError("desktopImage", { message: "File size exceed 2 mb" });
      setValue("image", "");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          //   if (
          //     img.width < 1910 ||
          //     img.width > 1930 ||
          //     img.height < 615 ||
          //     img.height > 625
          //   ) {
          //     setSelectedDesktopImage(null);
          //     setValue("image", "");
          //     setError("desktopImage", {
          //       message:
          //         "Image dimensions must be 1910 X 615 to 1930 X 625 pixels",
          //     });
          //     return;
          //   }
          // Image is valid
          let base64String = e.target.result;
          setSelectedDesktopImage(reader.result);
          setValue("image", base64String);
          clearErrors("desktopImage");
        };
      };
      reader.readAsDataURL(file);
    }
  };

  //submit& update
  const onFormSubmit = (formData) => {
    if (isValid) {
      setLoading(true);
      if (id) {
        let updateData;
        const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
        if (base64Regex.test(formData.imageUrl)) {
          updateData = { id: id, formData };
        } else {
          updateData = {
            id: id,
            formData: formData,
          };
        }
        updateSpotlight(updateData).then((res) => {
          if (res.data?.status) {
            toast.success("Spotlight Updated Successfully!!!");
            setLoading(false);
            memberScope === "PORTAL_SUPER_ADMIN"
              ? navigate("/admin/spotlight-table")
              : navigate("/assistantadmin/spotlight-table");
            reset();
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message);
          }
        });
      } else {
        saveSpotlight(formData).then((res) => {
          if (res.data?.status) {
            toast.success("Spotlight Added Successfully!!!");
            setLoading(false);
            memberScope === "PORTAL_SUPER_ADMIN"
              ? navigate("/admin/spotlight-table")
              : navigate("/assistantadmin/spotlight-table");
            reset();
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message);
          }
        });
      }
    }
  };
  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">
            {id ? "Edit Spotlight" : "Add Spotlight"}
          </h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          component={"form"}
          className="d-flex flex-column align-items-center p-md-5 p-3 overallPadding "
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className="text-end w-100">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color mb-3"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
          {/* {id ? (
            <>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item md={5}>
                  <div className="d-flex flex-column justify-content-center w-100 align-items-center h-100 row-gap-3">
                    <img
                      src={
                        selectedDesktopImage
                          ? selectedDesktopImage
                          : watch("image")
                          ? watch("image")
                          : placeholder
                      } // Fetched image from backend with cache busting
                      alt="Spotlight Img"
                      className="img-fluid w-75 mb-4"
                      style={{
                        objectFit: "scale-down",
                        aspectRatio: "4/1",
                      }}
                    />
                    <TextField
                      sx={{ mt: 2 }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: "image/jpeg, image/png" }}
                      id="image"
                      label={
                        <Typography className="text-dark">
                          Image <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      style={{ display: "none" }}
                      type="file"
                      {...register(`desktopImage`, {
                        required: {
                          value: !watch(`image`),
                          message: "Please select a image",
                        },
                        validate: (value) => {
                          const file = value[0];
                          if (!watch(`image`)) {
                            if (!file || !file.type.startsWith("image/")) {
                              setValue(`image`, "");
                              return "Please select a image file";
                            }
                            if (file && file.size > 2 * 1024 * 1024) {
                              setValue(`image`, "");
                              return "File size exceeds 2mb";
                            }
                            return true;
                          }
                        },
                        onChange: (e) => handleDesktopImageChange(e),
                      })}
                    />
                    {!!errors?.desktopImage && (
                      <p className="error-message mb-0">
                        {errors?.desktopImage?.message}
                      </p>
                    )}
                    <div className="text-center">
                      <label htmlFor="image">
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose Image <span style={{ color: "white" }}>*</span>
                        </Button>
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={7}
                  className="d-flex gap-3 flex-column align-items-center justify-content-center"
                >
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "100%" }}
                    id="heading"
                    label={
                      <Typography className="text-dark">
                        Heading <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    variant="outlined"
                    placeholder="Please enter heading"
                    {...register("heading", {
                      required: "Please enter heading",
                    })}
                    error={!!errors?.heading}
                    helperText={errors?.heading?.message}
                  />
                  <TextField
                    InputLabelProps={{ shrink: "true" }}
                    id="content"
                    label={
                      <Typography className="text-dark">
                        Content <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    placeholder="Please enter content"
                    style={{ height: "max-content" }}
                    {...register("content", {
                      required: "Please enter content",
                    })}
                    className="form-control"
                    multiline
                    error={!!errors?.content}
                    helperText={errors?.content?.message}
                  ></TextField>
                </Grid>
              </Grid>
            </>
          ) : ( */}
          <Grid container rowSpacing={3} columnSpacing={3}>

            {
              id ? <>
                <Grid item lg={4}>
                  <img
                    src={
                      selectedDesktopImage
                        ? selectedDesktopImage
                        : watch("image")
                          ? watch("image")
                          : placeholder
                    } // Fetched image from backend with cache busting
                    alt="Spotlight Img"
                    className="img-fluid w-75 mb-4"
                    style={{
                      objectFit: "scale-down",
                      aspectRatio: "4/1",
                    }}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: "image/jpeg, image/png" }}
                    id="image"
                    label={
                      <Typography className="text-dark">
                        Image <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    style={{ display: "none" }}
                    type="file"
                    {...register(`desktopImage`, {
                      required: {
                        value: !watch(`image`),
                        message: "Please select a image",
                      },
                      validate: (value) => {
                        const file = value[0];
                        if (!watch(`image`)) {
                          if (!file || !file.type.startsWith("image/")) {
                            setValue(`image`, "");
                            return "Please select a image file";
                          }
                          if (file && file.size > 2 * 1024 * 1024) {
                            setValue(`image`, "");
                            return "File size exceeds 2mb";
                          }
                          return true;
                        }
                      },
                      onChange: (e) => handleDesktopImageChange(e),
                    })}
                  />
                  {!!errors?.desktopImage && (
                    <p className="error-message mb-0">
                      {errors?.desktopImage?.message}
                    </p>
                  )}
                  <div className="text-center">
                    <label htmlFor="image">
                      <Button
                        variant="contained"
                        className="menus-color"
                        component="span"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Choose Image <span style={{ color: "white" }}>*</span>
                      </Button>
                    </label>
                  </div>
                </Grid>
              </> : <>
                <Grid item lg={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: "image/jpeg, image/png" }}
                    className="w-100"
                    id="image"
                    error={!!errors?.desktopImage}
                    helperText={errors?.desktopImage?.message}
                    label={
                      <Typography className="text-dark">
                        Image <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    type="file"
                    {...register(`desktopImage`, {
                      required: {
                        value: !watch(`image`),
                        message: "Please select a image",
                      },
                      validate: (value) => {
                        const file = value[0];
                        if (!watch(`image`)) {
                          if (!file || !file.type.startsWith("image/")) {
                            setValue(`image`, "");
                            return "Please select a image file";
                          }
                          if (file && file.size > 2 * 1024 * 1024) {
                            setValue(`image`, "");
                            return "File size exceeds 2mb";
                          }
                          return true;
                        }
                      },
                      onChange: (e) => handleDesktopImageChange(e),
                    })}
                  />
                </Grid>
              </>
            }
            <Grid item lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                id="heading"
                label={
                  <Typography className="text-dark">
                    Heading <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                placeholder="Please enter heading"
                {...register("heading", {
                  required: "Please enter heading",
                })}
                error={!!errors?.heading}
                helperText={errors?.heading?.message}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                InputLabelProps={{ shrink: "true" }}
                id="content"
                label={
                  <Typography className="text-dark">
                    Content <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                placeholder="Please enter content"
                style={{ height: "max-content" }}
                {...register("content", {
                  required: "Please enter content",
                })}
                className="form-control"
                multiline
                error={!!errors?.content}
                helperText={errors?.content?.message}
              ></TextField>
            </Grid>
          </Grid>
          {/* )} */}
          <>
            <Button
              variant="contained"
              className="menus-color mt-5"
              type="submit"
            >
              {id ? "Update" : "Submit"}
            </Button>
          </>
        </Box>
      )}
    </>
  );
};

export default SpotlightForm;
