import { api } from "./api";

const bannerServices = api.injectEndpoints({
  endpoints: (builder) => ({
     //save testimonial
     saveBanner: builder.mutation({
      query: (formData) => ({
        url: "banner/saveBanner",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags:["banner"]
    }),

    //get Testimonial pagination
    getAllBannerPagination:builder.mutation({
      query: (data) => ({
        url: "banner/getAllBannerPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
    
    //get testimonial Details by Id
    getBannerById: builder.query({
      query: (id) => "banner/get/" + id,
      invalidatesTags: ["banner"],
    }),

    //Update testimonial
    updateBannerById: builder.mutation({
      query: (data) => ({
        url: "banner/update/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags:['banner']
    }),

    //deleteTestmonial
    deleteBannerById: builder.mutation({
        query: (id) => ({
          url: "banner/delete/"+ id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }),
        invalidatesTags:['banner']
      }),
    //deleteTestmonial
    enableDisableBannerById: builder.mutation({
        query: (id) => ({
          url: "banner/enableDisable/"+ id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }),
        invalidatesTags:['banner']
      }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBannerPaginationMutation,
  useDeleteBannerByIdMutation,
  useLazyGetBannerByIdQuery,
  useSaveBannerMutation,
  useUpdateBannerByIdMutation,
  useEnableDisableBannerByIdMutation,
} = bannerServices;
