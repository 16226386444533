import TopHeader from "../../Component/Header/TopHeader";
import { useFieldArray, useForm } from "react-hook-form";
import React, { useCallback, useEffect, useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useRemoveAdminMutation, useSaveChapterMutation } from "../../app/services/chapterServices";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetChapterDetailsByIdQuery } from "../../app/services/chapterServices";
import { useUpdateChapterMutation } from "../../app/services/chapterServices";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { Typography } from "@mui/material";
import { Controller } from 'react-hook-form';
import placeholder from '../../assets/images/placeholder/placeholder.png'

const ChapterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //route
  const [initialData, setInitalData] = useState([]);
  const [adminChecked, setAdminChecked] = useState([])
  const [removeMemberAdmin] = useRemoveAdminMutation();
  const stateOption = [
    "Jammu & Kashmir",
    "Himachal Pradesh",
    "Punjab",
    "Uttarakhand",
    "Haryana",
    "Uttar Pradesh",
    "Rajasthan",
    "Bihar",
    "Sikkim",
    "West Bengal",
    "Assam",
    "Meghalaya",
    "Mizoram",
    "Tripura",
    "Manipur",
    "Arunanchal Pradesh",
    "Nagaland",
    "Jharkhand",
    "Madhya Pradesh",
    "Gujarat",
    "Chhattisgarh",
    "Odisha",
    "Maharashtra",
    "Goa",
    "Telangana",
    "Karnataka",
    "Andhra Pradesh",
    "Kerala",
    "Tamil Nadu",
  ]; //option for state
  stateOption.sort(); //sort the option

  //chapterForm Initialization
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange', // Validate on form submission initially
    reValidateMode: 'onChange',
    defaultValues: {
      title: "",
      region: "",
      state: "",
      country: "India",
      mission: "",
      isPublic: false,
      imageUrl: "",
      description: "",
      roleDetails: [],
      removedAdminEmail: [],
      adminsId: []
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roleDetails",
  });

  // Adding a new empty object to roleDetails array when "Add Office Bearers" button is clicked
  const addRoleDetails = () => {
    append({
      name: "",
      address: "",
      mobile: "",
      email: "",
      role: "",
      roleImageUrl: "",
      isAdmin: false,
      password: "",
      isNew: true
    });
  };

  // Removing Roledetails for each row except the first one
  const removeRoleDetails = (index) => {
    setValue(`roleDetails.${index}.isAdmin`, false);
    const isAdmin = getValues(`roleDetails.${index}.isAdmin`)
    const roleDetail = getValues(`roleDetails.${index}`)
    if (isAdmin) {
      removeAdmin(roleDetail, index)
    }
    else {
      remove(index);
    }
  };

  //Remove admin
  const removeAdmin = (member, index) => {
    const data = {
      chapterId,
      roleId: member._id,
      email: member.email,
    };
    setLoading(true);
    removeMemberAdmin(data).then((res) => {
      if (res.data?.data) {
        remove(index);
        setLoading(false);
        toast.success(res.data?.message);
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  const [saveChapter] = useSaveChapterMutation(); //calling save API service
  const { chapterId } = useParams(); //get Id from params
  const [chapterDetailById] = useLazyGetChapterDetailsByIdQuery(); //calling getby chapter id API service
  const [chapterData, setChapterData] = useState(null); //state for get id data
  const [updateChapter] = useUpdateChapterMutation(); //API for Update
  const [selectedImage, setSelectedImage] = useState();
  const fileInputRefImage = useRef(null);
  const [selectedRoleImage, setSelectedRoleImage] = useState([]);

  //get by id
  const fetchChapterData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await chapterDetailById(chapterId);
      if (response.data.status) {
        setLoading(false);
        setChapterData(response.data.data); // Update the state with the fetched data
        setInitalData(response.data?.data);
      } else {
        setLoading(false);
        toast.error(response.data?.err?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [chapterDetailById, chapterId]);
  useEffect(() => {
    if (chapterId) {
      fetchChapterData(); // Call the function to fetch chapter data
    }
  }, [fetchChapterData, chapterId]);

  // Patch form values from chapterData
  useEffect(() => {
    if (chapterData) {
      setValue("title", chapterData.title);
      setValue("region", chapterData.region);
      setValue("mission", chapterData.mission);
      setValue("description", chapterData.description);
      setValue("isPublic", chapterData.isPublic);
      setValue("state", chapterData.state);
      setValue("imageUrl", chapterData.imageUrl)
      if (chapterData.roleDetails.length !== 0) {
        setValue("roleDetails", chapterData.roleDetails);
      }
      const roleImages = chapterData.roleDetails.map(
        (item) => item.roleImageUrl
      );
      setSelectedRoleImage(roleImages);
    }
  }, [chapterData, setValue]);

  //Form submit
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    if (chapterId) {
      const formRoleDetailsIds = formData.roleDetails.map(
        (role) => role._id
      );
      const filteredData = initialData?.roleDetails?.filter((item) => {
        const isIncluded = formRoleDetailsIds.includes(item._id);
        return !isIncluded;
      });
      const isAdminStatus = filteredData.filter(
        (item) => item.isAdmin === true
      );
      if (isAdminStatus) {
        const email = isAdminStatus.map((item) => item.email);
        setValue("removedAdminEmail", email);
        setValue("adminsId", initialData.adminsId);
        formData.removedAdminEmail = watch("removedAdminEmail")
        formData.adminsId = watch("adminsId")
        const base64Regex =
          /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
        formData.imageUrl = base64Regex.test(formData.imageUrl)
          ? formData.imageUrl
          : formData?.imageUrl.split("/").slice(-2).join("/");
        formData.roleDetails = formData.roleDetails.map((item) => {
          return {
            address: item.address,
            email: item.email,
            roleImageUrl: base64Regex.test(item.roleImageUrl)
              ? item.roleImageUrl
              : item.roleImageUrl?.split("/").slice(-3).join("/"),
            isAdmin: item.isAdmin,
            mobile: item.mobile,
            name: item.name,
            password: item.password,
            role: item.role
          };
        });
        const data = { id: chapterId, formData: formData };
        try {
          const res = await updateChapter(data);
          if (res.data.status) {
            setLoading(false);
            toast.success(res.data?.message);
            navigate("/view-Chapter");
          } else {
            setLoading(false);
            toast.error(res.data?.err?.message);
          }
        } catch (error) {
          setLoading(false);
          console.error("API error:", error);
          toast.error("Failed to update chapter");
        }
      }
    }
    //Save Chapter API
    else {
      saveChapter(formData).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data?.message)
          navigate("/view-Chapter"); // Navigate after the success message disappears
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message)
        }
      });
    }
  };

  //Image handling
  const handleImageFileChange = (event) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedImage(null);
      setValue("imageUrl", "");
      setError('imageUrl', { message: 'Please Select an image file' })
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedImage(null);
      setValue(`imageUrl`, "")
      return;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue("imageUrl", base64String);
        clearErrors('imageUrl')
      };
      reader.readAsDataURL(file);
    }
  };

  //Image handling
  const handleRoleImageFileChange = (event, index) => {
    const file = event.target.files[0];
    if (!file || !file.type.startsWith("image/")) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "")
      setError(`roleDetails.${index}.roleImageUrl`, { message: 'Please select an image file' })
      return;
    }
    if (file && file.size > 2 * 1024 * 1024) {
      setSelectedRoleImage((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = null;
        return newImages;
      });
      setValue(`roleDetails.${index}.roleImageUrl`, "")
      clearErrors(`roleDetails.${index}.roleImageUrl`)
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedRoleImage((prevImages) => {
          // const newImages = [...prevImages]; // Create a copy of the array
          // newImages.splice(index, 1, reader.result); // Correctly use splice to replace the item at index
          // return newImages;
          const newImages = [...prevImages];
          newImages[index] = base64String; // Replace image at the specified index
          return newImages;
        });
        setValue(`roleDetails.${index}.roleImageUrl`, base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <TopHeader />
      {chapterId ? (
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Edit Chapter</h2>
          </div>
        </div>) : (
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Add Chapter</h2>
          </div>
        </div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <Box
          component={"chapterform"}
          className="d-flex flex-column p-md-5 p-3 overallPadding ">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch("isPublic")}
                  {...register("isPublic")}
                />
              }
              label={
                <Typography className="text-dark">
                  Public <span style={{ color: "red" }}>*</span>
                </Typography>
              }
            />
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}>
              Back
            </Button>
          </div>
          <div style={{ marginTop: "25px", marginBottom: "50px" }}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  helperText={errors?.title?.message}
                  error={!!errors?.title}
                  fullWidth
                  sx={{ width: '49%' }}
                  id="title"
                  placeholder="Enter chapter name"
                  label={
                    <Typography className="text-dark">
                      Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("title", { required: "Please Enter Title" })}
                />
                <TextField
                  style={{ width: '49%' }}
                  InputLabelProps={{ shrink: true }}
                  helperText={errors?.region?.message}
                  error={!!errors?.region}
                  id="region"
                  placeholder="Enter chapter region"
                  label={
                    <Typography className="text-dark">
                      Region <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("region", { required: "Please Enter a Region" })}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Controller rules={{ required: "Please Select a State" }} control={control} name="state" render={({ field, fieldState }) => (
                  <FormControl style={{ width: '49%' }}>
                    <InputLabel id='state-label'>
                      <span className="text-dark">State</span>
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      id="State"
                      labelId="state-label"
                      label={<span className="text-black">State <span className="text-danger">*</span></span>}
                      value={field?.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      error={!!fieldState.error}
                    >
                      {stateOption.map((states, index) => (
                        <MenuItem key={index} value={states}>
                          {states}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="text-danger">
                      {fieldState?.error?.message}
                    </FormHelperText>
                  </FormControl>
                )} />
                <TextField
                  style={{ width: '49%' }}
                  InputLabelProps={{ shrink: true }}
                  id="mission"
                   placeholder="Enter chapter mission"
                  label={
                    <Typography className="text-dark">
                      Mission <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  error={!!errors?.mission}
                  helperText={errors?.mission?.message}
                  {...register("mission", {
                    required: "Please enter mission",
                  })}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >

                <TextField
                  style={{ width: '49%' }}
                  InputLabelProps={{ shrink: true }}
                  id="description"
                   placeholder="Enter description"
                  label={
                    <Typography className="text-dark">
                      Description <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  variant="outlined"
                  {...register("description", {
                    required: "Please Enter Description",
                  })}
                />
                <div
                  style={{ width: '49%' }}
                >
                  {chapterId ? (
                    <>
                      <img
                        src={selectedImage ? selectedImage : watch("imageUrl") ? `${watch("imageUrl")}?t=${new Date().getTime()}` : placeholder} // Fetched image from backend with cache busting
                        height="100"
                        alt="chapterform"
                      />
                      <input
                        ref={fileInputRefImage}
                        accept="image/jpeg, image/png"
                        id="imageUrl"
                        type="file"
                        style={{ display: "none" }}
                        {...register('imageUrl')}
                        onChange={handleImageFileChange}
                      />
                      {errors?.imageUrl &&
                        <div className="helperText text-danger">{errors?.imageUrl?.message}</div>
                      }
                      <label
                        htmlFor="imageUrl"
                        style={{ marginLeft: "10px" }}
                      >
                        <Button
                          variant="contained"
                          className="menus-color"
                          component="span"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose Image File
                        </Button>
                      </label>
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-column w-100 justify-content-center align-items-start gap-3">
                        <img
                          src={watch("imageUrl") ? watch("imageUrl") : placeholder} // Fetched image from backend with cache busting
                          height="100"
                          alt="chapterform"
                        />
                        <input
                          ref={fileInputRefImage}
                          accept="image/jpeg, image/png"
                          id="imageUrl"
                          type="file"
                          style={{ display: "none" }}
                          {...register('imageUrl')}
                          onChange={handleImageFileChange}
                        />
                        {errors?.imageUrl &&
                          <div className="helperText text-danger">{errors?.imageUrl?.message}</div>
                        }
                        <label
                          htmlFor="imageUrl"
                        >
                          <Button
                            variant="contained"
                            className="menus-color"
                            component="span"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Choose Image File
                          </Button>
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div style={{ width: '49%' }}>
                  <Button variant="contained" className="menus-color" onClick={addRoleDetails}>
                    Add Office Bearers
                  </Button>
                </div>
              </div>
              {fields.map((details, index) => (
                <div key={details.id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: '49%' }}
                      InputLabelProps={{ shrink: true }}
                       placeholder="Enter office bearer name"
                      label={
                        <Typography className="text-dark">
                          Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.name`, {
                        required: "Please Enter a Name",
                        minLength: {
                          value: 3,
                          message: "Name must be min length of 3",
                        },
                      })}
                      error={!!errors?.roleDetails?.[index]?.name}
                      helperText={errors?.roleDetails?.[index]?.name?.message}
                    />
                    <TextField
                      style={{ width: '49%' }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Enter office bearer email"
                      label={
                        <Typography className="text-dark">
                          Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      InputProps={{
                        readOnly: !!chapterData?.roleDetails?.[index]?.email
                      }}
                      {...register(`roleDetails.${index}.email`, {
                        required: "Please Enter a Email",
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Please Enter Valid email",
                        },
                        validate: (value) => {
                          const roles = getValues('roleDetails')
                          const sameMailRoles = roles.filter(item => item.email === value)
                          if (sameMailRoles.length > 1) {
                            return 'Email ID Already Exists !!!'
                          }
                        }
                      })}
                      error={!!errors?.roleDetails?.[index]?.email}
                      helperText={errors?.roleDetails?.[index]?.email?.message}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: '49%' }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Enter office bearer mobile number"
                      label={
                        <Typography className="text-dark">
                          Mobile <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.mobile`, {
                        required: "Please Enter a Mobile Number",
                        validate: (value) => {
                          if (value.length !== 10) {
                            return "Please enter a valid 10-digit phone number";
                          }
                          // Additional validation logic if needed
                          return true; // Validation passed
                        },
                      })}
                      error={!!errors?.roleDetails?.[index]?.mobile}
                      helperText={errors?.roleDetails?.[index]?.mobile?.message}
                    />
                    <TextField
                      style={{ width: '49%' }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Enter office bearer address"
                      label={
                        <Typography className="text-dark">
                          Address <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`roleDetails.${index}.address`, {
                        required: "Please Enter Address",
                      })}
                      error={!!errors?.roleDetails?.[index]?.address}
                      helperText={errors?.roleDetails?.[index]?.address?.message}
                    />
                  </div>
                  {!chapterId ? (
                    <div >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <>
                          <TextField
                            style={{ width: '49%' }}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Enter role"
                            label={
                              <Typography className="text-dark">
                                Role <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            {...register(`roleDetails.${index}.role`, {
                              required: "Role is Required",
                            })}
                            error={!!errors?.roleDetails?.[index]?.role}
                            helperText={errors?.roleDetails?.[index]?.role?.message}
                          />
                        </>
                        <div style={{ width: '49%', display: "flex" }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{
                              width: "100%",
                            }}
                            variant="outlined"
                            ref={fileInputRefImage}
                            accept="image/jpeg, image/png"
                            id={`roleImageUrl-${index}`}
                            label="Image"
                            placeholder="Please Select a image"
                            type="file"
                            onChange={(e) => handleRoleImageFileChange(e, index)}
                          />
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", width: '49%' }}
                        className={chapterId ? "disable_checkbox" : ""}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ color: chapterId ? "grey" : "#1976d2" }}
                              checked={watch(`roleDetails.${index}.isAdmin`)}
                              {...register(`roleDetails.${index}.isAdmin`)}
                            />
                          }
                          label="Admin"
                        />
                        {(!chapterId && watch(`roleDetails.${index}.isAdmin`)) && (
                          <TextField
                            style={{ width: "93%" }}
                            InputLabelProps={{ shrink: true }}
                            label={
                              <Typography className="text-dark">
                                Password <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            InputProps={{ readOnly: chapterId ? true : false }}
                            {...register(`roleDetails.${index}.password`, {
                              required: "Please Enter a password",
                            })}
                            error={!!errors?.roleDetails?.[index]?.password}
                            helperText={
                              errors?.roleDetails?.[index]?.password?.message
                            }
                          />
                        )}
                      </div>
                    </div>
                  ) : (<>
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <TextField
                          style={{ width: '49%' }}
                          InputLabelProps={{ shrink: true }}
                          label={
                            <Typography className="text-dark">
                              Role <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          {...register(`roleDetails.${index}.role`, {
                            required: "Role is required",
                          })}
                          error={!!errors?.roleDetails?.[index]?.role}
                          helperText={errors?.roleDetails?.[index]?.role?.message}
                        />
                        <>
                          {chapterId ?
                            (
                              <>
                                <div style={{ width: '45%' }}>
                                  <img
                                    src={
                                      selectedRoleImage[index]
                                        ? selectedRoleImage[index].startsWith("data:image/")
                                          ? selectedRoleImage[index]
                                          : `${selectedRoleImage[index]}?t=${new Date().getTime()}`
                                        : watch(`roleDetails.${index}.roleImageUrl`) ? watch(`roleDetails.${index}.roleImageUrl`) : placeholder
                                    }
                                    height="100"
                                    alt={(!watch(`roleDetails.${index}.roleImageUrl`) && !watch(`roleDetails.${index}.isNew`)) ? "roleImage" : ""}
                                  />
                                  <input
                                    ref={fileInputRefImage}
                                    accept="image/jpeg, image/png"
                                    id={`roleImageUrl-${index}`}
                                    type="file"
                                    label="Image"
                                    variant="outlined"
                                    placeholder="Please Select a image"
                                    style={{ display: "none" }}
                                    onChange={(e) => handleRoleImageFileChange(e, index)}
                                  />
                                  <label
                                    htmlFor={`roleImageUrl-${index}`}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Button
                                      variant="contained"
                                      className="menus-color"
                                      component="span"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      Choose Image File
                                    </Button>
                                  </label>
                                </div>
                              </>
                            ) : (
                              <>
                                <div style={{ width: '49%', display: "flex" }}>

                                  <TextField
                                    InputLabelProps={{ shrink: true }}
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="outlined"
                                    id={`roleImageUrl-${index}`}
                                    label="Image"
                                    ref={fileInputRefImage}
                                    accept="image/jpeg, image/png"
                                    type="file"
                                    onChange={(e) => handleRoleImageFileChange(e, index)}
                                  />
                                </div>
                              </>
                            )
                          }
                        </>
                      </div>
                    </>
                  </>)}
                  <div
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: '50px' }}>
                        <div className="float-end" >
                          <Button
                            variant="contained"
                            style={{ background: "red" }}
                            onClick={() => removeRoleDetails(index)}
                            // disabled={watch(`roleDetails.${index}.isAdmin`)}
                          >
                            <DeleteIcon />
                            Remove
                          </Button>
                        </div><br />
                      </div>
                  </div>
                </div>
              ))}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" className="menus-color" type="submit">
                  {chapterId ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </Box>
      )
      }
    </div >
  );
};

export default ChapterForm;
