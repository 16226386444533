import {
  Alert,
  FormHelperText,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import Select from "@mui/material/Select";
import "../../../style/css/eventForm.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FormControl from "@mui/material/FormControl";
import { useGetChapterBasedOnStateMutation } from "../../../app/services/chapterServices";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import {
  currently_teaching,
  PrimarilyTeachingForTeacher,
  TypeOfInstitution,
  Salutation,
  planType,
} from "../../../constant/constant";
import { Country, State } from "country-state-city";
import {
  useCreateMemberMutation,
  useLazyGetMemberDetailsByIdQuery,
} from "../../../app/services/memberServices";
import {
  useLazyGetAllMemberShipDetailsQuery,
  useRemoveMemberShipPermanentlyMutation,
} from "../../../app/services/memberShipServices";
import { Link, useNavigate } from "react-router-dom";

const steps = ["Personal Information", "Payment Information"];

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  minWidth: 300,
  height: '70%',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
};

function Teacher() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [getChaptersBasedOnState] = useGetChapterBasedOnStateMutation();
  const [showPrimarilyOtherInput, setShowPrimarilyOtherInput] = useState(false);
  const [otherPrimarily, setOtherPrimarily] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [planName, setPlanName] = useState([]);
  const [costType, setCostType] = useState([]);
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [createMember] = useCreateMemberMutation();
  const [getAllplans] = useLazyGetAllMemberShipDetailsQuery();
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const [disableChapter, setDisableChapter] = useState(false);
  const countries = Country.getAllCountries();
  const [memberDetails, setMemberDetails] = useState("");
  const [open, setOpen] = useState(false);
  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry)
    : [];

  const navigator = useNavigate();
  const [deleteMember] = useRemoveMemberShipPermanentlyMutation();



  // useForm hook for form data handling
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      salutation: Salutation[0].id,
      type: 0,
      gender: "",
      address: "",
      fullName: "",
      dateOfBirth: "",
      city: "",
      pinCode: "",
      state: "",
      mobileNumber: "",
      whatsAppNumber: "",
      email: "",
      landlineNumber: "",
      teaching: "",
      currentDesignation: "",
      institution: "",
      primarilyTeachingLevel: "",
      otherprimarilyTeachingLevel: "",
      natureWork: "",
      country: "",
      chapter: [],
      role: "PORTAL_MEMBER",
    },
  });
  useEffect(() => {
    const handlePopState = () => {
      // You can check if you are on a specific route or just redirect unconditionally
      // For example, redirect to "/another" if the back button is pressed
      if (watch("paymentStatus")) {
        navigator("/");
      }
      else {
        window.history.back()
      }
    };

    window.history.pushState(null, "", window.location.href); // Adds an extra history entry
    window.onpopstate = handlePopState;

    return () => {
      window.onpopstate = null; // Clean up when the component is unmounted
    };
  }, [navigator, watch]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };
  useEffect(() => {
    getAllplans().then((res) => {
      if (res?.data?.status) {
        const filteredPlan = res?.data?.data?.find(
          (item) =>
            item.isDeleted === 0 && item.quantity === 1 && item.planName === "0"
        );
        setValue("dummyPlanId", filteredPlan?._id);
        setValue(
          "planName",
          filteredPlan
            ? planType.find((i) => i?.planId === "0")?.planName
            : "No active plan for this member type"
        );
        setCostType(filteredPlan?.planType || []);
      }
    });
  }, [getAllplans, setValue, setPlanName, setCostType]);

  const selectedplantypeId = watch("dummyPlanTypeId");

  useEffect(() => {
    if (amount && currency) {
      setValue("dummyAmount", amount);
      setValue("dummyCurrency", currency);
    }
  }, [setValue, amount, currency]);

  useEffect(() => {
    costType.forEach((data) => {
      if (data._id === selectedplantypeId) {
        const amountToSet = data.offer === 0 ? data.amount : data.offerAmount;
        setAmount(amountToSet);
        setCurrency(data.currencyType);
      }
    });
  }, [costType, selectedplantypeId]);

  useEffect(() => { }, [planName]);

  //On submit for save
  const AddEditTeacherMember = (data) => {
    if (activeStep === steps.length - 1) {
      setLoading(true);
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof FileList) {
          formData.append(key, value[0]);
        } else if (
          key !== "chapter" &&
          key !== "sigGroups" &&
          key !== "dummyAmount" &&
          key !== "dummyCurrency" &&
          key !== "otherPrimarily" &&
          key !== "primarilyTeachingLevel" &&
          key !== "otherprimarilyTeachingLevel"
        ) {
          formData.append(key, value);
        }
      });
      if (data.sigGroups && data.sigGroups.length > 0) {
        data.sigGroups.forEach((item) => {
          formData.append("sigGroups", item);
        });
      }
      formData.append("dummyAmount", amount);
      formData.append("dummyCurrency", currency);
      formData.append(
        "chapter",
        watch("chapter") === "No active Chapter on this state" ||
          watch("chapter") === "I will join a chapter later"
          ? ""
          : watch("chapter")
      );

      // Handle primarilyTeachingLevel and otherPrimarilyTeachingLevel
      const { primarilyTeachingLevel } = data;
      formData.append("primarilyTeachingLevel", primarilyTeachingLevel);

      if (showPrimarilyOtherInput && otherPrimarily) {
        formData.append("otherprimarilyTeachingLevel", otherPrimarily);
      } else {
        formData.append("otherprimarilyTeachingLevel", "");
      }

      createMember(formData).then((res) => {
        if (res?.data?.data) {
          var options = {
            key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: amount === 0 ? "INR" : "USD",
            config: {
              display: {
                hide: [
                  { method: 'paylater' },
                  { method: 'emi' },
                  { method: 'wallet' }
                ]
              }
            },
            name: res?.data?.data?.fullName,
            description: "Membership payment",
            order_id: res?.data?.data?.purchaseData?.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: function (response) {
              gettMemberDetailsByIdQuery(res?.data?.data?._id).then((res) => {
                if (res.data?.status) {
                  const isPaid = res.data?.data?.plans[0]?.isPaid === 1;
                  setMemberDetails(res?.data?.data);
                  setValue("paymentStatus", isPaid);
                  toast.success("Member Saved Successfully");
                  handleOpen();
                }
              });
            },
            modal: {
              ondismiss: function () {
                // Handle modal dismissal (user exits without payment)
                handlePaymentCancelled(res?.data?.data?._id); // Call your custom function
              },
            },
          };
          var rzp1 = new window.Razorpay(options);
          setLoading(false);
          rzp1.open();
          rzp1.on("payment.failed", function (response) {
            toast.error("Payment Failed Try Again");
          });
        } else {
          setLoading(false);
          toast.error(res.error.data.error);
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function handlePaymentCancelled(id) {
    toast.info("Payment was not completed.");
    deleteMember(id)
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  const state = watch("state");
  //Get all chapter
  const getChapters = useCallback(() => {
    const data = {
      state: watch("state"),
      page: "",
      itemsPerPage: "",
    };
    getChaptersBasedOnState(data).then((res) => {
      setSelectedChapters(
        res?.data?.data?.chapterData || [
          {
            title: "No active Chapter on this state",
            _id: "No active Chapter on this state",
          },
        ]
      );
      if (!res?.data?.data?.chapterData) {
        setValue("chapter", "No active Chapter on this state");
        setDisableChapter(true);
      } else {
        setDisableChapter(false);
      }
    });
  }, [watch, getChaptersBasedOnState, setValue]);
  React.useEffect(() => {
    getChapters();
  }, [state, getChapters]);

  //handle Back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Watch the teaching field value
  const isTeaching = useWatch({
    control: control,
    name: "teaching",
    defaultValue: "",
  });

  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">
            Individual Teacher Membership
          </h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box className="p-md-5 p-3 overallPadding">
          <div className="text-end w-100">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color rounded-0"
            >
              Go back to plans page
            </Button>
          </div>
          <Stepper activeStep={activeStep} className="mb-5 mt-3">
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <form className="w-100" onSubmit={handleSubmit(AddEditTeacherMember)}>
            {activeStep === 0 && (
              <Box>
                <Grid container rowSpacing={4} columnSpacing={5}>
                  <Grid item lg={4} md={6} xs={12}>
                    <Controller
                      control={control}
                      rules={{ required: "Please select an option" }}
                      name="salutation"
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel className="text-dark" id="Salutation">
                            Salutation<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            className="bg-white"
                            labelId="Salutation"
                            id="Salutation"
                            label={
                              <Typography className="text-dark">
                                Salutation
                                <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value === "" ? "" : field.value}
                            variant="outlined"
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!fieldState.error}
                          >
                            {Salutation.map((data) => (
                              <MenuItem key={data.Name} value={data.id}>
                                {data.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your Full Name<span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("fullName", {
                        required: "Please enter a full name",
                        minLength: {
                          value: 3,
                          message: "Please enter a valid name",
                        },
                      })}
                      error={!!errors?.fullName}
                      helperText={errors?.fullName?.message}
                      placeholder="Your Full Name"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your Date of Birth
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="date"
                      value={
                        watch("dateOfBirth")
                          ? watch("dateOfBirth").split("T")[0]
                          : ""
                      }
                      {...register("dateOfBirth", {
                        required: "Please select your date of birth",
                        max: {
                          value: new Date().toISOString().split("T")[0],
                          message: "Future date is not allowed",
                        },
                      })}
                      error={!!errors?.dateOfBirth}
                      helperText={errors?.dateOfBirth?.message}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <FormControl component="fieldset" error={!!errors.gender}>
                      <FormLabel className="text-dark" component="legend">
                        Your Gender<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select your gender" }}
                        render={({ field }) => (
                          <RadioGroup row {...field}>
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio />}
                              label="Transgender"
                            />
                          </RadioGroup>
                        )}
                      />
                      {errors.gender && (
                        <FormHelperText>{errors.gender.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your Address <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("address", {
                        required: "Please enter a address",
                      })}
                      error={!!errors?.address}
                      helperText={errors?.address?.message}
                      placeholder="Your Address"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your City / Town
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("city", {
                        required: "Please enter a  city / town",
                      })}
                      error={!!errors?.city}
                      helperText={errors?.city?.message}
                      placeholder="Your City / Town"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Pin Code <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("pinCode", {
                        required: "Please enter a pin code",
                        pattern: {
                          value: /^\d{6}$/,
                          message: "Pincode must be 6 digits",
                        },
                      })}
                      error={!!errors?.pinCode}
                      helperText={errors?.pinCode?.message}
                      placeholder="Pin Code"
                    />
                  </Grid>

                  <Grid item lg={4} md={6} xs={12}>
                    <FormControl
                      fullWidth
                      className="mt-0"
                      error={!!errors.country}
                    >
                      <InputLabel className="text-dark">
                        Country <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Please select a country", // Validation rule
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="bg-white"
                            label={
                              <Typography className="text-dark">
                                Country <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={selectedCountry}
                            onChange={(e) => {
                              setSelectedCountry(e.target.value);
                              field.onChange(e);
                              setValue("state", "");
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Country</em>
                            </MenuItem>
                            {countries.map((country) => (
                              <MenuItem
                                key={country.isoCode}
                                value={country.isoCode}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.country && (
                        <FormHelperText>
                          {errors.country.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={6} xs={12}>
                    <FormControl
                      fullWidth
                      disabled={!watch("country")}
                      error={!!errors.state}
                    >
                      <InputLabel className="text-dark">
                        State<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: watch("country") && "Please select a state", // Only required if a country is selected
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label={
                              <Typography className="text-dark">
                                State <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            className={
                              !watch("country") && errors.state
                                ? "border border-danger bg-white"
                                : "bg-white"
                            }
                            readOnly={!selectedCountry}
                            value={selectedState}
                            onChange={(e) => {
                              setSelectedState(e.target.value);
                              field.onChange(e);
                            }}
                          >
                            <MenuItem value="">
                              <em>Select State</em>
                            </MenuItem>
                            {states.map((state) => (
                              <MenuItem key={state.isoCode} value={state.name}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.state && (
                        <FormHelperText>{errors.state.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          District (If Any)
                        </Typography>
                      }
                      {...register("district",)}
                      placeholder="District (If Any)"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("mobileNumber", {
                        required: "Please enter a mobile number",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Mobile number must be 10 digits",
                        },
                      })}
                      error={!!errors?.mobileNumber}
                      helperText={errors?.mobileNumber?.message}
                      placeholder="Mobile Number"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          WhatsApp Number<span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("whatsAppNumber", {
                        required: "Please enter a whatsapp number",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Whatsapp number must be 10 digits",
                        },
                      })}
                      error={!!errors?.whatsAppNumber}
                      helperText={errors?.whatsAppNumber?.message}
                      placeholder="WhatsApp Number"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Email Address<span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("email", {
                        required: "Please enter a email address",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Enter a valid email ID",
                        },
                      })}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      placeholder="Email Address"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Landline Number
                        </Typography>
                      }
                      {...register("landlineNumber", {
                        maxLength:{value:15,message:'Please enter number below 15 digits'}
                      })}
                      error={!!errors?.landlineNumber}
                      helperText={errors?.landlineNumber?.message}
                      placeholder="Landline Number"
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <Controller
                      control={control}
                      rules={{
                        required: "Please select your currently teaching",
                      }}
                      name="teaching"
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel className="text-dark" id="teaching">
                            Are you currently teaching?
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            className="bg-white"
                            labelId="teaching"
                            id="teaching"
                            label={
                              <Typography className="text-dark">
                                Are you currently teaching?
                                <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value ? field.value : ""}
                            variant="outlined"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (e.target.value !== 1) {
                                setValue("currentDesignation", "");
                                setValue("institution", "");
                              }
                              if (e.target.value !== 1) {
                                clearErrors("currentDesignation"); // Clear error if not "Yes"
                                clearErrors("institution");
                              }
                            }}
                            error={!!fieldState.error}
                          >
                            {currently_teaching.map((data) => (
                              <MenuItem key={data.label} value={data.id}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.teaching && (
                            <FormHelperText error>
                              {errors.teaching.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Your Designation{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("currentDesignation", {
                        required:
                          isTeaching === 1
                            ? "Please enter your designation"
                            : undefined,
                        minLength: {
                          value: 3,
                          message: "Please enter a valid designation",
                        },
                      })}
                      error={!!errors?.currentDesignation}
                      helperText={errors?.currentDesignation?.message}
                      placeholder="Your Designation"
                      disabled={isTeaching !== 1}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label={
                        <Typography className="text-dark">
                          Name of your Institution{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("institution", {
                        required:
                          isTeaching === 1
                            ? "Please enter the name of your institution "
                            : undefined,
                        minLength: {
                          value: 3,
                          message: "Please enter a valid institution",
                        },
                      })}
                      error={!!errors?.institution}
                      helperText={errors?.institution?.message}
                      placeholder=" Name of your Institution"
                      disabled={isTeaching !== 1}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <Controller
                      control={control}
                      rules={{
                        required:
                          "Please select level you are teaching / taught",
                      }}
                      name="primarilyTeachingLevel"
                      render={({ field, fieldState }) => (
                        <>
                          <FormControl fullWidth>
                            <InputLabel
                              className="text-dark"
                              id="primarilyTeachingLevel"
                            >
                              Level at which you are primarily teaching/taught
                              <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Select
                              className="bg-white"
                              labelId="primarilyTeachingLevel"
                              id="primarilyTeachingLevel"
                              label={
                                <Typography className="text-dark">
                                  Level at which you are primarily
                                  teaching/taught
                                  <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              value={field.value ? field.value : ""}
                              variant="outlined"
                              onChange={(event, values) => {
                                const selectedValue = event.target.value;
                                field.onChange(selectedValue);
                                const isOther =
                                  selectedValue ===
                                  PrimarilyTeachingForTeacher.find(
                                    (option) => option.label === "Other"
                                  ).id;
                                setShowPrimarilyOtherInput(isOther);
                                // If 'Other' is selected, clear the 'otherPrimarily' value
                                if (isOther) {
                                  setOtherPrimarily("");
                                }
                              }}
                              renderValue={(selected) => {
                                const selectedOption =
                                  PrimarilyTeachingForTeacher.find(
                                    (option) => option.id === selected
                                  );
                                return selectedOption
                                  ? selectedOption.label
                                  : "Select primarily teaching / taught";
                              }}
                              error={!!fieldState.error}
                            >
                              {PrimarilyTeachingForTeacher.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {fieldState?.error && !showPrimarilyOtherInput && (
                              <FormHelperText error>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </>
                      )}
                    />
                  </Grid>
                  {/* Conditionally render input for "Other" option */}
                  {showPrimarilyOtherInput && (
                    <Grid item lg={4} md={6} xs={12}>
                      <Controller
                        name="otherprimarilyTeachingLevel"
                        control={control}
                        rules={{
                          required: showPrimarilyOtherInput
                            ? "Please specify the other level of teaching"
                            : false,
                        }}
                        render={({
                          field: otherField,
                          fieldState: otherFieldState,
                        }) => (
                          <>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              className="m-0"
                              fullWidth
                              label={
                                <Typography className="text-dark">
                                  Please specify{' '} <span className="text-danger">*</span>
                                </Typography>
                              }
                              placeholder="Specify other level of teaching"
                              variant="outlined"
                              margin="normal"
                              value={otherPrimarily}
                              onChange={(e) => {
                                otherField.onChange(e.target.value);
                                setOtherPrimarily(e.target.value);
                              }}
                              error={!!otherFieldState.error}
                              helperText={otherFieldState.error?.message}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item lg={4} md={6} xs={12}>
                    <Controller
                      control={control}
                      rules={{
                        required: "Please select the nature of your work",
                      }}
                      name="natureWork"
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <InputLabel className="text-dark" id="natureWork">
                            Nature of your work
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Select
                            className="bg-white"
                            labelId="natureWork"
                            id="natureWork"
                            label={
                              <Typography className="text-dark">
                                Nature of your work{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            value={field.value ? field.value : ""}
                            variant="outlined"
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!fieldState.error}
                          >
                            {TypeOfInstitution.map((data) => (
                              <MenuItem key={data.type} value={data.id}>
                                {data.type}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.natureWork && (
                            <FormHelperText error>
                              {errors.natureWork.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {activeStep === 1 && (
              <div className="row row-gap-3">
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl fullWidth error={!!errors.dummyPlanId}>
                    <TextField
                      label={
                        <Typography className="text-dark">
                          Membership Plan{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register("planName")}
                      InputProps={{ readOnly: true }}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    // className="mt-0"
                    fullWidth
                    error={!!errors.dummyPlanTypeId}
                    disabled={
                      watch("planName") ===
                      "No active plan for this member type"
                    }
                  >
                    <InputLabel
                      htmlFor="dummyPlanTypeId"
                      className="text-dark" // Ensure that the label is associated with the input
                    >
                      Membership Plan Type{" "}
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Controller
                      name="dummyPlanTypeId"
                      control={control}
                      rules={{
                        required:
                          watch("planName") ===
                            "No active plan for this member type"
                            ? "No active plan for this member type"
                            : "Please select a membership plan type",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Membership plan type"
                          className="bg-white"
                          id="dummyPlanTypeId"
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            setAmount("");
                          }}
                          value={watch("dummyPlanTypeId") || ""}
                        >
                          <MenuItem value="">
                            <em>Select a membership plan type</em>
                          </MenuItem>
                          {costType &&
                            costType.map((plan) => (
                              <MenuItem key={plan._id} value={plan._id}>
                                <span>
                                  {plan.planTypeName}
                                  {plan.offer === 0 ? (
                                    <span className="mx-2">
                                      {(currency === 1 ? "$" : "₹") +
                                        plan.amount}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="text-decoration-line-through mx-2">
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.amount}
                                      </span>
                                      <span>
                                        {(currency === 1 ? "$" : "₹") +
                                          plan.offerAmount}
                                      </span>
                                    </>
                                  )}
                                </span>
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                    {errors.dummyPlanTypeId && (
                      <FormHelperText>
                        {errors.dummyPlanTypeId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <Controller
                    control={control}
                    name="chapter"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel className="text-dark" id="event">
                          Which chapter of ELTAI would you like to be part of??
                        </InputLabel>
                        <Select
                          className="bg-white"
                          labelId="event"
                          id="event"
                          label={
                            <Typography className="text-dark">
                              Which chapter of ELTAI would you like to be part
                              of?
                            </Typography>
                          }
                          value={field.value || ""}
                          variant="outlined"
                          disabled={disableChapter}
                          onChange={(e) => field.onChange(e.target.value)}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={"I will join a chapter later"}>
                            I will join a chapter later
                          </MenuItem>
                          {selectedChapters.map((data) => (
                            <MenuItem key={data._id} value={data._id}>
                              {data.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <FormHelperText className="text-secondary-new alata-light">
                    For more details about chapters{" "}
                    <Link
                      target="_blank"
                      className="text-secondary-new alata-light text-decoration-underline"
                      to="/view-Chapter"
                    >
                      click here
                    </Link>
                  </FormHelperText>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    component="fieldset"
                    error={!!errors.emailMessage}
                  >
                    <FormLabel component="legend" className="w-75 text-dark">
                      Would you like to receive updates from ELTAI by email?
                      <span className="text-danger">*</span>
                    </FormLabel>
                    <Controller
                      name="emailMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please Select an Option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.emailMessage && (
                      <FormHelperText>
                        {errors.emailMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <FormControl
                    component="fieldset"
                    error={!!errors.whatsAppMessage}
                  >
                    <FormLabel component="legend" className="w-75 text-dark">
                      Would you like to receive updates from ELTAI by WhatsApp?
                      <span className="text-danger">*</span>{" "}
                    </FormLabel>
                    <Controller
                      name="whatsAppMessage"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors.whatsAppMessage && (
                      <FormHelperText>
                        {errors.whatsAppMessage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                {amount && (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="card card-amount-membership">
                      <p className="fw-bold text-center p-3">
                        You have to pay {currency === 1 ? "$" : "₹"}
                        {amount}
                      </p>
                      {/* Assuming this is a form field */}
                      <input
                        type="hidden"
                        {...register("dummyAmount", { value: amount })}
                      />
                      <input
                        type="hidden"
                        {...register("dummyCurrency", { value: currency })}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 3 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="menus-color rounded-0"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                variant="contained"
                className="menus-color rounded-0"
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={style}
          container
          className="overflow-y-scroll"
        >
          <Grid item xs={12} mb={3}>
            <h3 className="alata-regular fw-bold fs-4">Membership Details</h3>
            <p className="alata-regular fw-bold">
              Membership Type:{" "}
              <span className="fw-normal">{watch("planName")}</span>
            </p>
            <p className="alata-regular fw-bold">
              Plan Name:{" "}
              <span className="fw-normal">
                {" "}
                {
                  costType.find((i) => i._id === watch("dummyPlanTypeId"))
                    ?.planTypeName
                }
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Membership valid from:{" "}
              <span className="fw-normal">
                {memberDetails &&
                  memberDetails?.plans[0]?.dummyStartDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Membership valid till:{" "}
              <span className="fw-normal">
                {memberDetails &&
                  memberDetails?.plans[0]?.dummyEndDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
          </Grid>
          <Grid item xs={12} mb={3}>
            <h3 className="alata-regular fw-bold fs-4">Payment Details</h3>
            <p className="alata-regular fw-bold">
              Payment Type:{" "}
              <span className={`fw-normal ${(memberDetails && memberDetails?.plans[0]?.paymentMethod === 'upi') ? "text-uppercase" : 'text-capitalize'}`}>
                {memberDetails && memberDetails?.plans[0]?.paymentMethod}
              </span>
            </p>
            <p className="alata-regular fw-bold">
              Transaction Id: <span className="fw-normal">{memberDetails && memberDetails?.plans[0]?.bankTransactionId}</span>{" "}
            </p>
            <p className="alata-regular fw-bold">
              Payment Date:{" "}
              <span className="fw-normal">
                {memberDetails &&
                  memberDetails?.plans[0]?.paymentDate
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </span>
            </p>
            <Alert style={{ width: "100%" }} severity="success">
              Payment Success
            </Alert>
            <p className="alata-regular fw-bold justified">
              Note:{" "}
              <span className="fw-normal text-danger">
                Your login credentials will be sent through email to this mail
                address{" "}
                <span className="menus-green-color">
                  {watch("email") && watch("email")}
                </span>{" "}
                once the admin activate your account
              </span>
            </p>
          </Grid>
          <div className="text-center w-100">
            <Button
              className="mx-2"
              variant="contained"
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                padding: "5px 15px",
                borderRadius: "4px",
              }}
              onClick={() => navigator("/")}
            >
              Home
            </Button>
          </div>
        </Grid>
      </Modal>
    </>
  );
}

export default Teacher;
