import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateImportantAnnouncementMutation,
  useLazyGetImportantAnnouncementDetailsByIdQuery,
  useUpdateImportantAnnouncementMutation,
} from "../../../app/services/importantAnnouncementService";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";
import { Editor } from "primereact/editor";
import { useSelector } from "react-redux";
import {
  selectCurrentScope,
  selectUserId,
} from "../../../app/auth/authSlice";
import { selectCurrentSig } from "../../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../../app/features/chapterAdminSlice";

const AddEditImportAnnounment = () => {
  const { handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: { text: "" },
  });
  const [text, setText] = useState("");
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [createAnnouncement] = useCreateImportantAnnouncementMutation();
  const [getAnnouncementById] = useLazyGetImportantAnnouncementDetailsByIdQuery();
  const [updateAnnouncement] = useUpdateImportantAnnouncementMutation();

  const userId = useSelector(selectUserId);
  const memberScope = useSelector(selectCurrentScope);
  const sigDetails = useSelector(selectCurrentSig);
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const [approveStatus, setApproveStatus] = useState(''); // State for approve status


  const sigId = sigDetails?._id;
  const chapterId = chapterDetails?._id;

  // Fetch existing data if `id` is provided
  useEffect(() => {
    if (id) {
      setLoading(true);
      getAnnouncementById(id).then((res) => {
        if (res?.data?.status) {
          setText(res.data.data.text);
          setValue("text", res.data.data.text);
          setLoading(false);
          const  approve = res.data.data.isApproved;
          setApproveStatus(approve); // Store approve status in state
        }
      });
    }
  }, [id, getAnnouncementById, setValue,setApproveStatus]);


  const handleContentChange = (value) => {
    if (value.length <= 150) {
      setText(value);
      setError(''); // Clear error if input is valid
    } else {
      setError(`Maximum character limit of ${150} reached.`);
    }
  };

  const handleValidation = () => {
    const cleanText = text?.replace(/<[^>]*>/g, '').trim(); // Remove HTML tags and trim whitespace
    if (!cleanText) {
      setError('Please enter the text!');
      return false;
    } else {
      setError(''); // Clear error if validation passes
      return true;
    }
  };

  const navigateBasedOnScope = () => {
    switch (memberScope) {
      case "PORTAL_SUPER_ADMIN":
        navigate("/admin/importannoucenment");
        break;
      case "PORTAL_ADMIN":
        navigate("/assistantAdmin/importannoucenment");
        break;
      case "PORTAL_CHAPTER_ADMIN":
        navigate("/chapterAdmin/importannoucenment");
        break;
      case "PORTAL_SIG_ADMIN":
        navigate("/sigAdmin/importannoucenment");
        break;
      default:
        break;
    }
  };

  const onSaveAnnounment = (data) => {
    if (!handleValidation()) {
      return; // Prevent submission if validation fails
    }

    // const payload = { ...data, text };
    const payload = { ...data, text, isApproved: approveStatus }; // Include approve status


    if (
      (userId && memberScope === "PORTAL_SUPER_ADMIN") ||
      (userId && memberScope === "PORTAL_SIG_ADMIN") ||
      (userId && memberScope === "PORTAL_ADMIN") ||
      (userId && memberScope === "PORTAL_CHAPTER_ADMIN")
    ) {
      payload.createdBy = {
        id: userId,
        role: memberScope,
      };
    }

    if (memberScope === "PORTAL_SIG_ADMIN") {
      payload.SIGGroupsId = [sigId];
    } else if (memberScope === "PORTAL_CHAPTER_ADMIN") {
      payload.chapterId = [chapterId];
    }
    const action = id
      ? updateAnnouncement({ id, data: payload })
      : createAnnouncement(payload);

    setLoading(true);
    action
      .then((res) => {
        if (res?.data?.status) {
          toast.success(`Announcement ${id ? "Updated" : "Created"} Successfully!`);
          navigateBasedOnScope();
        } else {
          setLoading(false);
          toast.error("Failed to save the announcement!");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="pt-5 pb-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{id ? "Edit Announcement" : "Add Announcement"}</h2>
        </div>
      </div>
      <div className='p-md-5 p-3 overallPadding'>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => window.history.back()}
            variant="contained"
            className="menus-color"
            style={{ marginRight: '25px', marginBottom: '25px' }}
          >
            Back
          </Button>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <form onSubmit={handleSubmit(onSaveAnnounment)}>
            <div className="row mb-3">
              <div className="col-md-12">
                <Editor
                  value={text}
                  onTextChange={(e) => setText(e.htmlValue)}
                  style={{
                    height: "320px",
                    border: error ? "2px solid red" : "2px solid lightgrey",
                  }}
                  onChange={handleContentChange}
                  onBlur={handleValidation} // Validate on blur
                  placeholder="Please Enter Your Text"
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <p className="text-danger">{errors?.text?.message}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Button
                variant="contained"
                className="menus-color"
                type="submit"
                sx={{ mr: 3, mb: 3 }}
              >
                {id ? "Update" : "Submit"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddEditImportAnnounment;
