import {
  Box,
  Button,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Modal,
  Grid,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactPaginate from "react-paginate";
import placeholder from "../../assets/images/placeholder/placeholder.png";
import { toast } from "react-toastify";
import {
  useDeleteSpotlightByIdMutation,
  useEnableDisableSpotlightByIdMutation,
  useGetAllSpotlightPaginationMutation,
} from "../../app/services/spotlightService";
import { useSelector } from "react-redux";
import { selectCurrentScope } from "../../app/auth/authSlice";

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  minWidth: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
  overflow: "scroll",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  placeItems: "center",
  // width:'700px',
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: 4,
  height: "75vh",
  zIndex: "9999999",
};
function EnhancedTableHead() {
  const headCells = [
    {
      id: "heading",
      numeric: false,
      disablePadding: true,
      label: "Heading",
    },
    {
      id: "image",
      numeric: true,
      disablePadding: false,
      label: "Image",
    },
    {
      id: "content",
      numeric: true,
      disablePadding: false,
      label: "Content",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

const SpotlightTable = () => {
  const [loading, setLoading] = useState(true);
  const [allSpotlight, setAllSpotlight] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [totalPage, setTotalPage] = useState(0);
  const [getAllSpotlightPagination] = useGetAllSpotlightPaginationMutation();
  const [enableDisableSpotlight] = useEnableDisableSpotlightByIdMutation();
  const [deleteSpotlight] = useDeleteSpotlightByIdMutation();
  const [selectedId, setSelectedId] = useState("");
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const memberScope = useSelector(selectCurrentScope);

  //get all pagination for testimonial
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
    };
    setLoading(true);
    getAllSpotlightPagination(data).then((res) => {
      setLoading(false);
      if (res.data?.status) {
        setTotalPage(res?.data?.data?.totalCount || 0);
        setAllSpotlight(res.data?.data?.spotLightDetails || []);
      } else {
        setAllSpotlight([]);
      }
    });
  }, [getAllSpotlightPagination, page, rowsPerPage]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  // Enable/Disable
  const handleEnableDisable = (id) => {
    setLoading(true);
    enableDisableSpotlight(id)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          getAllPagination();
          if (res.data?.data.isDeleted === 0) {
            toast.success("Spotlight Enabled");
          } else {
            toast.error("Spotlight Disabled");
          }
        } else {
          getAllPagination();
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleOpen = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = (spotlight) => {
    setSelectedSpotlight(spotlight);
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleDelete = () => {
    setLoading(true);
    deleteSpotlight(selectedId)
      .then((res) => {
        setLoading(false);
        handleClose();
        if (res?.data?.status) {
          getAllPagination();
          toast.success("Spotlight Deleted successfully !!!");
        } else {
          getAllPagination();
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };
  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  return (
    <>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">
            Spotlights List
          </h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="p-md-5 p-3 overallPadding ">
          <div  sx={{ marginBottom: "50px" }}>
            <div className="text-end w-100">

              <Link
                to={
                  memberScope === "PORTAL_SUPER_ADMIN"
                    ? "/admin/add-edit-spotlight"
                    : "/assistantadmin/add-edit-spotlight"
                }
              >
                <Button
                  variant="contained"
                  className="menus-color mb-3"
                  type="submit"
                  style={{
                    backgroundColor: "#1769aa",
                    color: "#fff",
                    padding: "6px 16px",
                    borderRadius: "4px",
                    textTransform: "uppercase",
                    fontFamily: "signika",
                  }}
                >
                  Add Spotlight
                </Button>
              </Link>
            </div>
            <TableContainer sx={{ boxShadow: 0 }} component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead />
                <TableBody>
                  {allSpotlight?.length > 0 ? (
                    allSpotlight.map((spotlight, index) => (
                      <TableRow
                        hover
                        key={spotlight._id}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                      >
                        <TableCell align="center">
                          {spotlight.heading.length>30?(<>{spotlight?.heading.slice(0,30)}...</>):<>{spotlight?.heading}</>}
                        </TableCell>
                        <TableCell align="center" style={{ width: "200px" }}>
                          <img
                            src={
                              spotlight.image
                                ? `${spotlight.image}?t=${new Date().getTime()}`
                                : placeholder
                            }
                            className="w-100"
                            alt="Spotlight"
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ width: "300px" }}>
                          {spotlight.content.slice(0, 200)}...
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="8px"
                            flexWrap="wrap"
                          >
                               {!(spotlight.isDeleted === 2) && (
                                 <>
                                   <Tooltip title="View" placement="top">
                              <IconButton
                                aria-label="ViewIcon"
                                onClick={() => handleOpen1(spotlight)}
                              >
                              <VisibilityIcon className="icon-vieweye-color" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" placement="top">
                              <IconButton aria-label="EditIcon">
                                <Link
                                  to={
                                    memberScope === "PORTAL_SUPER_ADMIN"
                                      ? "/admin/add-edit-spotlight/" + spotlight._id
                                      : "/assistantadmin/add-edit-spotlight/" + spotlight._id

                                  }
                                >
                                  {!(spotlight.isDeleted === 2) && (
                                    <EditIcon style={{ color: "green" }} />
                                  )}
                                </Link>
                              </IconButton>
                            </Tooltip>
                                 </>
                                )}
                          
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                flexWrap: "wrap",
                              }}
                            >
                              {spotlight?.isDeleted === 0 ? (
                                <Tooltip title="Enable" placement="top">
                                  <IconButton
                                    aria-label="enable"
                                    onClick={() => {
                                      handleEnableDisable(spotlight._id);
                                    }}
                                  >
                                    <LockOpenIcon style={{ color: "green" }} />
                                  </IconButton>
                                </Tooltip>
                              ) : spotlight?.isDeleted === 2 ? (
                                <Tooltip title="Disable" placement="top">
                                  <IconButton
                                    aria-label="disable"
                                    onClick={() => {
                                      handleEnableDisable(spotlight._id);
                                    }}
                                  >
                                    <LockIcon style={{ color: "red" }} />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              <Tooltip title="Delete" placement="top">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleOpen(spotlight._id)}
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                    >
                      <TableCell colSpan={4} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <Modal
                  open={open1}
                  onClose={handleClose1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Grid
                    sx={style1}
                    container
                    className="overflow-y-scroll"
                    maxWidth={"sm"}
                  >
                    
                    <h3 style={{margin:"0 auto",color:'#0d4022'}} className="alata-regular fs-3">View Spotlights</h3>
                    <Grid item xs={12} mb={3}>
                      <h2
                        className="alata-regular fs-3"
                        style={{ color: "#611f1e" }}
                      >
                        {selectedSpotlight?.heading}
                      </h2>
                      <img
                        src={selectedSpotlight?.image}
                        className="w-100 img-fluid"
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={12} mb={3}>
                      <p className="alata-regular">
                        {selectedSpotlight?.content}
                      </p>
                    </Grid>
                    <div className="text-center w-100">
                      <Button
                        className="mx-2"
                        variant="contained"
                        style={{
                          backgroundColor: "#dc3545",
                          color: "white",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={() => handleClose1()}
                      >
                        Close
                      </Button>
                    </div>
                  </Grid>
                </Modal>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    className="d-flex align-items-center flex-column gap-3"
                  >
                    <Typography
                      id="modal-modal-title"
                      style={{ color: "#611f1e" }}
                      variant="h6"
                      component="h2"
                    >
                      Are You Surely Want to Delete this Spotlight?
                    </Typography>
                    <div>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#2e7d32",
                          border: "1px solid rgba(46, 125, 50, 0.5)",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={handleClose}
                      >
                        No
                      </Button>
                      <Button
                        className="mx-2"
                        variant="contained"
                        style={{
                          backgroundColor: "#dc3545",
                          color: "white",
                          padding: "5px 15px",
                          borderRadius: "4px",
                        }}
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </Table>
            </TableContainer>
            <br />
            {allSpotlight?.length > 0 && (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={page}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                }
                disabledClassName={"disablebtn"}
              />
            )}
          </div>
          <br />
        </div>
      )}
    </>
  );
};

export default SpotlightTable;
