import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import {
  useGetAllSigPaginationMutation,
  useDeleteSIGGroupMutation,
} from "../../app/services/SIGGroupServices";
import {
  useSwitchJoinSIGMutation,
  useExitSIGMutation,
} from "../../app/services/SIGServices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "../../style/css/SIGGroupTable.css";
import Typography from "@mui/material/Typography";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { selectCurrentMember } from "../../app/features/memberSlice";
import { useSelector } from "react-redux";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Spinner from "../../Component/spinner/spinner";
import ReactPaginate from "react-paginate";
import GroupIcon from "@mui/icons-material/Group";
import { selectCurrentSig } from "../../app/features/sigSlice";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

//Modal usage
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

function EnhancedTableHead() {
  const currentScope = useSelector(selectCurrentScope);
  let headCells;

  //It will display based on memberscope
  if (currentScope === "PORTAL_MEMBER") {
    headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "SIG Group Name",
      },
      {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];
  } else {
    headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "SIG Group Name",
      },
      {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ];
  }

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function SIGGroup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [joinSwitchSig] = useSwitchJoinSIGMutation();
  const [exitSigGroup] = useExitSIGMutation();
  const [deleteSIGGroup] = useDeleteSIGGroupMutation();
  const currentScope = useSelector(selectCurrentScope);
  const user = useSelector(selectCurrentMember);
  const userId = user ? user._id : "";
  const [getAllSig] = useGetAllSigPaginationMutation();
  const [getAllSIGGroup, setGetAllSigGroup] = useState([]);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const sigDetails = useSelector(selectCurrentSig);
  let [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 5;

  //Modal usage
  const handleOpen = (row) => {
    setOpen(true);
    setSelected(row);
  };

  const handleClose = () => setOpen(false);

  //Sig joining
  const joinSig = (sig) => {
    setLoading(true);
    let data = {
      sigGroupId: sig._id,
      memberId: user._id,
    };
    joinSwitchSig(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.success(res.data?.message);
        fetchData();
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Sig exiting
  const exitSig = (sig) => {
    let data = {
      sigId: sig._id,
      memberId: user._id,
    };
    setLoading(true);
    exitSigGroup(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.success(res.data?.message);
        fetchData();
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Handle delete function
  const handleDelete = () => {
    setLoading(true);
    deleteSIGGroup({ id: selected._id, isDeleted: 1 }).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        toast.success(res.data?.message);
        fetchData();
        setSelected(null);
        handleClose();
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
        setSelected(null);
      }
    });
  };

  const handleSigApproveMember = (row) => {
    navigate(`/sig-member-table/${row}`);
  };

  //fetch data get pagination for sig
  const fetchData = useCallback(() => {
    getAllSig({ page: page + 1, itemsPerPage: rowsPerPage })
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res.status) {
          if (res.data.sigDetails.length === 0 && page >= 1) {
            setPage(page - 1);
          }
          setGetAllSigGroup(res?.data.sigDetails || []);
          setTotalCount(res?.data.totalCount || 0);
        } else {
          setGetAllSigGroup([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching pending sig group:", error);
      });
  }, [page, rowsPerPage, getAllSig]);

  // Fetch data on component mount and when dependencies change
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => { }, [getAllSIGGroup]);

  //handle page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">SIG List</h2>
        </div>
      </div>
      <div className='p-md-5 p-3 overallPadding'>
        <div>
          {(currentScope === "PORTAL_SUPER_ADMIN" || currentScope === "PORTAL_ADMIN") && (
            <Link
              style={{ float: "right", marginRight: "3px", marginBottom: "15px" }}
              to={currentScope === "PORTAL_SUPER_ADMIN" ? "/admin/sigGroupForm" : `/assistantAdmin/sigGroupForm`}

            >
              <Button
                variant="contained"
                className="menus-color mt-4"
                type="submit"
                style={{
                  color: "#fff",
                  padding: "6px 16px",
                }}
              >
                ADD SIG
              </Button>
            </Link>
          )}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <Box>
            <>
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {getAllSIGGroup.length > 0 ? (
                      getAllSIGGroup.map((row, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">{row.groupName}</TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: 500,
                            }}
                          >
                            {row?.description && row?.description.slice(0, 100)}
                            ...
                          </TableCell>
                          {currentScope === "PORTAL_MEMBER" && (
                            <TableCell align="center">
                              {!row?.membersId.find(
                                (mem) => mem?.id === userId
                              ) ? (
                                <span className="notJoined">
                                  Not Joined
                                </span>
                              ) : row?.membersId.find(
                                (mem) =>
                                  mem?.id === userId && mem?.isApproved === 1
                              ) ? (
                                <span className="joined">
                                  Joined
                                </span>
                              ) : (
                                <span className="requested">
                                  Requested
                                </span>
                              )}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {currentScope === "PORTAL_SUPER_ADMIN" || currentScope === "PORTAL_ADMIN" ? (
                              <>
                                <Tooltip title="View">
                                  <IconButton
                                    onClick={
                                      currentScope === "PORTAL_SUPER_ADMIN"
                                        ? () => navigate(`/admin/viewSigDetails/${row?._id}`)
                                        : () => navigate(`/assistantAdmin/viewSigDetails/${row?._id}`)
                                    }
                                  >
                                    <VisibilityIcon className='icon-vieweye-color' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={
                                      currentScope === "PORTAL_SUPER_ADMIN"
                                        ? () => navigate(`/admin/sigGroupForm/${row?._id}`)
                                        : () => navigate(`/assistantAdmin/sigGroupForm/${row?._id}`)

                                    }
                                    aria-label="edit"
                                  >
                                    <EditIcon style={{ color: "green" }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Approve/Reject Member">
                                  <IconButton
                                    aria-label="redireact"
                                    onClick={() =>
                                      handleSigApproveMember(row._id)
                                    }
                                  >
                                    <CheckBoxIcon
                                      style={{
                                        color: row?.membersId
                                          ? "blue"
                                          : "",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Joined Member">
                                  <IconButton
                                    aria-label="redireact"

                                    onClick={
                                      currentScope === "PORTAL_SUPER_ADMIN"
                                        ? () => navigate("/admin/sig-joined-Member/" +
                                          row?._id)
                                        : () => navigate("/assistantAdmin/sig-joined-Member/" +
                                          row?._id)
                                    }
                                  >
                                    <GroupIcon
                                      style={{ color: "purple" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Make/Remove Admin">
                                  <IconButton
                                    aria-label="redireact"

                                    onClick={
                                      currentScope === "PORTAL_SUPER_ADMIN"
                                        ? () => navigate("/admin/MakeAdminSigGroup/" + row?._id)
                                        : () => navigate("/assistantAdmin/MakeAdminSigGroup/" + row?._id)
                                    }
                                  >
                                    <AdminPanelSettingsIcon
                                      style={{ color: "aqua" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => handleOpen(row)}
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Link
                                  to={
                                    currentScope === "PORTAL_CHAPTER_ADMIN"
                                      ? `/chapterAdmin/viewSigDetails/${row?._id}`
                                      : currentScope === "PORTAL_SIG_ADMIN"
                                        ? `/sigAdmin/viewSigDetails/${row?._id}`
                                        : currentScope === "PORTAL_MEMBER"
                                          ? `/member/viewSigDetails/${row?._id}`
                                          : `/viewSigDetails/${row?._id}`
                                  }
                                >
                                  <Tooltip title="View">
                                    <IconButton aria-label="view">
                                      <VisibilityIcon
                                        className='icon-vieweye-color'
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                                {currentScope === "PORTAL_MEMBER" && (
                                  <>
                                    {row.membersId.find(
                                      (mem) =>
                                        mem?.id === userId && mem?.isApproved === 1
                                    ) && (
                                        <Tooltip title="Exit Sig ">
                                          <IconButton
                                            variant="contained"
                                            className="icon-exit-color"
                                            onClick={() => exitSig(row)}
                                          >
                                            < LogoutIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                    {!row.membersId.find(
                                      (mem) => mem?.id === userId
                                    ) && (
                                        <Tooltip title="Join">
                                          <IconButton
                                            variant="contained"
                                            className="icon-join-color"
                                            onClick={() => joinSig(row)}
                                          >
                                            < LoginIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                  </>
                                )}
                              </>
                            )}
                            {currentScope === "PORTAL_SIG_ADMIN" &&
                              row?.roleDetails.filter(
                                (user) => user.isAdmin === true
                              ) &&
                              sigDetails._id === row._id ? (
                              <>
                                <Tooltip title="Joined Member">
                                  <IconButton
                                    aria-label="redireact"
                                    onClick={() =>
                                      navigate(
                                        `/sigAdmin/sig-joined-Member/${row?._id}`
                                      )
                                    }
                                  >
                                    <GroupIcon style={{ color: "purple" }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}

                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={style}
                className="d-flex align-items-center flex-column gap-3"
              >
                <Typography
                  id="modal-modal-title"
                  style={{ color: "#611f1e" }}
                  variant="h6"
                  component="h2"
                >
                  Are You Surely Want to Delete this SIG Group?
                </Typography>
                <div>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#2e7d32",
                      border: "1px solid rgba(46, 125, 50, 0.5)",
                      padding: "5px 15px",
                      borderRadius: "4px",
                    }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                  <Button
                    className="mx-2"
                    variant="contained"
                    style={{
                      backgroundColor: "#dc3545",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "4px",
                    }}
                    onClick={() => handleDelete()}
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>
            <div className="w-100 d-flex justify-content-center mt-5 pb-4">
              {getAllSIGGroup.length > 0 ? (
                <ReactPaginate
                  pageCount={pageCount} // Replace with the total number of pages
                  pageRangeDisplayed={rowsPerPage}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                  nextLabel={
                    pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                  }
                  forcePage={page}
                  disabledClassName={"disablebtn"}
                />
              ) : (
                <></>
              )}
            </div>
          </Box>
        )}
      </div>
    </>
  );
}

export default SIGGroup;
