import { Container } from '@mui/material'
import React from 'react'
import Bearflag from '../../assets/images/News/shared image.jpg'
import "./news.css"
const NewsHomePage = () => {
  return (
    <div className='ourExecutiveCommittee-containter'>
      <div className="pt-5">
        <header className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Flag Bearers</h2>
        </header>
      </div>
      <Container maxWidth="xl">
        <div className='row'>
          <div className='col-md-12'>
            <div className="d-flex justify-content-center align-items-center my-5">
              <div className="card shadow news-card-india-reads-containter" >
                <img
                  src={Bearflag}
                  className="card-img-top-news-india-reads"
                  alt="Card"
                />
                <div className="card-body card-text-india-reads">
                  <h5 className="card-title">Dr. Manoharan</h5>
                  <p >
                  <p className='mb-0'>Professor of English (Formerly),</p>
                    <p className='mb-0'>G.T.N. Arts College,</p>   
                    <p className='mb-0'>Dindigul,</p>
                    <p className='mb-0'>Tamil Nadu.</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Container>

    </div>
  )
}

export default NewsHomePage
