import React, { useState, useEffect, useCallback } from 'react'
import { Button, FormControl, ImageListItem, InputLabel, MenuItem, Select } from '@mui/material';
import { useLazyGetAllGalleryQuery } from '../../app/services/galleryService';
import Spinner from "../../Component/spinner/spinner";

const Gallery = () => {
  const [getAllGallery] = useLazyGetAllGalleryQuery();
  const [gallery, setGallery] = useState([]);
  const [galleryCategory, setGalleryCategory] = useState("all");
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [showSingleAlbum, setShowSingleAlbum] = useState(false);
  const [showCategory, setShowCategory] = useState("");
  const [initailData, setInitialData] = useState([])
  const [loading, setLoading] = useState(true);

  //Get all gallery images
  const getGalleryImages = useCallback(() => {
    setLoading(true);
    getAllGallery().then(res => {
      if (res.data?.status) {
        setLoading(false);
        const updatedData=res?.data?.data.map(item=>({...item,list:item?.list.filter(i=>i?.listIsApproved===1)}))
        const filteredData=updatedData.filter(item=>item?.list.length>0)
        setGallery(filteredData)
        const uniqueCategories = []
        const categories = filteredData.map(item => item?.category)
        for (let i = 0; i < categories.length; i++) {
          if (!uniqueCategories.includes(categories[i])) {
            uniqueCategories.push(categories[i])
          }
        }
        setGalleryCategories(uniqueCategories)
        setInitialData(filteredData)
      }
    })
  }, [getAllGallery])

  useEffect(() => {
    getGalleryImages()
  }, [getGalleryImages])

  useEffect(() => {
  }, [gallery]);

  //Handle change for category selection
  const handleChange = (e) => {
    if (e.target.value === "all") {
      setGalleryCategory("all");
      setGallery(initailData)
    } else {
      setGalleryCategory(e.target.value);
      const newArr = initailData.filter((item) => item?.category === e.target.value);
      setGallery(newArr);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
      <div className="row mx-auto mb-5" style={{ maxWidth: "90%" }}>
      {!showSingleAlbum && <div className="w-100 text-end">
          <FormControl
            sx={{ my: 3, width: "150px", textAlign: "left" }}            
            className='customTextField'
          >
            <InputLabel id="demo-simple-select-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={galleryCategory}
              label="Categories"
              onChange={handleChange}
            >
              <MenuItem value="all">All Categories</MenuItem>
              {galleryCategories.length > 0 &&
                galleryCategories.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>}
        <div className="col-md-12 d-flex justify-content-center mt-4">
          <div className="row mx-auto">
            {!showSingleAlbum &&
              gallery.length > 0 &&
              gallery.map((imgs, index) => (
                <>
                  <div className="col-md-6 mb-3">
                    <ImageListItem>
                      <img
                        onClick={() => {
                          setShowSingleAlbum(true);
                          setShowCategory(imgs?.category);
                          setGalleryCategory(imgs?.category);
                          setGallery(initailData)
                        }}
                        className="rounded"
                        style={{ height: '300px', cursor: 'pointer' }}
                        src={imgs?.list[0]?.imageUrl}
                        alt={imgs?.list[0]?.category}
                      />
                      <span
                        style={{ top: "75%",color:"#611f1e",border:"3px soloid #a52a2a "  }}
                        className="position-absolute start-50 translate-middle badge border border-2  mt-4 bg-white fs-5"
                      >
                        {imgs?.category}
                        <span class="visually-hidden">
                          Gallery Category
                        </span>
                      </span>
                    </ImageListItem>
                  </div>
                </>
              ))}
          </div>
          {gallery.length > 0 ?
            showSingleAlbum &&
            gallery.length > 0 &&
            gallery
              .filter((imgs) => imgs?.category === showCategory)
              .map((imgs, index) => (
                <div className="d-flex flex-column">
                  <div className="w-100 text-end mb-3">
                    <Button
                      variant="contained"
                      className='menus-color'
                      onClick={() => {
                        setGalleryCategory("all");
                        setShowSingleAlbum(false);
                        setShowCategory(null);
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {imgs?.list.map((img) => {
                      return (
                        <>
                          <ImageListItem
                            sx={{
                              padding: "8px",
                              cursor: "pointer",
                              objectFit: "scale-down",
                              border: "2px solid grey",
                              mb: 2,
                            }}
                            className="rounded"
                          >
                            <img
                              className="rounded"
                              style={{
                                objectFit: "scale-down",
                              }}
                              src={img?.imageUrl}
                              alt={imgs?.category}
                            />
                            <p
                              className="text-black mt-3"
                              style={{ textAlign: "justify" }}
                            >
                              {img?.description}
                              <span class="visually-hidden">
                                {img?.description}
                              </span>
                            </p>
                          </ImageListItem>
                        </>
                      );
                    })}
                  </div>
                </div>
              ))
            : (
              <div className='w-100 text-center'>
                <p className="text-black fs-2 text-capitalize lead">No Data Available</p>
              </div>
            )}
        </div>
      </div>
      )}
    </>
  )
}

export default Gallery