import React from 'react'
import './membershipPage.css'
import { membershipImage } from '../../constant/constant'
import { Link } from 'react-router-dom'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
const MembershipPage = () => {
 
  return (
    <div className='ourExecutiveCommittee-containter'>
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center alata-bold text-light py-3 mt-1">Membership Form</h2>
        </div>
      </div>
      <div
        className="memberFlex gap-2 column-gap-3 p-md-5 p-3 overallPadding"
        style={{ rowGap: "1rem" }}
      >
        {membershipImage.map((data, i) => (
          <div className={`memberCard ${i === membershipImage.length - 1 ? 'last-item-class MembershipPage-container-card' : ''}`} key={i} >
            <div>
              <h3 className="alata-regular title-with-bg ">{data.Title}</h3>
            </div>
            <div>
              <img src={data?.img} alt={data.role} height={305} style={{ width: "100%", objectFit: "cover" }} />
            </div>
            <Link to={data.externalLink} className="px-3 d-flex justify-content-between formLink">
              <p className=" py-2 mb-0 text-light d-flex justify-content-between align-items-center">{data.role}</p>
              <div className='d-flex align-items-center text-light'>
                <ArrowCircleRightIcon />
              </div>
            </Link>
            <div>
              <p className='mt-2 caption'>{data.caption}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
 
export default MembershipPage