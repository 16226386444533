import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, IconButton, Typography, Modal, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  usePaginationAnnualConferenceMutation,
  useEnableDisableAnnualConferenceMutation,
  useDeleteAnnualConferenceMutation,
} from '../../../app/services/annualConferenceService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const EnhancedNewsTableHead = () => {
  const headCells = [
    { id: 'conferenceName', label: 'Conference Name' },
    { id: 'venue', label: 'Venue' },
    { id: 'date', label: 'Date' },
    { id: 'actions', label: 'Actions' },
  ];

  return (
    <TableHead>
      <TableRow className="menus-color">
        {headCells.map((headCell) => (
          <TableCell sx={{ textAlign: 'center', color: '#ffff' }} key={headCell.id} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const AnnualConferenceList = () => {
  const rowsPerPage = 5;
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [annualConferences, setannualConferences] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [getAllPagination] = usePaginationAnnualConferenceMutation();
  const [EnableDisable] = useEnableDisableAnnualConferenceMutation();
  const [deleteAnnualConference] = useDeleteAnnualConferenceMutation();
  const navigate = useNavigate();
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  const fetchData = useCallback(async () => {
    const data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
    };
    try {
      const res = await getAllPagination(data);
      if (res?.data?.status) {
        setannualConferences(res?.data?.data?.annualConferenceDetails);
        setTotalPage(res?.data?.data?.totalCount);
      }
      else {
        setannualConferences([]);
        setTotalPage(0);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, rowsPerPage, getAllPagination]);

  useEffect(() => { }, [annualConferences])


  const handleEnableDisable = (id) => {
    EnableDisable(id)
      .then((res) => {
        if (res?.data?.data?.isDeleted === 0) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
        fetchData(); // Fetch data after enabling/disabling
      })
      .catch((err) => console.error(err));
  };

  const handleOpenModal = (id) => {
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleClose = () => {
    setDeleteId(null);
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    setOpenModal(false)
    try {
      const res = await deleteAnnualConference(deleteId);
      if (res?.data?.status) {
        fetchData(); // Refresh data after deletion
        toast.success('Conference deleted successfully!');
      } else {
        toast.error('Error deleting conference');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/addEditImport-AnnualConference/${id}`);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, page]); // Fetch data on page change

  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Annual Conference List</h2>
          </div>
        </div>
        <div className="p-md-5 p-3 overallPadding">
          <div className="d-flex justify-content-end mt-2 mb-4 align-items-center">
            <Link to={'/admin/addEditImport-AnnualConference'}>
              <Button variant="contained" className="menus-color" style={{ color: 'white' }}>
                Add Annual Conference
              </Button>
            </Link>
          </div>
          <Box >
            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedNewsTableHead />
                <TableBody>
                  {annualConferences.length > 0 ? (
                    annualConferences.map((annualConference, index) => (
                      <TableRow
                        hover
                        key={annualConference._id}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                        }}
                      >
                        <Modal
                          open={openModal}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: 400,
                              backgroundColor: 'white',
                              boxShadow: 24,
                              padding: "20px",
                              outline: 'none',
                            }}
                          >
                            <Typography
                              id="modal-modal-title"
                              style={{ color: "#611f1e" }}
                              variant="h6"
                              component="h2"
                            >
                              Are You Surely Want to Delete this Annual Conference?
                            </Typography>
                            <div>
                              <Button
                                variant="outlined"
                                style={{
                                  color: "#2e7d32",
                                  border: "1px solid rgba(46, 125, 50, 0.5)",
                                  padding: "5px 15px",
                                  borderRadius: "4px",
                                }}
                                onClick={handleClose}
                              >
                                No
                              </Button>
                              <Button
                                className="mx-2"
                                variant="contained"
                                style={{
                                  backgroundColor: "#dc3545",
                                  color: "white",
                                  padding: "5px 15px",
                                  borderRadius: "4px",
                                }}
                                onClick={() => handleDelete(annualConference._id)}
                              >
                                Yes
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                        <TableCell align="center">{annualConference.conferenceName}</TableCell>
                        <TableCell align="center">{annualConference.venue}</TableCell>
                        <TableCell align="center">{annualConference.date}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Edit">
                            <IconButton aria-label="editIcon" onClick={() => handleEdit(annualConference._id)}>
                              {!(annualConference.isDeleted === 2) && <EditIcon className="icon-vieweye-color" />}
                            </IconButton>
                          </Tooltip>
                          {annualConference?.isDeleted === 0 ? (
                            <Tooltip title="Enable">
                              <IconButton
                                aria-label="enable"
                                onClick={() => handleEnableDisable(annualConference._id)}
                              >
                                <LockOpenIcon style={{ color: 'green' }} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Disable">
                              <IconButton
                                aria-label="disable"
                                onClick={() => handleEnableDisable(annualConference._id)}
                              >
                                <LockIcon style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => handleOpenModal(annualConference._id)}>
                              <DeleteIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell colSpan={4} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="w-100 d-flex justify-content-center mt-5 pb-4">
              {annualConferences?.length > 0 && (
                <ReactPaginate
                  pageCount={pageCount} // Calculate total pages
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  forcePage={page}
                  previousLabel={pageCount > 1 && page >= 1 ? 'Previous' : null}
                  nextLabel={pageCount > 1 && page + 1 !== pageCount ? 'Next' : null}
                  disabledClassName={'disablebtn'}
                />
              )}
            </div>
          </Box>
        </div>
      </Box>
      {/* Modal for confirming delete */}
    </div>
  );
};

export default AnnualConferenceList;