import React from 'react'
import './OurExecutiveCommittee.css'
import Box from '@mui/material/Box';
import { ourExecutiveCommitteeData1, Presidents, Secretaries, eltaiMembers } from '../../../constant/constant'

const OurExecutiveCommittee = () => {
    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Our Executive Committee</h2>
                </div>
                <div maxWidth="lg" className='p-md-5 p-3 overallPadding'>
                    <div className='row d-flex justify-content-center'>
                        {eltaiMembers.map((data, i) => {
                            return (
                                <div className='col-xl-3 col-lg-4 col-md-6' key={i}>
                                    <div className='eltaiMember-containter'>
                                        <div className='card eltaimember-card-important-person'>
                                            <img src={data.img} alt={data.name} className='eltaimember-image-important-person mb-5' />
                                        </div>
                                        <div className='card eltaimember-details-important-person mb-5 '>
                                            <h6 className='text-center signika-font eltaimember-details-important-person-name'>{data.name}</h6>
                                            <h6 className='text-center signika-font'>{data.role}</h6>
                                            <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>{data.Address}</p>
                                            {data.mobile && <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>Mobile: {data.mobile}</p>}
                                            {data.email && <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>Email: {data.email}</p>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <h2 className='text-center fw-bold signika-font executive-member-title'>EXECUTIVE MEMBERS</h2>
                    <div className='row d-flex justify-content-center'>
                        {ourExecutiveCommitteeData1.map((data, i) => {
                            return (
                                <div className='col-xl-3 col-lg-4 col-md-6 ' key={i}>
                                    <div className='eltaiMember-containter'>
                                        <div className='card executive-card-important-person'>
                                            <img src={data.img} alt={data.name} className='eltaimember-image-important-person mb-5' />
                                        </div>
                                        <div className='card eltaimember-details-important-person mb-5 '>
                                            <h6 className='text-center signika-font eltaimember-details-important-person-name'>{data.name}</h6>
                                            <h6 className='text-center signika-font'>{data.role}</h6>
                                            <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>{data.Address}</p>
                                            {data.mobile && <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>Mobile: {data.mobile}</p>}
                                            {data.email && <p className='text-center signika-font m-0 eltaimember-details-important-person-address'>Email: {data.email}</p>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <h2 className='signika-font text-center  fw-bold  past-office-beaer'>PAST OFFICE BEARERS</h2>
                    <h2 className='signika-font text-center  fw-bold mt-3 presidents-OEC'>Presidents</h2>
                    <div className='row mt-5 mb-5'>
                        {Presidents.map((data, i) => {
                            return (
                                <div className='col-md-2 mb-4' key={i}>
                                    <div className={`presidentcard${data.id} m`}>
                                        <div className='presidents-dot'></div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className={`president-line${data.id}`}></div>
                                    </div>
                                    <h6 className='signika-font m-0 president-name'>{data.Name}</h6>
                                    <p className='signika-font m-0 president-year'>{data.years}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div maxWidth="lg" >
                    <h2 className='signika-font text-center fw-bold presidents-OEC'>Secretaries</h2>
                    <div className='row mt-5  d-flex justify-content-center secretaries-container'>
                        {Secretaries.map((data, i) => {
                            return (
                                <div className='col-md-2 mb-5' key={i}>
                                    <div className={`presidentcard${data.id}`}>
                                        <div className='presidents-dot'></div>
                                    </div>
                                    <div className="d-flex justify-content-center ">
                                        <div className={`president-line${data.id}`}></div>
                                    </div>
                                    <h6 className='signika-font m-0 president-name'>{data.Name}</h6>
                                    <p className='signika-font m-0 president-year'>{data.years}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </Box>
        </div>
    )
}

export default OurExecutiveCommittee