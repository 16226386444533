import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  useLazyGetChapterDetailsByIdQuery,
  useMakeAdminMutation,
  useRemoveAdminMutation,
} from "../../app/services/chapterServices";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import TopHeader from "../../Component/Header/TopHeader";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import LockResetIcon from '@mui/icons-material/LockReset';
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useResetPasswordMutation } from "../../app/services/loginServices";


//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "calories", numeric: true, disablePadding: false, label: "Email Id" },
    { id: "role", numeric: true, disablePadding: false, label: "Role" },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        backgroundColor: "#0072bb",
        textAlign: "center",
        color: "white",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {numSelected > 0 ? `${numSelected} selected` : "Office Bearers List"}
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const MakeAdmin = () => {
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleClose = () => setOpen(false);
  const { register, getValues, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const { chapterId } = useParams();
  const [chapterDetails, { data: chapterData }] =
    useLazyGetChapterDetailsByIdQuery();
  const [makeMemberAdmin] = useMakeAdminMutation();
  const [removeMemberAdmin] = useRemoveAdminMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false })

  useEffect(() => {
    chapterDetails(chapterId);
  }, [chapterDetails, chapterId]);

  //Remove admin
  const removeAdmin = (member) => {
    const data = {
      chapterId,
      roleId: member._id,
      email: member.email,
    };
    setLoading(true);
    removeMemberAdmin(data).then((res) => {
      if (res.data?.data) {
        setLoading(false);
        chapterDetails(chapterId);
        toast.success("Admin removed successfully");
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Make admin
  const makeAdmin = () => {
    const data = {
      chapterId: chapterId,
      roleId: selectedMember._id,
      email: selectedMember.email,
      password: getValues("password"),
    };
    setLoading(true);
    makeMemberAdmin(data).then((res) => {
      handleClose();
      if (res.data.status) {
        setLoading(false);
        chapterDetails(chapterId);
        reset()
        toast.success(res.data?.message);
      } else {
        setLoading(false);
        toast.error(res.data?.err?.message);
      }
    });
  };

  //Reset Password
  const handleResetPassword = (id) => {
      var data = {id:id}
      resetPassword(data).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        }
        else {
          toast.error(res?.data?.message)
        }
      }).catch(err => console.error(err))
    }

  //handle Click show password
  const handleClickShowPassword = (type) => {
    setShowPassword({ ...showPassword, [type]: !showPassword[type] });
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Make/Remove Admin</h2>
        </div>
      </div>
      <div
        className="col-2 mt-2"
        style={{ float: "right", marginRight: "6rem" }}
      >
        <br></br>
        <Button
          onClick={() => window.history.back()}
          variant="contained"
          className="menus-color"
          style={{ float: "right", marginRight: "10px" }}
        >
          Back
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ width: "80%", marginTop: 10 }} className="memberTable mb-5">
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead />
                <TableBody>
                  {chapterData?.data?.roleDetails.length > 0 ? (
                    chapterData?.data?.roleDetails.map((member, index) => (
                      <TableRow
                        hover
                        key={member._id}
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            index % 2 === 0 ? "#f0f0f0" : "white",
                        }}
                      >
                        <TableCell align="center">{member.name}</TableCell>
                        <TableCell align="center">{member.email}</TableCell>
                        <TableCell align="center">{member.role}</TableCell>
                        <TableCell align="center">
                          {member.isAdmin ? (
                            <>
                              <Tooltip title="Remove Admin">
                                <IconButton
                                  color="error"
                                  onClick={() => removeAdmin(member)}
                                  title="Remove Admin"
                                  disabled={!member.isAdmin}
                                  aria-label="approve"
                                >
                                  <CloseIcon color="error"></CloseIcon>
                                </IconButton>
                              </Tooltip>
                              <IconButton onClick={() => handleResetPassword(member._id)}>
                                <Tooltip title="Reset Password">
                                  <LockResetIcon style={{ color: 'darkred' }} />
                                </Tooltip>
                              </IconButton>
                            </>
                          ) : (
                            <Tooltip title="Make Admin">
                              <IconButton
                                onClick={() => handleOpen(member)}
                                aria-label="approve"
                                title="Make Admin"
                              >
                                <DoneIcon color="success"></DoneIcon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component={'form'} onSubmit={handleSubmit(makeAdmin)} className='d-grid place-items-center gap-3 mx-auto'>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#134b2b" }}>
            Enter a Password
          </Typography>
          <TextField className="time-picker" type={showPassword?.password ? 'text' : 'password'} label={
            <Typography className="text-dark">
              Password <span style={{ color: "red" }}>*</span>
            </Typography>
          } InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('password')}
                  edge="end"
                >
                  {showPassword?.password ? <VisibilityOffIcon sx={{ color: '#611f1e !important' }} /> : <VisibilityIcon sx={{ color: '#611f1e !important' }} />}
                </IconButton>
              </InputAdornment>
            ),
          }} error={!!errors?.password} helperText={errors?.password?.message} {...register('password', { required: 'Please Enter a New password', pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$/, message: 'Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8' } })} ></TextField>
          <TextField className="time-picker" type={showPassword?.confirmPassword ? 'text' : 'password'} label={
            <Typography className="text-dark">
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Typography>
          } InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('confirmPassword')}
                  edge="end"
                >
                  {showPassword?.confirmPassword ? <VisibilityOffIcon sx={{ color: '#611f1e !important' }} /> : <VisibilityIcon sx={{ color: '#611f1e !important' }} />}
                </IconButton>
              </InputAdornment>
            ),
          }}  {...register('confirmPassword', {
            required: 'Please Re-enter Password', validate: (value) => {
              if (value !== watch('password')) {
                return 'Password do not match'
              }
              return true
            }
          })} error={!!errors?.confirmPassword} helperText={errors?.confirmPassword?.message} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="outlined" color="error" onClick={handleClose} style={{ marginRight: '10px' }}>Cancel</Button>
            <Button className="menus-color" type='submit' variant='contained' style={{ backgroundColor: "#601e1d" }}>Submit</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MakeAdmin;
