import { Box, Button, Grid, InputAdornment, TextField, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../Component/spinner/spinner';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import placeholder from '../../../assets/images/placeholder/placeholder.png'
import { useLazyGetBannerByIdQuery, useSaveBannerMutation, useUpdateBannerByIdMutation } from '../../../app/services/bannerServices';

const AddEditBannerForm = () => {
    const { bannerContentId } = useParams();
    const [loading, setLoading] = useState(false);
    const {
        register,
        setValue,
        handleSubmit,
        formState: { isValid, errors },
        reset,
        setError,
        clearErrors,
        watch
    } = useForm({
        defaultValues: {
            text: '',
            buttonName: '',
            buttonLink: '',
            desktopBannerImage: '',
            mobileBannerImage: ''
        },
        mode: 'onChange'
    });
    const [saveBanner] = useSaveBannerMutation();
    const [updateBanner] = useUpdateBannerByIdMutation();
    const [getBannerById] = useLazyGetBannerByIdQuery();
    const navigate = useNavigate();
    const [selectedDesktopImage, setSelectedDesktopImage] = useState();
    const [selectedMobileImage, setSelectedMobileImage] = useState();

    //get Testimonial By Id 
    useEffect(() => {
        if (bannerContentId) {
            setLoading(true);
            getBannerById(bannerContentId).then((res) => {
                if (res.data?.status) {
                    setLoading(false);
                    const data = res.data?.data;
                    if (data.desktopBannerImage.length > 0) {
                        // Add a timestamp to the URL to force reload (cache-busting)
                        const cacheBustedUrl = `${data.desktopBannerImage}?t=${new Date().getTime()}`;
                        setValue("desktopBannerImage", cacheBustedUrl);
                    } else {
                        setValue("desktopBannerImage", "");
                    }
                    if (data.mobileBannerImage.length > 0) {
                        // Add a timestamp to the URL to force reload (cache-busting)
                        const cacheBustedUrl = `${data.mobileBannerImage}?t=${new Date().getTime()}`;
                        setValue("mobileBannerImage", cacheBustedUrl);
                    } else {
                        setValue("mobileBannerImage", "");
                    }
                    setValue("text", data.text);
                    setValue("buttonName", data.buttonName);
                    setValue("buttonLink", data.buttonLink);
                }
                else {
                    setLoading(false);
                }
            });
        }
    }, [bannerContentId, getBannerById, setValue]);

    //Event image handling
    const handleDesktopImageChange = (event) => {
        const file = event.target.files[0];
        if (!file || !file.type.startsWith("image/")) {
            setSelectedDesktopImage(null);
            setError('desktopImage', { message: 'Please select a image file' })
            setValue('desktopBannerImage', "")
            return;
        }
        if (file && file.size > 2 * 1024 * 1024) {
            setSelectedDesktopImage(null);
            setError('desktopImage', { message: 'File size exceed 2 mb' })
            setValue('desktopBannerImage', "")
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    console.log(img.width, img.height);
                    if ((img.width < 1910 || img.width > 1930) || (img.height < 615 || img.height > 625)) {
                        setSelectedDesktopImage(null);
                        setValue('desktopBannerImage', "");
                        setError("desktopImage", { message: "Image dimensions must be 1910 X 615 to 1930 X 625 pixels" });
                        return;
                    }
                    // Image is valid
                    let base64String = e.target.result;
                    setSelectedDesktopImage(reader.result);
                    setValue('desktopBannerImage', base64String);
                    clearErrors("desktopImage");
                };
            };
            reader.readAsDataURL(file);
        }
    };
    // mobile image
    const handleMobileImageChange = (event) => {
        const file = event.target.files[0];
        if (!file || !file.type.startsWith("image/")) {
            setSelectedMobileImage(null);
            setError('mobileImage', { message: 'File Size exceed 2 mb' })
            setValue('mobileBannerImage', "")
            return;
        }
        if (file && file.size > 2 * 1024 * 1024) {
            setSelectedMobileImage(null);
            setError('mobileImage', { message: 'File Size exceed 2 mb' })
            setValue('mobileBannerImage', "")
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    // if (img.width !== 1200 || img.height !== 700) {
                    //         setSelectedMobileImage(null);
                    //         setValue('mobileBannerImage', "");
                    //         setError("mobileImage", { message: "Image dimensions must be 420x160 pixels" });
                    //         return;
                    //     }
                    // Image is valid
                    let base64String = e.target.result;
                    setSelectedMobileImage(reader.result);
                    setValue('mobileBannerImage', base64String);
                    clearErrors("mobileImage");
                };
            };
            reader.readAsDataURL(file);
        }
    };


    //submit& update
    const onFormSubmit = (formData) => {
        if (isValid) {
            setLoading(true);
            if (bannerContentId) {
                let updateData;
                const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
                if (base64Regex.test(formData.imageUrl)) {
                    updateData = { id: bannerContentId, formData };
                } else {
                    updateData = {
                        id: bannerContentId,
                        formData: formData,
                    };
                }
                updateBanner(updateData).then((res) => {
                    if (res.data?.status) {
                        toast.success("Banner Content Updated Successfully!!!");
                        setLoading(false);
                        navigate("/admin/banner-content-table");
                        reset();
                    } else {
                        setLoading(false);
                        toast.error(res.data?.err?.message);
                    }
                });
            } else {
                saveBanner(formData).then((res) => {
                    if (res.data?.status) {
                        toast.success("Banner Content Added Successfully!!!");
                        setLoading(false);
                        navigate("/admin/banner-content-table");
                        reset();
                    } else {
                        setLoading(false);
                        toast.error(res.data?.err?.message);
                    }
                });
            }
        }
    };

    return (
        <>
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{bannerContentId ? "Edit Banner Content" : "Add Banner Content"}</h2>
                </div>
            </div>

            {loading ? (
                <Spinner />
            ) : (
                <Box
                    component={"form"}
                    className="d-flex flex-column align-items-center p-md-5 p-3 overallPadding "
                    onSubmit={handleSubmit(onFormSubmit)}
                >
                    <div className="text-end w-100 mb-3">
                        <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="menus-color"
                            style={{ float: "right" }}
                        >
                            Back
                        </Button>
                    </div>
                    <Grid container rowSpacing={3} columnSpacing={3} >
                        <Grid item md={6} xs={12} className='d-flex align-items-end'>
                            <div className='d-flex flex-column justify-content-between row-gap-3'>
                                {bannerContentId ? (
                                    <>
                                        <img
                                            src={selectedDesktopImage ? selectedDesktopImage : watch("desktopBannerImage") ? watch("desktopBannerImage") : placeholder} // Fetched image from backend with cache busting
                                            alt="Desktop banner"
                                            className=' w-75 '

                                            style={{
                                                objectFit: "scale-down",
                                                // width:"75%"
                                            }}
                                        />
                                        <TextField
                                            sx={{ mt: 2 }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ accept: "image/jpeg, image/png" }}
                                            id="desktopBannerImage"
                                            label={
                                                <Typography className="text-dark">
                                                    Image <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                            }
                                            style={{ display: 'none' }}
                                            type="file"
                                            {...register(`desktopImage`, {
                                                required: { value: !watch(`desktopBannerImage`), message: 'Please Select a Image for desktop' }, validate: (value) => {
                                                    const file = value[0];
                                                    if (!watch(`desktopBannerImage`)) {
                                                        if (!file || !file.type.startsWith("image/")) {
                                                            setValue(`desktopBannerImage`, "")
                                                            return "Please Select a Image File"
                                                        }
                                                        if (file && file.size > 2 * 1024 * 1024) {
                                                            setValue(`desktopBannerImage`, "")
                                                            return "File size exceeds 2mb"
                                                        }
                                                        return true;
                                                    }
                                                }, onChange: (e) => handleDesktopImageChange(e),
                                            })}
                                        />
                                        {!!errors?.desktopImage && (
                                            <p className='error-message mb-0'>
                                                {errors?.desktopImage?.message}
                                            </p>
                                        )}
                                        <div>
                                            <label
                                                htmlFor="desktopBannerImage"
                                            >
                                                <Button
                                                    variant="contained"
                                                    className="menus-color"
                                                    component="span"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Choose Desktop Image <span style={{ color: "white" }}>*</span>
                                                </Button>
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='d-flex gap-1'>
                                            <InfoIcon style={{ color: '#611f1e' }} />
                                            <p style={{ fontSize: '14px', display: 'flex', alignItems: 'center',marginBottom:'0px' }}>Image dimensions must range from 1910 x 615 to 1930 x 625 pixels.</p>
                                        </div>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ accept: "image/jpeg, image/png" }}
                                            id="desktopBannerImage"
                                            label={
                                                <Typography className="text-dark">
                                                    Desktop Image <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                            }
                                            type="file"
                                            error={!!errors?.desktopImage}
                                            helperText={errors?.desktopImage?.message}
                                            {...register(`desktopImage`, {
                                                required: { value: !watch(`desktopBannerImage`), message: 'Please Select a Image for desktop' }, validate: (value) => {
                                                    const file = value[0];
                                                    if (!watch(`desktopBannerImage`)) {
                                                        if (!file || !file.type.startsWith("image/")) {
                                                            setValue(`desktopBannerImage`, "")
                                                            return "Please select a Image File"
                                                        }
                                                        if (file && file.size > 2 * 1024 * 1024) {
                                                            setValue(`desktopBannerImage`, "")
                                                            return "File size exceeds 2mb"
                                                        }
                                                        return true;
                                                    }
                                                }, onChange: (e) => handleDesktopImageChange(e),
                                            })}
                                        />
                                    </>
                                )}

                            </div>
                        </Grid>
                        <Grid item md={6} xs={12} className='d-flex align-items-end'>
                            <div className='d-flex flex-column justify-content-between   row-gap-3'>
                                {bannerContentId ? (
                                    <>

                                        <img
                                            src={selectedMobileImage ? selectedMobileImage : watch("mobileBannerImage") ? watch("mobileBannerImage") : placeholder} // Fetched image from backend with cache busting
                                            alt="Mobile Banner"
                                            // className='img-fluid w-75 h-50'
                                            style={{
                                                objectFit: "scale-down",
                                            }}
                                        />
                                        <TextField
                                            sx={{ mt: 2 }}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ accept: "image/jpeg, image/png" }}
                                            id="mobileBannerImage"
                                            label={
                                                <Typography className="text-dark">
                                                    Mobile Image <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                            }
                                            style={{ display: 'none' }}
                                            type="file"
                                            {...register(`mobileImage`, {
                                                required: { value: !watch(`mobileBannerImage`), message: 'Please Select a Image for mobile' }, validate: (value) => {
                                                    const file = value[0];
                                                    if (!watch(`mobileBannerImage`)) {
                                                        if (!file || !file.type.startsWith("image/")) {
                                                            setValue(`mobileBannerImage`, "")
                                                            return "Please Select a Image File"
                                                        }
                                                        if (file && file.size > 2 * 1024 * 1024) {
                                                            setValue(`mobileBannerImage`, "")
                                                            return "File size exceeds 2mb"
                                                        }
                                                        return true;
                                                    }
                                                }, onChange: (e) => handleMobileImageChange(e),
                                            })}
                                        />
                                        {!!errors?.mobileImage && (
                                            <p className='error-message mb-0'>
                                                {errors?.mobileImage?.message}
                                            </p>
                                        )}
                                        <div>
                                            <label
                                                htmlFor="mobileBannerImage"
                                            >
                                                <Button
                                                    variant="contained"
                                                    className="menus-color"
                                                    component="span"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Choose Mobile Image <span style={{ color: "white" }}>*</span>
                                                </Button>
                                            </label>
                                        </div>
                                    </>
                                ) : <>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/jpeg, image/png" }}
                                        id="mobileBannerImage"
                                        label={
                                            <Typography className="text-dark">
                                                Image <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        }
                                        type="file"
                                        error={!!errors?.mobileImage}
                                        helperText={errors?.mobileImage?.message}
                                        {...register(`mobileImage`, {
                                            required: { value: !watch(`mobileBannerImage`), message: 'Please Select a Image for mobile' }, validate: (value) => {
                                                const file = value[0];
                                                if (!watch(`mobileBannerImage`)) {
                                                    if (!file || !file.type.startsWith("image/")) {
                                                        setValue(`mobileBannerImage`, "")
                                                        return "Please Select a Image File"
                                                    }
                                                    if (file && file.size > 2 * 1024 * 1024) {
                                                        setValue(`mobileBannerImage`, "")
                                                        return "File size exceeds 2mb"
                                                    }
                                                    return true;
                                                }
                                            }, onChange: (e) => handleMobileImageChange(e),
                                        })}
                                    />
                                </>}

                            </div>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12} className='d-flex align-items-start'>
                            {/* <TextField
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%" }}
                                id="body"
                                label={
                                    <Typography className="text-dark">
                                        Banner Text <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                variant="outlined"
                                placeholder="Enter banner text"
                                {...register("text", { required: "Please enter banner content text" })}
                                error={!!errors?.text}
                                helperText={errors?.text?.message}
                                multiline
                            /> */}
                            <TextField
                                id="outlined-basic"
                                label={<Typography className="text-dark">
                                    Banner Text<span style={{ color: "red" }}>*</span>
                                </Typography>}
                                InputLabelProps={{ shrink: true }}
                                className="mt-3"
                                multiline
                                inputProps={{ maxLength: 150 }}
                                {...register("text", { required: "Please enter banner content text" })}
                                error={!!errors?.text}
                                placeholder="Enter banner text"
                                helperText={errors?.text?.message} fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"><span style={{ position: 'absolute', bottom: '0', right: '5px' }} className='fw-bold text-primary'>{150 - (watch(`text`) ? watch(`text`).length : 0)}</span></InputAdornment>)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12} className='d-flex align-items-start mt-3'>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                id="buttonText"
                                label={
                                    <Typography className="text-dark">
                                        Button Text <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                placeholder="Please enter button text"
                                style={{ height: "max-content" }}
                                {...register("buttonName", {
                                    required: "Please enter button text",
                                    maxLength: {
                                        value: 300,
                                        message: "Text cannot exceed 300 characters",
                                    },
                                })}
                                inputProps={{ maxLength: 300 }}
                                className="form-control"
                                error={!!errors?.buttonName}
                                helperText={errors?.buttonName?.message}
                            />

                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                            className="d-flex align-items-start mt-3"
                        >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                id="buttonLink"
                                label={
                                    <Typography className="text-dark">
                                        Button Link <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                placeholder="Example:www.sample.com"
                                style={{ height: "max-content" }}
                                {...register("buttonLink", {
                                    required: "Please enter button link",
                                    pattern: {
                                        value: /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(\d{1,3}\.){3}\d{1,3})(:[0-9]+)?(\/[\w .-]*)*(\?.*)?$/,
                                        message: "Please enter a valid link",
                                    },
                                })}
                                className="form-control"
                                error={!!errors?.buttonLink}
                                helperText={errors?.buttonLink?.message}
                                fullWidth
                            />
                        </Grid>



                    </Grid>
                    <>
                        <Button variant="contained" className="menus-color mt-5" type="submit">
                            {bannerContentId ? "Update" : "Submit"}
                        </Button>
                    </>

                </Box>
            )
            }
        </>
    )
}

export default AddEditBannerForm