import { api } from "./api";

const spotlightService = api.injectEndpoints({
  endpoints: (builder) => ({
     //save testimonial
     saveSpotlight: builder.mutation({
      query: (formData) => ({
        url: "spotLight/saveSpotLight",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData,
      }),
      invalidatesTags:["spotlight"]
    }),

    //get Testimonial pagination
    getAllSpotlightPagination:builder.mutation({
      query: (data) => ({
        url: "spotLight/getAllSpotLightPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
    
    //get testimonial Details by Id
    getSpotlightById: builder.query({
      query: (id) => "spotLight/get/" + id,
      invalidatesTags: ["spotlight"],
    }),

    //Update testimonial
    updateSpotlightById: builder.mutation({
      query: (data) => ({
        url: "spotlight/update/"+ data.id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data.formData,
      }),
      invalidatesTags:['spotlight']
    }),

    //deleteTestmonial
    deleteSpotlightById: builder.mutation({
        query: (id) => ({
          url: "spotlight/delete/"+ id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }),
        invalidatesTags:['spotlight']
      }),
    //deleteTestmonial
    enableDisableSpotlightById: builder.mutation({
        query: (id) => ({
          url: "spotlight/enableDisable/"+ id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }),
        invalidatesTags:['spotlight']
      }),
      paginationSpotLight: builder.mutation({
        query: (data) => ({
            url: "spotLight/getAllSpotLightPagination",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data,
        }),
    }),
      
  }),
  overrideExisting: false,
});

export const {
useGetAllSpotlightPaginationMutation,
useDeleteSpotlightByIdMutation,
useEnableDisableSpotlightByIdMutation,
useLazyGetSpotlightByIdQuery,
useSaveSpotlightMutation,
useUpdateSpotlightByIdMutation,
usePaginationSpotLightMutation
} = spotlightService;
