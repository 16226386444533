import { createSlice } from "@reduxjs/toolkit";
const AssistentAdminSlice = createSlice({
    name: 'assistantAdmin',
    
    //intialState
    initialState: {
        assistentAdminDetails: {},
        assistantRoleDetails: {},
    },

    //reducer
    reducers: {
        setAssistantDetails: (state, action) => {
            state.assistentAdminDetails = action.payload;
        },
        setRoleDetails: (state, action) => {
            state.assistantRoleDetails = action.payload;
        } 
    } 
})

export default AssistentAdminSlice.reducer
export const { setAssistantDetails } = AssistentAdminSlice.actions
export const { setRoleDetails } = AssistentAdminSlice.actions
export const selectCurrenAssistantAdmin = (state) => state.assistantAdmin.assistentAdminDetails
export const selectCurrenAssistantRole = (state) => state.assistantAdmin.assistantRoleDetails