import React from 'react';
import '../ContactUS/contactus.css';
import CallIcon from '../../assets/images/contactus/CallIcon.png';
import LocationIcon from '../../assets/images/contactus/LocationIcon.png';
import EmailIcon from '../../assets/images/contactus/EmailIcon.png';
import FBicon from '../../assets/images/contactus/facebookicon.png';
import Xicon from '../../assets/images/contactus/Xicon.png';
import instaicon from '../../assets/images/contactus/instaicon.png';
import youtubeicon from '../../assets/images/contactus/youtubeicon.png';
import ELTALogo from '../../assets/images/contactus/ELTALogo.png';

const ContactUs = () => {
  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Contact</h2>
      </div>
      <div maxWidth="lg" className='p-md-5 p-3  overallPadding'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-lg-6  col-md-12' style={{ paddingTop: '-200px' }}>
            <div className='row eltai_logo' >
              <img src={ELTALogo} alt="ELTA Logo" height='120px' />
            </div>
            <div className='signika-font w-75 ms-5 mt-4 justified'>
              We'd love to hear from you! Whether you have a question about our services,
              need assistance, or just want to provide feedback, our team is ready to help.
            </div>
          </div>
          <div className='col-lg-6 col-md-12 mt-5 mt-lg-0 p-0'>
            <p className='signika-font d-flex align-items-center justify-content-center'
              style={{ fontSize: '18px', fontWeight: '700' }}>Get in Touch</p>
            <div className='card card-contact-us-eltai  rounded-0'>
              <div className='row'>
                <div className="col-lg-3 location-icon-contact-us">
                  <img src={LocationIcon} alt="Location Icon" className='location-icon-image' />
                </div>
                <div className='col-lg-9'>
                  <div class=" signika-font location-address p-3 fw-light">
                    D-54 Third Floor, Anandham<br /> Apartments, 156, SIDCO Nagar Main<br /> Road,
                    Villivakkam, Chennai-600 049.
                  </div>
                </div>
              </div>
            </div>
            <div className='card card-contact-us-eltai rounded-0 mt-4 p-3'>
              <div className='row'>
                <div className='col-lg-3 call-icon-contact-us '>
                  <img src={CallIcon} alt="Call Icon" className='call-icon-contact-us-image' />
                </div>
                <div className='col-lg-9'>
                  <div class=" signika-font call-number p-3 fw-light">
                    +91 044-26172789<br />
                    +91 93444-25159
                  </div>
                </div>
              </div>
            </div>
            <div className='card card-contact-us-eltai  rounded-0 mt-4 p-3'>
              <div className='row'>
                <div className='col-lg-3 call-icon-contact-us '>
                  <img src={EmailIcon} alt="Email Icon" className='mail-icon-image' />
                </div>
                <div className='col-lg-9'>
                  <div className='signika-font email-font p-3'>
                    Email : indiaeltai@gmail.com<br />
                    Website : www.eltai.in
                  </div>
                </div>
              </div>
            </div>
            <div className='card  rounded-0 mt-4' style={{ paddingTop: '40px', paddingBottom: '40px' }}>
              <div className='row align-items-center'>
                <div className="col-lg-5 text-center fs-5 ">
                  You can follow us on
                </div>
                <div className='col-lg-7'>
                  <div className='d-flex justify-content-around align-items-center'>
                    <img src={FBicon} alt="Facebook Icon" className='icon-image' />
                    <img src={Xicon} alt="Twitter Icon" className='icon-image' />
                    <img src={instaicon} alt="Instagram Icon" className='icon-image' />
                    <img src={youtubeicon} alt="YouTube Icon" className='icon-image' />
                  </div>
                </div>
              </div >
            </div>
            <div style={{ paddingBottom: '70px' }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs