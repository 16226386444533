import React, { useState, useRef, useEffect } from 'react';
import { Container, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import eltaiLogo from '../../../assets/images//logos/ELTA@I Logo Source File2.png'
import './pdfconver.css'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import CloseIcon from '@mui/icons-material/Close';
import { LinkedIn, YouTube } from '@material-ui/icons';
import { useLazyGetMemberShipDetailsByIdQuery } from '../../../app/services/memberShipServices'
import { useLazyGetPendingMemberByIdQuery, } from '../../../app/services/memberListServices'
import { Salutation, planType } from '../../../constant/constant'
import Barcode from "react-barcode";
import html2pdf from 'html2pdf.js';

const CardPdfConvert = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [member, setMember] = useState([])
    const [membershipDetails] = useLazyGetPendingMemberByIdQuery()
    const [planDetails] = useLazyGetMemberShipDetailsByIdQuery()
    const dynamicIndex = 0;
    const card1Ref = useRef(null);
    const card2Ref = useRef(null);
    const [plan, setPlan] = useState()
    useEffect(() => {
        membershipDetails(id).then((res) => {
            if (res?.data?.status) {
                setMember(res?.data?.data)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }, [membershipDetails, id])
    useEffect(() => {
        const fetchPlanDetails = async () => {
            const planId = member?.plans?.[dynamicIndex]?.planId;

            if (!planId) {
                console.warn(`Plan ID not found for index: ${dynamicIndex}`);
                return;
            }

            try {
                const res = await planDetails(planId);
                if (res?.data?.status) {
                    setPlan(res?.data?.data)
                } else {
                    console.warn("Invalid response status", res?.data);
                }
            } catch (err) {
                console.error("Error fetching plan details:", err);
            }
        };

        fetchPlanDetails();
    }, [member, dynamicIndex, planDetails]); // Add dynamicIndex as a dependency

    useEffect(() => {
    }, [plan])
    const getMemberTypeLabel = (type) => {
        switch (type) {
            case "0":
                return member?.fullName;
            case "1":
                return member?.fullName;
            case "2":
                return member?.institution;
            case "3":
                return member?.organisationName;
            case "4":
                return member?.fullName;
            default:
                return;
        }
    };



    const getSalutation = (id) => {
        const salutation = Salutation.find(data => data?.id === id)
        return salutation ? salutation?.Name : "-"
    }

    const exportToPDF = () => {
        setIsLoading(true);

        // Combine the two cards into a wrapper for rendering
        const combinedRef = document.createElement("div");
        combinedRef.style.padding = "20px"; // Padding around the content
        combinedRef.style.backgroundColor = "white";

        // Clone and append the first card
        const card1Clone = card1Ref.current.cloneNode(true);
        card1Clone.style.marginBottom = "20px"; // Gap between cards
        combinedRef.appendChild(card1Clone);

        // Clone and append the second card
        const card2Clone = card2Ref.current.cloneNode(true);
        combinedRef.appendChild(card2Clone);

        // Use html2pdf to generate the PDF
        const options = {
            margin: [10, 10], // Top and bottom margin
            filename: `${member.userId}`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 }, // Higher scale for better quality
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf()
            .set(options)
            .from(combinedRef)
            .save()
            .finally(() => setIsLoading(false));
    };


    function formatDate(isoDateString) {
        if (!isoDateString) return "Invalid date";

        const date = new Date(isoDateString);

        if (isNaN(date.getTime())) return "Invalid date";

        // Format options
        const options = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("en-GB", options); // British English format
    }
    // The function to calculate the difference in years
    const betweenYear = (startyear, endyear) => {
        const start = new Date(startyear); // Convert startyear to Date object
        const end = new Date(endyear); // Convert endyear to Date object
        return end.getFullYear() - start.getFullYear(); // Return the difference in years
    };

    // JSX Component
    const planTypeShow = (id) => {
        const plan = planType?.find((data) => data?.planId === id);
        return plan ? plan?.planName : "Unknown Plan";
    };


    return (
        <div className="ourExecutiveCommittee-containter">
            <div className="pt-5">
                <header className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">
                        View Membership Card PDF
                    </h2>
                </header>
            </div>
            <Container maxWidth="xl">
                <div className="d-flex justify-content-end">
                    <Button onClick={exportToPDF} variant="contained" className="menus-color mb-4">
                        {isLoading ? 'Generating PDF...' : 'Download PDF'}
                    </Button>
                </div>
                <div>
                    <div className="row pb-5" id="pdf-container">
                        {/* Left Section */}
                        <div className="col-lg-6" ref={card1Ref}>
                            <Container maxWidth="xl">
                                <div className="card shadow">
                                    <div className="card-body card-download-pdf pdf-generator-nember-eltai-font-size">
                                        {/* Logo */}
                                        <div className='row'>
                                            <div className='col-4'>
                                                <img
                                                    src={eltaiLogo}
                                                    width={100}
                                                    alt="logo"
                                                    className="pdf-generator-nember-eltai-logo"
                                                />
                                            </div>
                                            <div className='col-4'>

                                                <p
                                                    className={`text-uppercase text-center mb-0  card-download-pdf-green-color-font
                                                 fw-bold fs-3`}
                                                    style={{ fontSize: isLoading ? '10px' : '18px' }} // Inline font-size for testing
                                                >
                                                    {member?.salutation ? `${getSalutation(member?.salutation)}. ` : ""}
                                                    {getMemberTypeLabel(member?.type)}
                                                </p>
                                            </div>
                                            <div className='col-4'>

                                            </div>
                                        </div>
                                        <p className="text-uppercase text-center mb-0 card-download-pdf-brown-color-font fs-5 pdf-generator-nember-eltai-font-size">
                                            {planTypeShow(plan?.planName)}
                                        </p>
                                        <p className="text-uppercase text-center mb-1 card-download-pdf-black-color-font fw-bold fs-6">
                                            {/* {`Valid  For ${betweenYear(
                                                member?.plans?.[member?.plans?.length - 1]?.dummyStartDate,
                                                member?.plans?.[member?.plans?.length - 1]?.dummyEndDate
                                            )} Years`} */}
                                            {`Valid For ${betweenYear(
                                                member?.plans?.[member?.plans?.length - 1]?.dummyStartDate,
                                                member?.plans?.[member?.plans?.length - 1]?.dummyEndDate
                                            ) > 1
                                                    ? `${betweenYear(
                                                        member?.plans?.[member?.plans?.length - 1]?.dummyStartDate,
                                                        member?.plans?.[member?.plans?.length - 1]?.dummyEndDate
                                                    )} Years`
                                                    : `${betweenYear(
                                                        member?.plans?.[member?.plans?.length - 1]?.dummyStartDate,
                                                        member?.plans?.[member?.plans?.length - 1]?.dummyEndDate
                                                    )} Year`
                                                }`}

                                        </p>
                                        <p className="text-center mb-1 card-download-pdf-black-color-font fs-6 text-decoration-underline">
                                            {`From ${formatDate(
                                                member?.plans?.[member?.plans?.length - 1]?.dummyStartDate
                                            )} To ${formatDate(
                                                member?.plans?.[member?.plans?.length - 1]?.dummyEndDate
                                            )}`}
                                        </p>
                                        {/* Barcode */}
                                        <div className="d-flex justify-content-center">
                                            <Barcode
                                                className="barcode-class"
                                                value={member}
                                                format="CODE128"
                                                width={1.5}
                                                height={isLoading ? 20 : 50}
                                                displayValue={false}
                                                lineColor="#000"
                                                background="#fff"
                                            />
                                        </div>
                                        <p className="text-center fs-5 mb-0">{member?.userId}</p>
                                        <p className="text-center fs-5">
                                            For Membership Benefits, https://eltai.in/
                                        </p>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        {/* Right Section */}
                        <div className="col-lg-6" ref={card2Ref}>
                            <Container maxWidth="xl">
                                <div className="card shadow">
                                    <div className="card-body card-download-pdf">
                                        {/* Logo */}
                                        <div className="d-flex justify-content-center">
                                            <img src={eltaiLogo} width={100} alt="logo" />
                                        </div>
                                        <p className="text-uppercase text-center my-2 card-download-pdf-green-color-font fw-bold fs-5">
                                            The English Language Teachers’ Association of India
                                        </p>
                                        <p className="text-center card-download-pdf-address">
                                            D-54 Third Floor, Anandham Apartments, 156, SIDCO Nagar Main
                                            Road, Villivakkam, Chennai, Tamil Nadu, India - 600049.
                                        </p>
                                        {/* Contact Information */}
                                        {/* <Container maxWidth="xl">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="card-download-pdf-phone">
                                                        <PhoneIcon className="card-download-pdf-white" />
                                                    </div>
                                                    <span>
                                                        <p className="mb-0 mt-3 mx-2">+91-04426172789</p>
                                                        <p className="mx-2">+91-9344425159</p>
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-download-pdf-phone">
                                                        <EmailIcon className="card-download-pdf-white" />
                                                    </div>
                                                    <span>
                                                        <p className="mb-0 mt-3 mx-2">indiaeltai@gmail.com</p>
                                                        <p className="mx-2">www.eltai.in/</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </Container> */}
                                        <>
                                            <div className="row d-flex align-items-center">
                                                {/* First column for phone icon and numbers */}
                                                <div className={`col-6 d-flex align-items-center export-pdf-col`}>
                                                    <div className="card-download-pdf-phone">
                                                        <PhoneIcon className="card-download-pdf-white" />
                                                    </div>
                                                    <span>
                                                        <p className="mb-0 mt-3 mx-2 fs-6">+91-04426172789</p>
                                                        <p className="mx-2 fs-6">+91-9344425159</p>
                                                    </span>
                                                </div>

                                                {/* Second column for Gmail and website */}
                                                <div className={`col-6 d-flex align-items-center export-pdf-col`}>
                                                    <div className="card-download-pdf-phone">
                                                        <EmailIcon className="card-download-pdf-white" />
                                                    </div>
                                                    <span>
                                                        <p className="mb-0 mt-3 mx-2 fs-6 text-truncate">indiaeltai@gmail.com</p>
                                                        <p className="mx-2 fs-6">www.eltai.in/</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </>

                                        {/* Social Media */}
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <span className="fs-5 fw-bold card-download-pdf-green-color-font">
                                                    Follow us on
                                                </span>
                                                <FacebookIcon className="icon-social-media-pdf-card" />
                                                <CloseIcon className="icon-social-media-pdf-card" />
                                                <YouTube className="icon-social-media-pdf-card" />
                                                <LinkedIn className="icon-social-media-pdf-card mx-1" />
                                                /eltai.india
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CardPdfConvert;
