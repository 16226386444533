import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, IconButton, Typography, Modal, Tooltip, TextField } from '@mui/material';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { usePaginationNonReceiptMutation, useLazyGetNonReceiptDetailsByIdQuery, useUpdateNonReceiptMutation } from '../../../app/services/nonReceiptService'
import CommentIcon from '@mui/icons-material/Comment'
const EnhancedNewsTableHead = () => {
  const headCells = [
    { id: 'MemberId', label: 'Member ID' },
    { id: 'date', label: 'Date' },
    { id: 'description', label: 'Description' },
    { id: 'monthOfJournal', label: 'Month Of Journal' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' },
  ];

  return (
    <TableHead>
      <TableRow className="menus-color">
        {headCells.map((headCell) => (
          <TableCell sx={{ textAlign: 'center', color: '#ffff' }} key={headCell.id} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const NonReceipt = () => {
  const rowsPerPage = 5;
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [nonReceipt, setNonReceipt] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [getAllPagination] = usePaginationNonReceiptMutation();
  const [getByid] = useLazyGetNonReceiptDetailsByIdQuery()
  const [memberDetails, setMemberDetails] = useState()
  const [UpdateNonReceiptData] = useUpdateNonReceiptMutation()
  const [selectedReceipt, setSelectedReceipt] = useState()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { status: 1, comment: '' }
  });

  const pageCount = Math.ceil(totalPage / rowsPerPage);

  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  const fetchData = useCallback(async () => {
    const data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
    };
    try {
      const res = await getAllPagination(data);
      if (res?.data?.status) {
        setNonReceipt(res?.data?.data?.reportNonReceiptDetails);
        setTotalPage(res?.data?.data?.totalCount);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, rowsPerPage, getAllPagination]);




  const handleOpenModal = (receipt) => {
    setSelectedReceipt(receipt)
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false)
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, page]); // Fetch data on page change


  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
  };
  const handleUserDetails = async (id) => {
    try {
      setOpen(true);

      const response = await getByid(id);
      if (response?.data?.status) {
        const memberData = response.data.data;
        // setGetMember(memberData);
        const matchedMember = nonReceipt.find(nr => nr?.memberDetails?._id === memberData?.memberId);
        if (matchedMember) {
          const { fullName, email, mobileNumber, organisationName, institution } = matchedMember.memberDetails;
          setMemberDetails({ fullName, email, mobileNumber, organisationName, institution });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
  }, [memberDetails])


  const UpdateNonReceipt = () => {
    // Prepare the request payload
    const requestData = {
      status: 1,  // Assuming data contains the updated status
      comment: getValues('comment'), // Assuming data contains the comment
      id: selectedReceipt?._id
    };
    // Call the mutation, passing id and requestData as parameters
    UpdateNonReceiptData(requestData)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          reset();  // Reset form or other UI elements if needed
          handleClose();  // Close modal or handle UI state
          fetchData()
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => console.error("Error: ", err));
  }
  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Report Non Receipt List</h2>
          </div>
        </div>
        <div className="p-md-5 p-3 overallPadding">
            <Box sx={{ width: '100%' }} className="memberTable mb-2">
              <Paper sx={{ width: '100%', mb: 3 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedNewsTableHead />
                    <TableBody>
                      {nonReceipt.length > 0 ? (
                        nonReceipt.map((receipt, index) => (
                          <TableRow
                            hover
                            key={receipt._id}
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white',
                            }}
                          >
                            <TableCell align="center  cursor-pointer" onClick={() => handleUserDetails(receipt?._id)}>{receipt?.memberDetails?.userId}</TableCell>
                            <TableCell align="center">{formatDate(receipt?.date)}</TableCell>
                            <TableCell align="center" width={'45%'}>{receipt?.description}</TableCell>
                            <TableCell align="center">{receipt?.monthOfJournal}</TableCell>
                            <TableCell align="center">{receipt?.status === 0 ? <span style={{ color: "red", fontWeight: 600 }}>Open</span> : <span style={{ color: "green", fontWeight: 600 }}>Close</span>}</TableCell>
                            <TableCell align="center">
                              {receipt?.status === 0
                                ?
                                <Tooltip title="Add Comment">
                                  <IconButton aria-label="viewIcon" onClick={() => handleOpenModal(receipt)}>
                                    <CommentIcon style={{ color: 'blue' }} />
                                  </IconButton>
                                </Tooltip> :
                                <>-----</>
                              }

                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow hover>
                          <TableCell colSpan={6} align="center">
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div className="w-100 d-flex justify-content-center pb-4 mb-4">
                {nonReceipt?.length > 0 && (
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    forcePage={page}
                    previousLabel={pageCount > 1 && page >= 1 ? 'Previous' : null}
                    nextLabel={pageCount > 1 && page + 1 !== pageCount ? 'Next' : null}
                    disabledClassName={'disablebtn'}
                  />
                )}
              </div>
            </Box>
            <br />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  backgroundColor: 'white',
                  boxShadow: 24,
                  padding: "20px",
                  outline: 'none',
                }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Member Details
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p><span className='fw-bold'> Name:</span> {memberDetails?.fullName || memberDetails?.institution || memberDetails?.organisationName}</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p><span className='fw-bold'> Email:</span> {memberDetails?.email}</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p><span className='fw-bold'> Mobile:</span> {memberDetails?.mobileNumber}</p>
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "#dc3545",
                    color: "white",
                    padding: "5px 15px",
                    borderRadius: "4px",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Modal>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                className="d-flex align-items-center flex-column gap-3"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  backgroundColor: 'white',
                  boxShadow: 24,
                  padding: "20px",
                  outline: 'none',
                }}
              >
                {/* <div id="modal modal-title" >
                <Typography style={{ color: "#611f1e" }} variant="h6" component="h2">
                  Add Comment
                </Typography>
                </div> */}

                {/* TextField with validation */}
                {/* <TextField
                  fullWidth
                  id="Comment"
                  label={
                    <Typography className="text-dark">
                      Comment <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("comment", {
                    required: "Comment is required",
                    minLength: { value: 3, message: "Comment must be at least 3 characters long" },
                  })}
                  error={!!errors?.comment}  // Display error if there is an issue with validation
                  helperText={errors?.comment?.message} // Display error message
                /> */}
                <Typography
                  component={"h2"}
                  className="text-center fs-4 fw-bold text-danger"
                >
                  Add Comment
                </Typography>
                <TextField
                  fullWidth
                  label={
                    <Typography className="text-dark">
                      Comment{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  error={!!errors?.comment}
                  helperText={errors?.comment?.message}
                  {...register("comment", {
                    required: "Comment is required",
                    minLength: { value: 3, message: "Comment must be at least 3 characters long" },
                  })}
                />

                {/* Modal buttons */}
                <div>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#2e7d32",
                      border: "1px solid rgba(46, 125, 50, 0.5)",
                      padding: "5px 15px",
                      borderRadius: "4px",
                    }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                  <Button
                    className="mx-2"
                    variant="contained"
                    style={{
                      backgroundColor: "#dc3545",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "4px",
                    }}
                    onClick={handleSubmit(UpdateNonReceipt)}
                  >
                    Yes
                  </Button>
                </div>
              </Box>
            </Modal>
        </div>
      </Box>

      {/* Modal for confirming delete */}

    </div>
  );
};

export default NonReceipt;
