import React from 'react';
import { motion } from 'framer-motion';
import "../../style/css/commingSoon.css";

const CommingSoonPage = () => {
  return (
    <div className="membership-page">
      <motion.div
        className="content-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="main-heading mt-0">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Coming Soon!
          </motion.span>
        </h1>
        <p className="sub-text px-4">
        Thank you for choosing <span className="highlight">ELT<span style={{color:'#e7833f'}}>@</span>I</span> Our new membership form is launching soon, designed for a seamless experience. Your journey with India’s leading English Language and Literature educator network is just around the corner. Stay tuned, and we’ll welcome you with open arms. Check back soon to join  <span className="highlight">ELT<span style={{color:'#e7833f'}}>@</span>I!</span>
        </p>
      </motion.div>
    </div>
  );
};

export default CommingSoonPage;
